import dayjs from "dayjs";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { BsFillHeptagonFill } from "react-icons/bs";
import { InfiniteScroll } from "..";
import {
  criarAlerta,
  ehAssinaturaDigitalNLar,
  ehBonificacao,
  ehContasAPagar,
  ehInclusaoContasAPagar,
  ehJuros,
  ehOrdemDeComprasConsumo,
  ehOrdemDePagamento,
  ehOrdemDeServico,
  ehPrevisaoDeCompraNLar,
  ehSolicitacaoFinanceira,
  lancarAlertaPadraoParaListaVazia,
  limparIntervalosExistentes,
  obterCorControleDeAcesso,
  toDataBR,
  tratarMensagemDeErroNaBuscaDeDados,
  verificarScrollInfinitoAtivo,
} from "../../functions";
import {
  useConfiguracaoDeFiltroContext,
  useGlobalContext,
  usePaginacaoContext,
  useScrollDaLista,
} from "../../hooks";
import { ISolicitacaoSenha } from "../../interfaces";
import { SolicitacaoSenhaService } from "../../services";
import {
  IDENTIFICADOR_ASSINATURA_DIGITAL_NLAR,
  IDENTIFICADOR_BAIXA_DE_CONTAS_A_PAGAR,
  IDENTIFICADOR_BONIFICACAO,
  IDENTIFICADOR_INCLUSAO_DE_CONTAS_A_PAGAR,
  IDENTIFICADOR_JUROS,
  IDENTIFICADOR_ORDEM_DE_COMPRAS_CONSUMO,
  IDENTIFICADOR_ORDEM_DE_PAGAMENTO,
  IDENTIFICADOR_ORDEM_DE_SERVICO,
  IDENTIFICADOR_PREVISAO_DE_COMPRA_NLAR,
  IDENTIFICADOR_SENHA_FINANCEIRA,
  IDENTIFICADOR_SOLICITACAO_DE_SENHA_COMUM,
  TEMPO_DE_ATUALIZACAO_DE_REGISTROS,
} from "../../utils";
import { BaseClicavel } from "../base-clicavel";
import { EmptyState } from "../empty-state";
import { MenuDeFiltroSolicitacoesDeSenha } from "../menu-de-filtro-solicitacoes-de-senha";
import { useStyles } from "./styles";
import { ScrollDaLista } from "../../functions/scroll-da-lista";
import { configs } from "../../configs";

interface ISolicitacaoDeSenhaComControleDeAcesso extends ISolicitacaoSenha {
  controleDeAcesso: string;
}

export interface IInformacaoControleDeAcesso {
  cor: string;
  titulo: string;
}

type TListagemDeSolicitacoesProps = {
  selecionarSolicitacao: (
    solicitacao: ISolicitacaoSenha,
    indiceSolicitacao: number
  ) => Promise<void>;
};

type TSolicitacaoDeSenhaProps = {
  indiceDaNavegacaoDoFoco: number;
  indiceSolicitacao: number;
  solicitacaoDeSenha: ISolicitacaoSenha;
  selecionarSolicitacao: (
    solicitacao: ISolicitacaoSenha,
    indiceSolicitacao: number
  ) => Promise<void>;
};

type TPropriedadeDaSolicitacaoDeSenhaProps = {
  label: string;
  valor: ReactNode;
};

const PropriedadeDaSolicitacaoDeSenha: React.FC<
  TPropriedadeDaSolicitacaoDeSenhaProps
> = ({ label, valor }) => {
  const classes = useStyles();

  return (
    <span className={classes.propriedadeDaSolicitacaoDeSenha}>
      <strong>{label}: </strong>
      <p>{valor}</p>
    </span>
  );
};

const SolicitacaoDeSenha: React.FC<TSolicitacaoDeSenhaProps> = ({
  indiceDaNavegacaoDoFoco,
  indiceSolicitacao,
  solicitacaoDeSenha,
  selecionarSolicitacao,
}) => {
  const { refElementoParaFoco } = useScrollDaLista();

  const classes = useStyles();

  if (
    ehAssinaturaDigitalNLar(solicitacaoDeSenha) &&
    dayjs(solicitacaoDeSenha.dataEnvio).isBefore(dayjs(new Date()), "day")
  )
    return <></>;

  const date = new Date();
  date.setTime(Date.parse(solicitacaoDeSenha.dataEnvio));

  return (
    <BaseClicavel
      ref={
        indiceDaNavegacaoDoFoco === indiceSolicitacao
          ? refElementoParaFoco
          : null
      }
      className={classes.solicitacaoDeSenha}
      onClick={() =>
        selecionarSolicitacao(solicitacaoDeSenha, indiceSolicitacao)
      }
    >
      <div className={classes.title}>
        <BsFillHeptagonFill
          size="1.25em"
          color={obterCorControleDeAcesso(solicitacaoDeSenha).cor}
        />

        <h1>{obterCorControleDeAcesso(solicitacaoDeSenha).titulo}</h1>
      </div>

      <PropriedadeDaSolicitacaoDeSenha
        label="Sequencial"
        valor={solicitacaoDeSenha.sequencial}
      />

      {solicitacaoDeSenha.empresa && (
        <PropriedadeDaSolicitacaoDeSenha
          label="Empresa"
          valor={solicitacaoDeSenha.empresa}
        />
      )}

      {ehAssinaturaDigitalNLar(solicitacaoDeSenha) && (
        <>
          <PropriedadeDaSolicitacaoDeSenha
            label="NumOrc"
            valor={solicitacaoDeSenha.numOrcPedido}
          />

          <PropriedadeDaSolicitacaoDeSenha
            label="CodLoja"
            valor={solicitacaoDeSenha.codLojaPedido}
          />
        </>
      )}

      <PropriedadeDaSolicitacaoDeSenha
        label="Usuário origem"
        valor={solicitacaoDeSenha.usuarioOrigem}
      />

      <PropriedadeDaSolicitacaoDeSenha
        label="Usuário destino"
        valor={solicitacaoDeSenha.usuarioDestino}
      />

      {solicitacaoDeSenha.fornecedor && (
        <PropriedadeDaSolicitacaoDeSenha
          label="Fornecedor"
          valor={solicitacaoDeSenha.fornecedor}
        />
      )}

      <PropriedadeDaSolicitacaoDeSenha
        label="Data envio"
        valor={`${toDataBR(
          solicitacaoDeSenha.dataEnvio
        )} - ${date.toLocaleTimeString()}`}
      />
    </BaseClicavel>
  );
};

export const ListagemDeSolicitacoes: React.FC<TListagemDeSolicitacoesProps> = ({
  selecionarSolicitacao,
}) => {
  const { refElementoParaFoco } = useScrollDaLista();

  const classes = useStyles();

  const { loading, notificacao, setLoading, setNotificacao } =
    useGlobalContext();

  const {
    filtrosDeSolicitacaoDeSenhaAtivos,
    pesquisaPeloFiltroDeDataAtiva,
    exibindoOpcoesDeFiltroDeData,
    dataInicialDoFiltro,
    dataFinalDoFiltro,
    setPesquisaPeloFiltroDeDataAtiva,
  } = useConfiguracaoDeFiltroContext();

  const { paginacaoSolicitacoes, setPaginacaoSolicitacoes } =
    usePaginacaoContext();

  const { paginaAtual, totalPaginas } = paginacaoSolicitacoes;

  const [solicitacoesDeSenha, setSolicitacoesDeSenha] = useState<
    ISolicitacaoSenha[]
  >([]);

  const [intervaloDeBuscaAtivo, setIntervaloDeBuscaAtivo] = useState(false);

  const solicitacoesDeSenhaParaExibir: ISolicitacaoSenha[] = useMemo(() => {
    if (filtrosDeSolicitacaoDeSenhaAtivos.length === 0)
      return [...solicitacoesDeSenha];

    function obterControleDeAcesso(solicitacao: ISolicitacaoSenha): string {
      if (ehOrdemDeComprasConsumo(solicitacao))
        return IDENTIFICADOR_ORDEM_DE_COMPRAS_CONSUMO;

      if (ehOrdemDeServico(solicitacao)) return IDENTIFICADOR_ORDEM_DE_SERVICO;

      if (ehOrdemDePagamento(solicitacao))
        return IDENTIFICADOR_ORDEM_DE_PAGAMENTO;

      if (ehPrevisaoDeCompraNLar(solicitacao))
        return IDENTIFICADOR_PREVISAO_DE_COMPRA_NLAR;

      if (ehAssinaturaDigitalNLar(solicitacao))
        return IDENTIFICADOR_ASSINATURA_DIGITAL_NLAR;

      if (ehContasAPagar(solicitacao))
        return IDENTIFICADOR_BAIXA_DE_CONTAS_A_PAGAR;

      if (ehSolicitacaoFinanceira(solicitacao))
        return IDENTIFICADOR_SENHA_FINANCEIRA;

      if (ehBonificacao(solicitacao)) return IDENTIFICADOR_BONIFICACAO;

      if (ehInclusaoContasAPagar(solicitacao))
        return IDENTIFICADOR_INCLUSAO_DE_CONTAS_A_PAGAR;

      if (ehJuros(solicitacao)) return IDENTIFICADOR_JUROS;

      return IDENTIFICADOR_SOLICITACAO_DE_SENHA_COMUM;
    }

    const solicitacoesDeSenhaComCampoDoControleDeAcesso: ISolicitacaoDeSenhaComControleDeAcesso[] =
      solicitacoesDeSenha.map((solicitacao) => ({
        ...solicitacao,
        controleDeAcesso: obterControleDeAcesso(solicitacao),
      }));

    return solicitacoesDeSenhaComCampoDoControleDeAcesso.filter((solicitacao) =>
      filtrosDeSolicitacaoDeSenhaAtivos.some(
        (p) => p.titulo === solicitacao.controleDeAcesso
      )
    );
  }, [filtrosDeSolicitacaoDeSenhaAtivos, solicitacoesDeSenha]);

  const [existeElementoAguardandoFoco, setExisteElementoAguardandoFoco] =
    useState(ScrollDaLista.existeElementoAguardandoFoco());

  const buscarSolicitacoesDeSenha = async () => {
    setLoading(true);

    try {
      const { dado, totalPaginas } =
        await SolicitacaoSenhaService.obterSolicitacoesDoUsuario(1);

      lancarAlertaPadraoParaListaVazia(dado, notificacao, setNotificacao);

      setSolicitacoesDeSenha([...dado]);

      setPaginacaoSolicitacoes({ paginaAtual: 1, totalPaginas });

      setLoading(false);

      return [...dado];
    } catch (erro) {
      setSolicitacoesDeSenha([]);

      setPaginacaoSolicitacoes({ ...paginacaoSolicitacoes, totalPaginas: 0 });

      tratarMensagemDeErroNaBuscaDeDados(erro, setNotificacao);

      setLoading(false);

      return [];
    }
  };

  const atualizarPaginaSolicitacoes = async (
    listaAnterior?: ISolicitacaoSenha[]
  ) => {
    setLoading(true);

    const elementosParaManterNaLista = listaAnterior
      ? [...listaAnterior]
      : [...solicitacoesDeSenha];

    try {
      const novaPagina: number = paginacaoSolicitacoes.paginaAtual + 1;

      if (pesquisaPeloFiltroDeDataAtiva) {
        const { dado, totalPaginas } =
          await SolicitacaoSenhaService.obterSolicitacoesDoUsuarioComFiltroDeData(
            novaPagina,
            dataInicialDoFiltro,
            dataFinalDoFiltro
          );

        setSolicitacoesDeSenha([...elementosParaManterNaLista, ...dado]);

        setPaginacaoSolicitacoes({ paginaAtual: novaPagina, totalPaginas });
      } else {
        const { dado, totalPaginas } =
          await SolicitacaoSenhaService.obterSolicitacoesDoUsuario(novaPagina);

        setSolicitacoesDeSenha([...elementosParaManterNaLista, ...dado]);

        setPaginacaoSolicitacoes({ paginaAtual: novaPagina, totalPaginas });
      }
    } catch (erro) {
      criarAlerta(
        "error",
        "Não foi possível atualizar as solicitações.",
        setNotificacao
      );
    }

    setLoading(false);
  };

  async function buscarSolicitacoesDeSenhaPeloFiltro() {
    try {
      setLoading(true);

      const { dado, totalPaginas } =
        await SolicitacaoSenhaService.obterSolicitacoesDoUsuarioComFiltroDeData(
          1,
          dataInicialDoFiltro,
          dataFinalDoFiltro
        );

      setSolicitacoesDeSenha([...dado]);

      setPaginacaoSolicitacoes({ paginaAtual: 1, totalPaginas });
    } catch (erro) {
      setSolicitacoesDeSenha([]);

      setPaginacaoSolicitacoes({ ...paginacaoSolicitacoes, totalPaginas: 0 });

      tratarMensagemDeErroNaBuscaDeDados(erro, setNotificacao);
    } finally {
      setPesquisaPeloFiltroDeDataAtiva(true);

      setLoading(false);
    }
  }

  function handlePesquisarPeloFiltro() {
    limparIntervalosExistentes();
    setIntervaloDeBuscaAtivo(false);

    buscarSolicitacoesDeSenhaPeloFiltro();
  }

  function navegarParaElementoDoFoco(
    indiceElementoDoFoco: number,
    lista: ISolicitacaoSenha[]
  ) {
    if (!existeElementoAguardandoFoco) return;

    const elemento = lista[indiceElementoDoFoco];

    if (elemento) {
      setExisteElementoAguardandoFoco(false);

      ScrollDaLista.navegarParaReferenciaDoFoco(refElementoParaFoco);

      if (
        (!intervaloDeBuscaAtivo && !exibindoOpcoesDeFiltroDeData) ||
        (!intervaloDeBuscaAtivo && !pesquisaPeloFiltroDeDataAtiva)
      ) {
        limparIntervalosExistentes();

        window.setInterval(
          buscarSolicitacoesDeSenha,
          TEMPO_DE_ATUALIZACAO_DE_REGISTROS
        );

        setIntervaloDeBuscaAtivo(true);
      }

      return;
    }

    atualizarPaginaSolicitacoes(lista);
  }

  useEffect(() => {
    if (
      ((!intervaloDeBuscaAtivo && !exibindoOpcoesDeFiltroDeData) ||
        (!intervaloDeBuscaAtivo && !pesquisaPeloFiltroDeDataAtiva)) &&
      !existeElementoAguardandoFoco
    ) {
      limparIntervalosExistentes();

      buscarSolicitacoesDeSenha();

      window.setInterval(
        buscarSolicitacoesDeSenha,
        TEMPO_DE_ATUALIZACAO_DE_REGISTROS
      );

      setIntervaloDeBuscaAtivo(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exibindoOpcoesDeFiltroDeData]);

  useEffect(() => {
    if (pesquisaPeloFiltroDeDataAtiva) buscarSolicitacoesDeSenhaPeloFiltro();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function obterDadosParaNavegarScroll() {
      let solicitacoesDeSenha = [...solicitacoesDeSenhaParaExibir];

      if (paginacaoSolicitacoes.paginaAtual === 1)
        solicitacoesDeSenha = await buscarSolicitacoesDeSenha();

      const indiceDaNavegacaoDoFoco =
        ScrollDaLista.obterIndiceDaNavegacaoDoFoco(solicitacoesDeSenha);

      if (indiceDaNavegacaoDoFoco > 0)
        navegarParaElementoDoFoco(indiceDaNavegacaoDoFoco, solicitacoesDeSenha);
    }

    if (existeElementoAguardandoFoco) obterDadosParaNavegarScroll();
  }, [paginacaoSolicitacoes.paginaAtual]);

  return (
    <section className={`${classes.listagemDeSolicitacoes} fadeIn`}>
      {(solicitacoesDeSenha.length > 0 || pesquisaPeloFiltroDeDataAtiva) && (
        <MenuDeFiltroSolicitacoesDeSenha
          onPesquisarPeloFiltro={handlePesquisarPeloFiltro}
        />
      )}

      {solicitacoesDeSenhaParaExibir.length === 0 &&
      pesquisaPeloFiltroDeDataAtiva ? (
        <EmptyState
          className={classes.emptyStateFiltro}
          mensagem="Não foi encontrada nenhuma solicitação de senha no período informado"
        />
      ) : solicitacoesDeSenhaParaExibir.length === 0 && !loading ? (
        <EmptyState />
      ) : null}

      {solicitacoesDeSenhaParaExibir.map((solicitacaoDeSenha, k) => (
        <SolicitacaoDeSenha
          key={k}
          indiceSolicitacao={k}
          indiceDaNavegacaoDoFoco={ScrollDaLista.obterIndiceDaNavegacaoDoFoco(
            solicitacoesDeSenhaParaExibir
          )}
          solicitacaoDeSenha={solicitacaoDeSenha}
          selecionarSolicitacao={selecionarSolicitacao}
        />
      ))}

      {verificarScrollInfinitoAtivo(
        solicitacoesDeSenhaParaExibir.length,
        loading,
        paginaAtual,
        totalPaginas
      ) && <InfiniteScroll obterMais={atualizarPaginaSolicitacoes} />}
    </section>
  );
};
