import { obterCamelCase, toDataBR, toMoney } from "../../functions";
import { ILoteDocumento, ILoteDocumentoAgrupadoPorLoja } from "../../services";
import { Atributo } from "../atributo";
import { Secao } from "../secao";
import { useStyles } from "./styles";

interface IDocumentoDeContasAPagarProps {
  data: Omit<ILoteDocumento, "codLoja">;
}

export function DocumentoDeContasAPagar({
  data,
}: IDocumentoDeContasAPagarProps) {
  const classes = useStyles();

  return (
    <div className={classes.documento}>
      <Atributo nome="Fornecedor" valor={data.nomeFor} />

      <Atributo nome="Documento" valor={data.documento} />

      <Atributo nome="Tipo" valor={data.tipo} />

      <Atributo nome="NP" valor={`${data.np} / ${data.totalParcelas}`} />

      <Atributo nome="Emissão" valor={toDataBR(data.emissao)} />

      <Atributo nome="Vencimento" valor={toDataBR(data.vencimento)} />

      <Atributo nome="ValorDoc" valor={toMoney(data.valorDoc)} />

      <Atributo nome="Desconto" valor={toMoney(data.desconto)} />

      <Atributo nome="JurosPago" valor={toMoney(data.jurosPago)} />

      <Atributo nome="Multa" valor={toMoney(data.multa)} />

      <Atributo nome="TaxaCartorio" valor={toMoney(data.taxaCartorio)} />

      <Atributo nome="ValorRestante" valor={toMoney(data.valorRestante)} />

      {data.obs && data.obs.trim() ? <Atributo nome="Obs" valor={data.obs} /> : null}
    </div>
  );
}

interface DetalhesContasAPagarProps {
  data: ILoteDocumentoAgrupadoPorLoja[];
  totalGeral: number;
}

export function DetalhesContasAPagar({
  data,
  totalGeral,
}: DetalhesContasAPagarProps) {
  const classes = useStyles();

  return (
    <Secao cabecalho="Lote de documentos">
      <div>
        {data.map((grupoDeCertaLoja, k) => {
          return (
            <div
              key={k}
              className={classes.loteDeDocumento}
              style={{
                borderBottom: k === data.length - 1 ? "none" : undefined,
              }}
            >
              <h3>
                <span>{grupoDeCertaLoja.id}</span>

                <span>{obterCamelCase(grupoDeCertaLoja.razaoSocial)}</span>
              </h3>

              <div className={classes.listaDocumentos}>
                {grupoDeCertaLoja.documentos.map((documento, k) => (
                  <DocumentoDeContasAPagar key={k} data={documento} />
                ))}
              </div>

              <div className={classes.totalLoja}>
                <strong>Total dessa loja:</strong>

                <span>{toMoney(grupoDeCertaLoja.totalLoja)}</span>
              </div>
            </div>
          );
        })}
      </div>

      <div className={classes.totalGeral}>
        <strong>Total geral:</strong>

        <span>{toMoney(totalGeral)}</span>
      </div>
    </Secao>
  );
}
