import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    container: {
        display: 'grid',
        columnGap: '1em',
        gridTemplateColumns: 'repeat(2, 1fr)',
        justifyContent: 'space-evenly',
        marginBottom: '0.5em',
        paddingLeft: '0.5em',
        paddingRight: '0.5em',
    }
})