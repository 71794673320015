import { Button, ButtonProps } from "@material-ui/core";
import { IconType } from "react-icons";
import { useStyles } from "./styles";

export type TTipoDeRelatorio = "pedido";

export type TOpcaoDeRelatorio = {
    nome: string;
    Icone: IconType;
    tipo: TTipoDeRelatorio;
}

type Props = ButtonProps & {
    data: TOpcaoDeRelatorio;
}

export const OpcaoDeRelatorio: React.FC<Props> = ({ data, ...rest }) => {

    const classes = useStyles();

    return <Button
        {...rest}
        className={classes.container}
        classes={{ label: classes.labelContainer }}
    >

        <div className={classes.icone}>

            <data.Icone />

        </div>

        <p className={classes.nome}>{data.nome}</p>

    </Button>

}