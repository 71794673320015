import { toMoney } from "../../functions";
import { INegociacaoResumoOrcamento } from "../../services/api/assinatura-digital/assinatura-digital-service";
import { LinhaRelatorio } from "../linha-relatorio";
import { useStyles } from "./styles";

type Props = {
    data: INegociacaoResumoOrcamento;
}

export const ParcelaNegociacaoAssinaturaDigital: React.FC<Props> = ({ data }) => {

    const classes = useStyles();

    return <div className={classes.container}>

        <span className={classes.titulo}>

            <p>Parcela {data.nrParcela}</p>

        </span>

        <LinhaRelatorio
            titulo="Valor"
            valor={toMoney(data.valor)}
            corDoValor="#007303"
            semNegrito
        />

    </div>

}