import { makeStyles } from "@material-ui/core"

export const cores = {
    finalizadas: {
        texto: '#00AA35',
        decoracoes: '#00C853'
    },
    pendentes: {
        textoEDecoracoes: '#FF6F00',
        fundo: '#FFA000'
    },
    naoSelecionada: {
        padrao: '#6D6D6D'
    }
}

export const useStyles = makeStyles({
    container: {
        textTransform: 'none',
        fontSize: '0.95em',
        fontWeight: 400,
        transition: '0.5s'
    },
    abaFinalizadas: {
        color: cores.finalizadas.texto,
        background: `${cores.finalizadas.decoracoes}1A`,
        '&:hover': {
            background: `${cores.finalizadas.decoracoes}1A`
        }
    },
    abaPendentes: {
        color: cores.pendentes.textoEDecoracoes,
        background: `${cores.pendentes.fundo}1A`,
        '&:hover': {
            background: `${cores.pendentes.fundo}1A`
        }
    },
    abaNaoSelecionada: {
        color: cores.naoSelecionada.padrao,
        background: `${cores.naoSelecionada.padrao}1A`,
        opacity: .3,
        '&:hover': {
            background: `${cores.naoSelecionada.padrao}1A`
        }
    },
    bolinhaMaior: {
        width: 14,
        height: 14,
        borderRadius: 6,
        display: 'grid',
        placeItems: 'center',
        justifyContent: 'center',
        marginLeft: '0.5em'
    },
    bolinhaMenor: {
        width: 8,
        height: 8,
        borderRadius: 4,
    }
});