import { INotificacao, IRetornoObterParametrosDaURL, ISolicitacaoSenha, TSeverityNotificacao, TTipoDaMensagem } from "../../interfaces";
import { ApiException } from "../../services";

export const obterTempoDeLeitura = (textoALer: string): number => (((textoALer.split(" ").length * 60) / 90) * 1000);

export const esperar = async (ms: number): Promise<void> => new Promise((res) => window.setTimeout(() => res(), ms));

export const animarSaidaModal = async (ref: React.RefObject<any>) => {

    document.body.style.pointerEvents = 'none';

    ref.current?.classList.replace('zoomIn', 'zoomOut');

    await esperar(500);

    ref.current?.classList.replace('zoomOut', 'zoomIn');

    document.body.style.pointerEvents = 'auto';

}

export const criarAlerta = (severityNotificacao: TSeverityNotificacao, mensagem: string, setNotificacao: React.Dispatch<React.SetStateAction<INotificacao>>) => {

    const tempoEmTela = obterTempoDeLeitura(mensagem) + 500;

    let severity = severityNotificacao;

    if (mensagem.includes("(#401)")) severity = "warning";

    setNotificacao({ severity, mensagem, tempoEmTela });

    return new Promise((res) => {

        setTimeout(async () => {

            setNotificacao({ severity: '', mensagem: '', tempoEmTela });

            res('ok');

        }, tempoEmTela);

    });

}

export const obterParametrosDaURL = (): IRetornoObterParametrosDaURL => {

    const parametrosDaURL: IRetornoObterParametrosDaURL = {
        token: '',
        sequencial: undefined,
        tipoDaMensagem: undefined
    }

    const parametrosRecebidos: string[] = window.location.href.split("?")[1]?.split("&");

    parametrosRecebidos?.forEach((parametro => {

        const [chave, valor] = parametro.split("=");

        if (chave === 'token') {

            parametrosDaURL.token = valor;

        } else if (chave === 'sequencial') {

            parametrosDaURL.sequencial = Number(valor);

        } else if (chave === 'tipoDaMensagem') {

            parametrosDaURL.tipoDaMensagem = valor.toLowerCase() as TTipoDaMensagem;

        }

    }));

    return parametrosDaURL;

}

export const limparIntervalosExistentes = () => {

    let id = window.setInterval(() => { }, 0);

    while (id--) { window.clearInterval(id) };

}

export const obterIdPedidoSolicitacaoPendente = (mensagemSolicitacao: string): string => {

    if (!mensagemSolicitacao) return ''

    const indiceInicioIdPedido = mensagemSolicitacao.toLowerCase().indexOf('id:') + 3;

    const indiceFimIdPedido = mensagemSolicitacao.toLowerCase().indexOf('fornecedor:');

    const idPedido = mensagemSolicitacao.substring(indiceInicioIdPedido, indiceFimIdPedido).trim();

    return idPedido;

}

export const tratarMensagemDeErroNaBuscaDeDados = (erro: any, setNotificacao: React.Dispatch<React.SetStateAction<INotificacao>>) => {

    const CODIGOS_WARNING = [400, 401];

    if (!(erro instanceof ApiException) || !erro.status) return;

    if (CODIGOS_WARNING.includes(erro.status)) return criarAlerta('warning', erro.message, setNotificacao);

    if (erro.status === 500) return criarAlerta('error', erro.message, setNotificacao);

}

export const lancarAlertaPadraoParaListaVazia = (listaSolicitacaoSenha: ISolicitacaoSenha[], notificacao: INotificacao, setNotificacao: React.Dispatch<React.SetStateAction<INotificacao>>) => {

    if (listaSolicitacaoSenha.length === 0 && notificacao.severity !== "success")
        return criarAlerta("warning", "Não existe solicitações de senha pendentes para o usuário.", setNotificacao);

}