import axios from "axios";

export class ApiException extends Error {
  public readonly message: string;

  public readonly status?: 400 | 500 | 401;

  constructor(error: any, mensagemPersonalizada: string) {
    super();

    if (axios.isAxiosError(error)) {
      const resposta = error.response;

      if (
        (resposta?.status === 400 || resposta?.status === 500) &&
        typeof resposta.data.Detalhes === "string"
      ) {
        this.message = resposta.data.Detalhes;

        this.status = resposta.status;
      } else if (
        resposta?.status === 400 &&
        typeof resposta.data.mensagem === "string"
      ) {
        this.message = resposta.data.mensagem;

        this.status = 400;
      } else if (resposta?.status === 401) {
        this.message =
          "Sua sessão expirou! Efetue o login novamente para continuar utilizando o aplicativo. (#401)";

        this.status = 401;
      } else this.message = mensagemPersonalizada;
    } else this.message = mensagemPersonalizada;
  }
}
