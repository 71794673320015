import { obterParametrosDaURL } from "../../../functions";
import { apiAssinaturaDigital } from "../api";
import { ApiException } from "../api-exception";

interface IComplementoOrcamento {
  chave: string;
  valor: string;
}

export interface IItemResumoOrcamento {
  quantidade: number;
  unidade: string;
  descricao: string;
  valorBruto: number;
  valorLiquido: number;
  desconto: number;
}

export interface INegociacaoResumoOrcamento {
  nrParcela: number;
  valor: number;
}

export interface IResumoOrcamento {
  totalBruto: number;
  desconto: number;
  totalLiquido: number;
  pedido: number;
  data: string;
  cliente: string;
  cpfcnpj: string;
  endereco: string;
  cidadeUF: string;
  condicaoPagamento: string;
  fone: string;
  rG_IE: string;
  bairro: string;
  vendedor: string;
  itens: IItemResumoOrcamento[];
  negociacao: INegociacaoResumoOrcamento[];
  complementos: IComplementoOrcamento[];
  tipoEntrega: string;
  numEndereco: string | null;
}

interface IResumoOrcamentoRequest {
  NumOrc: number;
  CodLoja: number;
}

const obterResumoOrcamento = async ({
  NumOrc,
  CodLoja,
}: IResumoOrcamentoRequest): Promise<IResumoOrcamento> => {
  const { token } = obterParametrosDaURL();

  try {
    const { data } = await apiAssinaturaDigital.get<IResumoOrcamento>(
      `/Orcamento/resumoorcamento`,
      {
        params: { NumOrc, CodLoja },
        headers: { token },
      }
    );

    return data;
  } catch (erro) {
    throw new ApiException(
      erro,
      "Não foi possível obter o resumo do orçamento"
    );
  }
};

const obterURLDoContrato = async ({
  NumOrc,
  CodLoja,
}: IResumoOrcamentoRequest): Promise<string> => {
  const { token } = obterParametrosDaURL();

  try {
    const { data } = await apiAssinaturaDigital.get<string>(
      `/Contrato/obterurlcontrato`,
      {
        params: { NumOrc, CodLoja },
        headers: { token },
      }
    );

    return data;
  } catch (erro) {
    throw new ApiException(erro, "Não foi possível obter a URL do contrato");
  }
};

const validarContrato = async ({
  NumOrc,
  CodLoja,
}: IResumoOrcamentoRequest) => {
  const { token } = obterParametrosDaURL();

  try {
    await apiAssinaturaDigital.put(`/Signatario/validarcontrato`, null, {
      params: { NumOrc, CodLoja },
      headers: { token },
    });
  } catch (erro) {
    throw new ApiException(
      erro,
      "Não foi possível validar o contrato de assinatura digital"
    );
  }
};

const negarContrato = async ({ NumOrc, CodLoja }: IResumoOrcamentoRequest) => {
  const { token } = obterParametrosDaURL();

  try {
    await apiAssinaturaDigital.put(`/Signatario/negarcontrato`, null, {
      params: { NumOrc, CodLoja },
      headers: { token },
    });
  } catch (erro) {
    throw new ApiException(
      erro,
      "Não foi possível negar o contrato de assinatura digital"
    );
  }
};

const verificarSolicitacaoRespondida = async ({
  NumOrc,
  CodLoja,
}: IResumoOrcamentoRequest): Promise<boolean> => {
  const { token } = obterParametrosDaURL();

  try {
    const { data } = await apiAssinaturaDigital.get<boolean>(
      `/Orcamento/verificarsolicitacaorespondida`,
      {
        params: { NumOrc, CodLoja },
        headers: { token },
      }
    );

    return data;
  } catch (erro) {
    throw new ApiException(
      erro,
      "Não foi possível verificar se a solicitação já foi respondida por outro gerente"
    );
  }
};

export const AssinaturaDigitalService = {
  obterResumoOrcamento,
  obterURLDoContrato,
  validarContrato,
  negarContrato,
  verificarSolicitacaoRespondida,
};
