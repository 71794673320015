import { useStyles } from "./styles";

type Props = {
    chave: number;
    tamanhoDaLista: number;
}

export const SeparadorDeListaRelatorio: React.FC<Props> = ({ chave, tamanhoDaLista }) => {

    const classes = useStyles();

    if (chave === tamanhoDaLista - 1) return <></>

    return <div className={classes.container}></div>

}