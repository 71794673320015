import { makeStyles } from "@material-ui/core";
import { colors } from "../../assets/styles";

export const useStyles = makeStyles({
    container: {
        background: 'rgba(60, 126, 247, 0.1)',
        textTransform: 'none',
        color: colors.primary,
        paddingRight: '1.5em',
        paddingLeft: '1.5em',
        marginBottom: '20px',

        "&:hover": {
            background: 'rgba(60, 126, 247, 0.1)',
        }
    }
});