import { Button, makeStyles } from "@material-ui/core";
import {
  forwardRef,
  ReactChild,
  ReactElement,
  ReactNode,
  useMemo,
  useRef,
} from "react";
import { FaRegBell } from "react-icons/fa";
import { useGlobalContext, usePaginacaoContext } from "../../hooks";
import { RiFileList3Line } from "react-icons/ri";
import { IoSearch } from "react-icons/io5";
import { GoGear, GoGraph } from "react-icons/go";
import { TPaginas } from "../../interfaces";
import { colors } from "../../assets/styles";
import { criarAlerta, esperar } from "../../functions";

type TOpcaoDeNavegacaoProps = {
  opcao: TPaginas;
  icone: ReactNode | ReactChild | ReactElement;
  handleChamarAtencaoAbaSolicitacoes?: () => Promise<void>;
};

const useStyles = makeStyles({
  navegacao: {
    position: "fixed",
    zIndex: 3,
    bottom: 0,
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    padding: "0em 1em",
    borderTop: "0.8px solid #D6DDEB",
  },

  opcaoDeNavegacao: {
    fontSize: "1em",
    marginTop: "0",
    minWidth: "0",
    padding: "0.9em",
    borderRadius: "16px",

    "& svg": {
      height: "1.4em",
      width: "1.4em",
      fill: "#D5DCEA",
    },

    transition: "0.5s",
  },

  opcaoDeNavegacaoSelecionada: {
    backgroundColor: colors.primary,
    boxShadow: "0px 0px 5.64242px rgba(60, 126, 247, 0.3)",
    marginTop: "-60px",

    "& svg": {
      fill: "white",
    },

    "&:hover": {
      backgroundColor: colors.primary,
    },
  },
});

const OpcaoDeNavegacao = forwardRef<HTMLButtonElement, TOpcaoDeNavegacaoProps>(
  ({ opcao, icone, handleChamarAtencaoAbaSolicitacoes }, ref) => {
    const classes = useStyles();

    const { paginaAtual, pedidoSelecionado, setPaginaAtual, setNotificacao } =
      useGlobalContext();

    const { setPaginacaoSolicitacoes } = usePaginacaoContext();

    const selecionado = opcao === paginaAtual;

    const temPedidoSelecionado = useMemo(
      () => (pedidoSelecionado.sequencial ? true : false),
      [pedidoSelecionado]
    );

    const handleClickOpcaoDeNavegacao = () => {
      if (opcao === "pedido-selecionado" && !temPedidoSelecionado) {
        criarAlerta(
          "info",
          "Selecione alguma solicitação para poder acessar esta aba.",
          setNotificacao
        );

        if (handleChamarAtencaoAbaSolicitacoes) {
          handleChamarAtencaoAbaSolicitacoes();
        }

        return setPaginaAtual("solicitacoes");
      }

      setPaginacaoSolicitacoes({ paginaAtual: 1, totalPaginas: 0 });

      setPaginaAtual(opcao);

      if (opcao === paginaAtual) window.scrollTo(0, 0);
    };

    return (
      <Button
        ref={ref}
        className={`${classes.opcaoDeNavegacao} ${
          selecionado ? classes.opcaoDeNavegacaoSelecionada : ""
        }`}
        onClick={handleClickOpcaoDeNavegacao}
      >
        {icone}
      </Button>
    );
  }
);

export const Navegacao: React.FC = () => {
  const classes = useStyles();

  const refOpcaoDeNavegacaoSolicitacoes = useRef<HTMLButtonElement>(null);

  const handleChamarAtencaoAbaSolicitacoes = async () => {
    refOpcaoDeNavegacaoSolicitacoes.current?.classList.add("shake");

    await esperar(1000);

    refOpcaoDeNavegacaoSolicitacoes.current?.classList.remove("shake");
  };

  return (
    <nav className={classes.navegacao}>
      <OpcaoDeNavegacao opcao={"notificacoes"} icone={<FaRegBell />} />

      <OpcaoDeNavegacao
        opcao={"resumo-contas-a-pagar-pendente-de-envio"}
        icone={<GoGraph />}
      />

      <OpcaoDeNavegacao
        ref={refOpcaoDeNavegacaoSolicitacoes}
        opcao={"solicitacoes"}
        icone={<RiFileList3Line />}
      />

      <OpcaoDeNavegacao
        opcao={"pedido-selecionado"}
        icone={<IoSearch />}
        handleChamarAtencaoAbaSolicitacoes={handleChamarAtencaoAbaSolicitacoes}
      />

      <OpcaoDeNavegacao opcao="configuracoes" icone={<GoGear />} />
    </nav>
  );
};
