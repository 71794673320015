import { useEffect, useState } from "react";
import { InfiniteScroll } from "..";
import {
  toDataBR,
  toMoney,
  tratarMensagemDeErroNaBuscaDeDados,
  verificarScrollInfinitoAtivo,
} from "../../functions";
import { useGlobalContext } from "../../hooks";
import {
  IContasAPagarPendente,
  IRetornoObterContasAPagarPendentes,
  SolicitacaoSenhaService,
} from "../../services";
import { Atributo } from "../atributo";
import { BotaoVoltarParaOTopo } from "../botao-voltar-para-o-topo";
import { DetalhesContasAPagarPendente } from "../detalhes-contas-a-pagar-pendente";
import { EmptyState } from "../empty-state";
import { CabecalhoSecao } from "../informacoes-pedido";
import { Secao } from "../secao";
import { useStyles } from "./styles";

const valorInicialResumoDeContasAPagarPendentes: IRetornoObterContasAPagarPendentes =
  {
    totalPaginas: 0,
    valorTotalPendente: 0,
    valorTotalPendenteDoDiaDeHoje: 0,
    pendentes: [],
  };

export function ResumoDeContasAPagarPendenteDeEnvio() {
  const classes = useStyles();

  const { loading, setLoading, setNotificacao } = useGlobalContext();

  const [paginaAtual, setPaginaAtual] = useState(1);

  const [resumoDeContasAPagarPendentes, setResumoDeContasAPagarPendentes] =
    useState<IRetornoObterContasAPagarPendentes>({
      ...valorInicialResumoDeContasAPagarPendentes,
    });

  const [
    diaDeContasAPagarPendenteSelecionado,
    setDiaDeContasAPagarPendenteSelecionado,
  ] = useState<IContasAPagarPendente | null>(null);

  function handleSelecionarDiaDeContasAPagarPendente(
    diaDeContasAPagarPendenteClicado: IContasAPagarPendente
  ) {
    setDiaDeContasAPagarPendenteSelecionado({
      ...diaDeContasAPagarPendenteClicado,
    });
  }

  useEffect(() => {
    async function obterResumoDeContasAPagarPendentes() {
      try {
        setLoading(true);

        const resumoDeContasAPagarPendentesData =
          await SolicitacaoSenhaService.obterContasAPagarPendentes(paginaAtual);

        if (paginaAtual === 1)
          setResumoDeContasAPagarPendentes({
            ...resumoDeContasAPagarPendentesData,
          });
        else
          setResumoDeContasAPagarPendentes((oldValue) => ({
            totalPaginas: resumoDeContasAPagarPendentesData.totalPaginas,
            valorTotalPendente:
              resumoDeContasAPagarPendentesData.valorTotalPendente,
            valorTotalPendenteDoDiaDeHoje:
              resumoDeContasAPagarPendentesData.valorTotalPendenteDoDiaDeHoje,
            pendentes: [
              ...oldValue.pendentes,
              ...resumoDeContasAPagarPendentesData.pendentes,
            ],
          }));
      } catch (erro) {
        tratarMensagemDeErroNaBuscaDeDados(erro, setNotificacao);
      } finally {
        setLoading(false);
      }
    }

    obterResumoDeContasAPagarPendentes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginaAtual]);

  return (
    <>
      <div
        className={classes.container}
        style={{
          display:
            diaDeContasAPagarPendenteSelecionado !== null ? "none" : undefined,
        }}
      >
        <Secao
          cabecalho="Resumo de contas a pagar pendentes"
          className={classes.secao}
        >
          <Atributo
            nome="Total pendente"
            valor={toMoney(resumoDeContasAPagarPendentes.valorTotalPendente)}
          />

          <Atributo
            nome="Total pendente hoje"
            valor={toMoney(
              resumoDeContasAPagarPendentes.valorTotalPendenteDoDiaDeHoje
            )}
          />
        </Secao>

        <CabecalhoSecao className={classes.cabecalhoLista}>
          Contas a pagar pendentes por dia
        </CabecalhoSecao>

        {resumoDeContasAPagarPendentes.pendentes.length === 0 && !loading ? (
          <EmptyState className={classes.emptyState} />
        ) : null}

        {resumoDeContasAPagarPendentes.pendentes.map(
          (contaAPagarPendente, k) => (
            <Secao
              key={k}
              className={`${classes.pendente} ${
                k === resumoDeContasAPagarPendentes.pendentes.length - 1
                  ? classes.ultimoPendente
                  : ""
              }`}
              onClick={() =>
                handleSelecionarDiaDeContasAPagarPendente(contaAPagarPendente)
              }
            >
              <Atributo
                nome="Vencimento"
                valor={toDataBR(contaAPagarPendente.vencimento)}
              />

              <Atributo
                nome="Valor"
                valor={toMoney(contaAPagarPendente.totalPendente)}
              />
            </Secao>
          )
        )}

        {verificarScrollInfinitoAtivo(
          resumoDeContasAPagarPendentes.pendentes.length,
          loading,
          paginaAtual,
          resumoDeContasAPagarPendentes.totalPaginas
        ) && (
          <InfiniteScroll
            obterMais={() => setPaginaAtual((oldValue) => oldValue + 1)}
          />
        )}

        {paginaAtual > 1 && (
          <BotaoVoltarParaOTopo onClick={() => setPaginaAtual(1)} />
        )}
      </div>

      {diaDeContasAPagarPendenteSelecionado !== null && (
        <DetalhesContasAPagarPendente
          data={diaDeContasAPagarPendenteSelecionado}
          onClickVoltar={() => setDiaDeContasAPagarPendenteSelecionado(null)}
        />
      )}
    </>
  );
}
