import { useEffect, useRef } from "react";

type TInfiniteScrollProps = {
    obterMais: () => void;
}

export const InfiniteScroll: React.FC<TInfiniteScrollProps> = ({ obterMais }) => {

    const infiniteScrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {

        const options = {
            root: null,
            rootMargin: '0px',
            threshould: 1.0
        }

        // executa a funcao callback toda vez que o elemento aparecer/desaparecer na tela
        const observer = new IntersectionObserver(([entry]) => {

            // se ele ta aparecendo na tela, entry.isIntersecting = true.
            if (entry.isIntersecting) {

                // para evitar aparecer mais vezes
                observer.disconnect();

                obterMais();

            }

        }, options);

        observer.observe(infiniteScrollRef.current as Element);

        // para evitar vazamento de memória
        return () => observer.disconnect();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // carregar mais, quando esta div aparecer na tela.
    return <div ref={infiniteScrollRef} />

}