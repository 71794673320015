import { TextField } from "@material-ui/core";
import {
  LocalizationProvider,
  MobileDatePicker,
  ptBR,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBRLocale from "date-fns/locale/pt-BR";

interface IDataPickerProps {
  label: string;
  value: string;
  onChange(newValue: string | null): void;
}

export const DataPicker: React.FC<IDataPickerProps> = ({
  label,
  value,
  onChange,
}) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ptBRLocale}
      localeText={
        ptBR.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <MobileDatePicker
        label={label}
        value={value}
        onChange={(newValue) =>
          onChange((newValue as any as Date).toISOString())
        }
        renderInput={(params) => (
          <TextField
            variant="outlined"
            style={{ background: "white" }}
            {...(params as any)}
          />
        )}
      />
    </LocalizationProvider>
  );
};
