import { makeStyles } from "@material-ui/core";

export const useStyledButtons = makeStyles({

    bttPagination: {
        backgroundColor: '#263C9A',
        fontFamily: `"Open Sans", sans-serif`,
        fontWeight: 600,
        color: 'white',
        textTransform: 'none',
        "&:hover": {
            backgroundColor: '#001364',
        }
    },

});

export const colors = {
    primary: '#3C7EF7'
}