import { makeStyles } from "@material-ui/core"
import { RiChat1Fill } from "react-icons/ri"
import { colors } from "../../assets/styles";

const useStyles = makeStyles({

    header: {

        display: 'flex',
        alignItems: 'center',
        columnGap: '10px',
        fontWeight: 700,
        color: '#121A26',
        paddingBottom: '20px',

        "& svg": {
            width: '1.25em',
            height: '1.25em',
            fill: colors.primary
        }

    }

})

export const Header: React.FC = () => {

    const classes = useStyles();

    return <header className={classes.header}>

        <RiChat1Fill />

        <p>Radinfo — Liberação de pedidos</p>

    </header>

}