import { toMoney } from "../../functions";
import { IItemResumoOrcamento } from "../../services/api/assinatura-digital/assinatura-digital-service";
import { LinhaRelatorio } from "../linha-relatorio";
import { useStyles } from "./styles";

type Props = {
    data: IItemResumoOrcamento;
}

export const ItemAssinaturaDigital: React.FC<Props> = ({ data }) => {

    const classes = useStyles();

    return <div className={classes.container}>

        <span className={classes.titulo}>

            <strong>{`${data.quantidade}x ${data.unidade || ''}`}</strong>

            <p>{data.descricao}</p>

        </span>

        <LinhaRelatorio titulo="Valor bruto" valor={toMoney(data.valorBruto) || 'R$ 0,00'} semNegrito />

        <LinhaRelatorio titulo="Valor líquido" valor={toMoney(data.valorLiquido) || 'R$ 0,00'} semNegrito />

        <LinhaRelatorio titulo="Desconto" valor={toMoney(data.desconto) || 'R$ 0,00'} semNegrito />

    </div>

}