import { createContext, ReactNode, useState } from "react";
import { IAnexoPrevisaoCompra } from "../api";

interface IAnexoEmExibicao {
  type: string;
  src: string;
}

interface IPrevisaoCompraContextData {
  anexosPrevisao: IAnexoPrevisaoCompra[];
  anexoEmExibicao: IAnexoEmExibicao;
  setAnexosPrevisao: React.Dispatch<
    React.SetStateAction<IAnexoPrevisaoCompra[]>
  >;
  setAnexoEmExibicao: React.Dispatch<React.SetStateAction<IAnexoEmExibicao>>;
}

type PrevisaoCompraProviderProps = {
  children: ReactNode;
};

export const PrevisaoCompraContext = createContext(
  {} as IPrevisaoCompraContextData
);

export function PrevisaoCompraProvider({
  children,
}: PrevisaoCompraProviderProps) {
  const [anexosPrevisao, setAnexosPrevisao] = useState<IAnexoPrevisaoCompra[]>(
    []
  );

  const [anexoEmExibicao, setAnexoEmExibicao] = useState<IAnexoEmExibicao>({
    src: "",
    type: "",
  });

  return (
    <PrevisaoCompraContext.Provider
      value={{
        anexosPrevisao,
        anexoEmExibicao,
        setAnexosPrevisao,
        setAnexoEmExibicao,
      }}
    >
      {children}
    </PrevisaoCompraContext.Provider>
  );
}
