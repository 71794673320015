import { ReactNode, forwardRef } from "react";
import { Button, makeStyles } from "@material-ui/core";

type TBaseClicavelProps = {
  children?: ReactNode;
  className?: string;
  onClick: () => void;
};

const useStyles = makeStyles({
  baseClicavel: {
    display: "inline-block",
    padding: 0,
    textTransform: "none",
    lineHeight: "1",
    fontWeight: 400,
    fontFamily: `'Montserrat', sans-serif`,
    fontSize: "1em",
  },
});

export const BaseClicavel = forwardRef<HTMLButtonElement, TBaseClicavelProps>(
  ({ children, className, onClick }, ref) => {
    const classes = useStyles();

    return (
      <Button
        ref={ref}
        onClick={onClick}
        className={`${classes.baseClicavel} ${className || ""}`}
      >
        {children}
      </Button>
    );
  }
);
