import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    container: {
        width: '100%',
        background: 'white',
        borderRadius: 3,
        padding: 0,
        textTransform: 'none',
        
        "&:focus": {
            background: 'white',
        },

    },
    labelContainer: {
        justifyContent: 'flex-start'
    },
    icone: {

        padding: 15,
        width: 63,
        borderRight: '0.5px solid #E3ECFA',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        "& svg": {
            height: 30,
            width: 'auto',
            fill: "#303030",
        },

    },
    nome: {
        fontSize: 17,
        padding: 20,
        color: "#303030",
    }
});