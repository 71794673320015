import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "15px",
    gap: "10px",

    "& h1": {
      fontSize: "1.1em",
    },
  },

  listagemDeSolicitacoes: {
    display: "grid",
    rowGap: "15px",
  },

  solicitacaoDeSenha: {
    width: "100%",
    padding: "10px",
    backgroundColor: "white",
    lineHeight: "1.4",

    "&:hover": {
      backgroundColor: "white",
    },
  },

  propriedadeDaSolicitacaoDeSenha: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",

    "& *": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },

  emptyStateFiltro: {
    marginTop: "3em",
  },
});
