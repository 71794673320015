import { Button, ButtonProps } from "@material-ui/core";
import { useStyles } from "./styles";

export const BtnSecundario: React.FC<ButtonProps> = ({ ...rest }) => {

    const classes = useStyles();

    return <Button
        className={classes.container}
        {...rest}
    >
        Voltar
    </Button>

}