import { limitarEmDuasCasas, toMoney } from "../../functions";
import { TItemRelatorio } from "../../services/api/relatorio/relatorio-service"
import { LinhaRelatorio } from "../linha-relatorio";
import { useStyles } from "./styles";

type Props = {
    data: TItemRelatorio;
}

export const ItemRelatorio: React.FC<Props> = ({ data }) => {

    const classes = useStyles();

    // const temAcrescimos = data.percDescProd < 0;
    // const descItemProduto = data.descontoItem <0;

    return <div className={classes.container}>

        <span className={classes.titulo}>

            <strong>{`${data.quantidade}x `}</strong>

            <p>{data.descricao}</p>

        </span>

        {
            // temAcrescimos ?
            //     <LinhaRelatorio titulo="Acréscimos" valor={`+ ${toMoney(Math.abs(data.descontoProdutos)) || "R$ 0,00"} (${limitarEmDuasCasas(Math.abs(data.percDescProd))}%)`} semNegrito />
            //     :
                <LinhaRelatorio titulo="Desconto Negociação" valor={`${toMoney(data.descontoProdutos) || "R$ 0,00"} (${limitarEmDuasCasas(data.percDescProd)}%)`} semNegrito />
        }

        {
            // descItemProduto ?
            //     <LinhaRelatorio titulo="Acréscimos" valor={`+ ${toMoney(Math.abs(data.valorDesconto)) || "R$ 0,00"} (${limitarEmDuasCasas(Math.abs(data.descontoItem))}%)`} semNegrito />
            //     :
                <LinhaRelatorio titulo="Desconto Item" valor={`${toMoney(data.valorDesconto) || "R$ 0,00"} (${limitarEmDuasCasas(data.descontoItem)}%)`} semNegrito />
        }

        <LinhaRelatorio titulo="Valor bruto" valor={toMoney(data.valorBruto)} semNegrito />

        <LinhaRelatorio titulo="Valor total" valor={toMoney(data.valorTotal)} semNegrito />

        <LinhaRelatorio titulo="Garantia" valor={toMoney(data.garantiaComJuros)} semNegrito />

    </div>

}