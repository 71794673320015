import { createContext, ReactElement, useState } from 'react';
import { AtributoNotificacaoDoApp } from '../../components';
import { toDataBR } from '../../functions';
import { ISolicitacaoSenha, TAbaNotificacao, TNotificacaoDoAplicativo } from '../../interfaces';

interface INotificacaoDoAppContext {
    notificacaoDoAppSelecionada: TNotificacaoDoAplicativo | null;
    solicitacaoPendenteSelecionada: ISolicitacaoSenha | null;
    abaSelecionada: TAbaNotificacao;
    verificarPrevDeCompraERetornarMsg: (notificacao: TNotificacaoDoAplicativo, maisDetalhes?: boolean) => false | ReactElement;
    setNotificacaoDoAppSelecionada: React.Dispatch<React.SetStateAction<TNotificacaoDoAplicativo | null>>;
    setAbaSelecionada: React.Dispatch<React.SetStateAction<TAbaNotificacao>>;
    setSolicitacaoPendenteSelecionada: React.Dispatch<React.SetStateAction<ISolicitacaoSenha | null>>;
}

export const NotificacaoDoAppContext = createContext({} as INotificacaoDoAppContext);

export const NotificacaoDoAppProvider: React.FC = ({ children }) => {

    const [notificacaoDoAppSelecionada, setNotificacaoDoAppSelecionada] = useState<TNotificacaoDoAplicativo | null>(null);

    const [solicitacaoPendenteSelecionada, setSolicitacaoPendenteSelecionada] = useState<ISolicitacaoSenha | null>(null);

    const [abaSelecionada, setAbaSelecionada] = useState<TAbaNotificacao>('finalizadas');

    const verificarPrevDeCompraERetornarMsg = (notificacao: TNotificacaoDoAplicativo, maisDetalhes?: boolean): false | ReactElement => {

        const {
            pedido,
            nomeUsuarioOrigem,
            statusMensagem,
            tipoMensagem,
            fornecedor,
            motivo,
            dataHora
        } = notificacao;

        if (pedido && statusMensagem && tipoMensagem) { // se true = previsão de compra

            return <span>

                {maisDetalhes && <AtributoNotificacaoDoApp>De: {nomeUsuarioOrigem}</AtributoNotificacaoDoApp>}

                <AtributoNotificacaoDoApp>Pedido: {pedido}</AtributoNotificacaoDoApp>

                <AtributoNotificacaoDoApp>Fornecedor: {fornecedor}</AtributoNotificacaoDoApp>

                <AtributoNotificacaoDoApp>Tipo mensagem: {tipoMensagem}</AtributoNotificacaoDoApp>

                <AtributoNotificacaoDoApp>Motivo: {motivo}</AtributoNotificacaoDoApp>

                {maisDetalhes && <AtributoNotificacaoDoApp>Data: {toDataBR(dataHora, true)}</AtributoNotificacaoDoApp>}

            </span>

        } else {

            return false;

        }

    }

    return (
        <NotificacaoDoAppContext.Provider
            value=
            {{
                verificarPrevDeCompraERetornarMsg,
                notificacaoDoAppSelecionada,
                setNotificacaoDoAppSelecionada,
                abaSelecionada,
                setAbaSelecionada,
                solicitacaoPendenteSelecionada, setSolicitacaoPendenteSelecionada
            }}
        >
            {children}
        </NotificacaoDoAppContext.Provider >
    )
}