import { makeStyles } from "@material-ui/core";

import { colors } from "../../assets/styles";

export const useStyles = makeStyles({
  containerFiltro: {
    display: "flex",
    flexDirection: "column",
  },

  opcoesFiltro: {
    display: "grid",
    gridTemplateColumns: ".8fr .2fr",
    gap: "10px",
  },

  btnFiltro: {
    color: colors.primary,
    background: `${colors.primary}1A`,
    height: "3.5em",

    "&:hover": {
      background: `${colors.primary}33`,
    },
  },
  
  btnFecharFiltro: {
    color: "#F03738",
    background: `#F037381A`,
    height: "3.5em",

    "&:hover": {
      background: `#F0373833`,
    },
  },

  filtros: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },

  filtroSolicitacaoDeSenha: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: ".9em",
    borderRadius: ".3em",
    paddingLeft: "10px",
  },

  bolinhaDestaqueMenuFiltro: {
    width: ".6em",
    height: ".6em",
    borderRadius: "50%",
  },

  opcaoMenuDoFiltro: {
    fontSize: ".9em",
    textOverflow: "clip",
  },

  containerFiltrosDeData: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginTop: "15px",
  },

  contentFiltrosDeData: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "10px",
  },
});
