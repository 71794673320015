import { api, ApiException } from "..";
import { toDataBR } from "../../../functions";
import {
  IResponderSolicitacao,
  IRetornoPaginadoApi,
  ISolicitacaoSenha,
} from "../../../interfaces";

interface IRetornoObterSolicitacoesDoUsuario extends IRetornoPaginadoApi {
  dado: ISolicitacaoSenha[];
}

export interface ILoteDocumento {
  codLoja: number;
  tipo: string;
  codFor: number;
  nomeFor: string;
  documento: string;
  np: number;
  totalParcelas: number;
  emissao: string;
  vencimento: string;
  obs: string | null;
  desconto: number;
  jurosPago: number;
  taxaCartorio: number;
  multa: number;
  valorRestante: number;
  valorDoc: number;
}

export interface ILoteDocumentoAgrupadoPorLoja {
  id: number;
  razaoSocial: string;
  totalLoja: number;
  documentos: ILoteDocumento[];
}

export interface IValoresDoLoteDeContasAPagar {
  valorDoc: number;
  valorRestante: number;
  desconto?: number;
  jurosPago?: number;
  multa?: number;
  taxaCartorio?: number;
}

export interface IContasAPagarPendente {
  totalPendente: number;
  vencimento: string;
}

export interface IRetornoObterContasAPagarPendentes {
  totalPaginas: number;
  valorTotalPendente: number;
  valorTotalPendenteDoDiaDeHoje: number;
  pendentes: IContasAPagarPendente[];
}

export interface IDetalhesSolicitacaoFinanceira {
  codigoConta: string;
  documento: number;
  derivadoVenda: boolean;
  bancoAgencia: string;
  cidadeUf: string;
  emissao: string;
  vencimento: string;
  pagamento: string;
  codHistorico: number;
  historico: string;
  complemento: string;
  tipo: string;
  valor: number;
  digitador: string;
  dataHoraDigitacao: string;
  conferente1: string;
  dataHoraConferencia1: string;
  conferente2: string;
  dataHoraConferencia2: string;
  mesRef: string;
  atualizacao: string;
  codUsuario: number;
  transferiu: string;
  codigoContaOrigem: string;
  documentoOrigem: string;
  origemDocumento: string;
  infoOrigem: string;
  cheque: string;
  codLoja: number;
  contabil: string;
  codSubCc: number;
  codFormaPgto: number;
  idcaixa: string;
  depNaoIndent: string;
  dataContabil: string;
  codOperadorCx: number;
  ecfcupom: number;
  coo: number;
  numeroLote: string;
  docArqBank: null;
  histContabil: string;
  statusEnvioServidor: null;
  codLojaContabil: number;
  ignorarContabilidade: string;
  flagenviado: null;
  dataConciliado: null;
  codUsuarioConciliado: null;
  docbancarioOfx: string;
  autorizado: string;
  tipoEnvio: string;
  valorTotal: number;
  docFina: string;

  [chave: string]: string | number | null | boolean;
}

interface ICabecalhoInclusaoContasAPagar {
  id: number;
  numPrevisao: number;
  valorTotal: number;
  fornecedor: string;
  emissao: string | null;
  vencimento: string | null;
  observacao: string;
  valorPago: number;
  valorRestante: number;
}

interface INotaInclusaoContasAPagar {
  documento: string;
  parcela: number;
  valorLancado: number;
  pago: boolean;
}

export interface IDetalhesInclusaoContasAPagar {
  cabecalho: ICabecalhoInclusaoContasAPagar;
  notas: INotaInclusaoContasAPagar[];
}

interface ICabecalhoJuros {
  orcamento: number;
  loja: number;
  codCliente?: number;
  nomeCliente: string;
  valorFinal: number;
  juros: number;
  valorSemJuros: number;
  data?: string;
  codCondicaoPgto?: number;
  descricaoCondicaoPgto: string;
}

interface INegociacaoJuros {
  nrParcela: number;
  codFormaPgto: number;
  descricaoFormaPagamento: string;
  valorFinal: number;
  juros: number;
  valorSemJuros: number;
  vencimento: string;
}

export interface IDetalhesJuros {
  cabecalho: ICabecalhoJuros;
  negociacao: INegociacaoJuros[];
}

const obterSolicitacoesDoUsuario = async (
  CurrentPage: number
): Promise<IRetornoObterSolicitacoesDoUsuario> => {
  try {
    const r = await api.get<IRetornoObterSolicitacoesDoUsuario>(
      `/solicitacaosenhaporusuario`,
      { params: { CurrentPage, Limit: 10 } }
    );

    return r.data;
  } catch (erro) {
    throw new ApiException(erro, "Erro ao buscar solicitações do usuário");
  }
};

const obterSolicitacoesDoUsuarioComFiltroDeData = async (
  CurrentPage: number,
  dataInicial: string,
  dataFinal: string
) => {
  try {
    const r = await api.get<IRetornoObterSolicitacoesDoUsuario>(
      `/solicitacaosenhaporusuariocomdata`,
      {
        params: { CurrentPage, Limit: 10, dataInicial, dataFinal },
      }
    );

    return r.data;
  } catch (erro) {
    throw new ApiException(
      erro,
      "Erro ao buscar solicitações do usuário usando a data"
    );
  }
};

const obterSolicitacaoSenhaPorId = async (
  sequencial: number
): Promise<ISolicitacaoSenha> => {
  try {
    const r = await api.get<ISolicitacaoSenha>(
      `/solicitacaosenhaporid/${sequencial}`
    );

    return r.data;
  } catch (erro) {
    throw new ApiException(erro, "Erro ao buscar solicitação de senha");
  }
};

const obterSolicitacoesPendentesSolicitadasPeloUsuario = async (
  CurrentPage: number
): Promise<IRetornoObterSolicitacoesDoUsuario> => {
  try {
    const r = await api.get<IRetornoObterSolicitacoesDoUsuario>(
      `/solicitacaosenhapendenteporusuario`,
      { params: { CurrentPage, Limit: 10 } }
    );

    return r.data;
  } catch (erro) {
    throw new ApiException(
      erro,
      "Erro ao buscar solicitações pendentes solicitadas pelo usuário"
    );
  }
};

const responderSolicitacao = async (body: IResponderSolicitacao) => {
  try {
    await api.put(`/autorizarsolicitacaosenha`, body, {});
  } catch (erro) {
    throw new ApiException(erro, "Erro ao responder solicitação");
  }
};

const alterarStatusMensagemEUsuarioOrigemEDestinoPorId = async (
  sequencial: number
) => {
  try {
    await api.put(`/alterarstatus_usuariosolicitacaosenha`, undefined, {
      headers: { id: String(sequencial) },
    });
  } catch (erro) {
    throw new ApiException(erro, "Erro ao alterar status");
  }
};

const obterDocumentosDoLoteAgrupadosPorLoja = async (
  IdLote: string
): Promise<ILoteDocumentoAgrupadoPorLoja[]> => {
  try {
    const { data } = await api.get(
      `/obter-documentos-do-lote-agrupados-por-loja`,
      {
        params: { IdLote },
      }
    );

    return data;
  } catch (erro) {
    throw new ApiException(
      erro,
      "Não conseguimos buscar informações dos lotes de contas a pagar"
    );
  }
};

const autorizarLiberacoesBaixaContasAPagar = async (
  idLote: string,
  statusAutorizacao: "S" | "N"
) => {
  try {
    await api.put(`/autorizar-liberacoes-baixa-docpagar`, {
      idLote,
      statusAutorizacao,
    });
  } catch (erro) {
    throw new ApiException(
      erro,
      "Não conseguimos autorizar a liberação de contas a pagar"
    );
  }
};

const obterValoresDoLoteDeContasAPagar = async (idLote: string) => {
  try {
    const { data } = await api.get<IValoresDoLoteDeContasAPagar>(
      `/total-lote-documentos`,
      {
        params: {
          idLote,
        },
      }
    );

    return data;
  } catch (erro) {
    throw new ApiException(
      erro,
      "Não conseguimos obter o valor total do lote de contas a pagar"
    );
  }
};

const obterContasAPagarPendentes = async (CurrentPage: number) => {
  try {
    const { data } = await api.get<IRetornoObterContasAPagarPendentes>(
      `/obter-contas-a-pagar-pendentes`,
      {
        params: { CurrentPage, Limit: 10 },
      }
    );

    return data;
  } catch (erro) {
    throw new ApiException(
      erro,
      "Não conseguimos obter o resumo de documentos de contas a pagar pendentes"
    );
  }
};

const obterDetalhesDeContasAPagarPendente = async (Vencimento: string) => {
  try {
    const { data } = await api.get<Omit<ILoteDocumento, "codLoja">[]>(
      `/obter-detalhes-de-contas-a-pagar-pendentes-pelo-dia`,
      {
        params: {
          Vencimento,
        },
      }
    );

    return data;
  } catch (erro) {
    throw new ApiException(
      erro,
      "Não conseguimos obter detalhes de contas a pagar pendentes do dia informado"
    );
  }
};

const obterVencimentosDoLoteDeContasAPagar = async (idLote: string) => {
  try {
    const { data } = await api.get<string[]>(`/vencimentos-do-lote`, {
      params: { idLote },
    });

    const formattedData = data.map((vencimento) => toDataBR(vencimento));

    return formattedData;
  } catch (erro) {
    throw new ApiException(
      erro,
      "Não foi possível obter os vencimentos do lote informado"
    );
  }
};

const obterDetalhesSolicitacaoFinanceira = async (
  documento: number,
  codigoConta: string
): Promise<IDetalhesSolicitacaoFinanceira> => {
  try {
    const { data } = await api.get<IDetalhesSolicitacaoFinanceira>(
      `/obter-detalhes-da-solicitacao-financeira`,
      { params: { documento, codigoConta } }
    );

    return data;
  } catch (erro) {
    throw new ApiException(
      erro,
      "Não foi possível obter os detalhes da solicitação financeira"
    );
  }
};

const obterDetalhesDaInclusaoDeContasAPagar = async (
  documento: string,
  codLoja: number
): Promise<IDetalhesInclusaoContasAPagar> => {
  try {
    const { data } = await api.get<IDetalhesInclusaoContasAPagar>(
      `/obter-detalhes-da-previsao-de-pagamento`,
      { params: { documento, codLoja } }
    );

    return data;
  } catch (erro) {
    throw new ApiException(
      erro,
      "Não foi possível obter os detalhes da inclusão de contas a pagar"
    );
  }
};

const obterDetalhesDaInclusaoDeContasAPagarCONSULTA = async (
  documento: string,
  codLoja: number
): Promise<IDetalhesInclusaoContasAPagar> => {
  try {
    const { data } = await api.get<IDetalhesInclusaoContasAPagar>(
      `/obter-detalhes-da-previsao-de-pagamento-CONSULTA`,
      { params: { documento, codLoja } }
    );

    return data;
  } catch (erro) {
    throw new ApiException(
      erro,
      "Não foi possível obter os detalhes da inclusão de contas a pagar da CONSULTA"
    );
  }
};

const obterDetalhesDeJuros = async (
  documento: string,
  codLoja: number
): Promise<IDetalhesJuros> => {
  try {
    const { data } = await api.get<IDetalhesJuros>(`obter-detalhes-de-juros`, {
      params: { documento, codLoja },
    });

    return data;
  } catch (erro) {
    throw new ApiException(
      erro,
      "Não foi possível obter os detalhes da solicitação de juros tipo gerência"
    );
  }
};

export const SolicitacaoSenhaService = {
  obterSolicitacoesDoUsuario,
  obterSolicitacoesDoUsuarioComFiltroDeData,
  obterSolicitacaoSenhaPorId,
  obterSolicitacoesPendentesSolicitadasPeloUsuario,
  responderSolicitacao,
  alterarStatusMensagemEUsuarioOrigemEDestinoPorId,
  obterDocumentosDoLoteAgrupadosPorLoja,
  autorizarLiberacoesBaixaContasAPagar,
  obterValoresDoLoteDeContasAPagar,
  obterContasAPagarPendentes,
  obterDetalhesDeContasAPagarPendente,
  obterVencimentosDoLoteDeContasAPagar,
  obterDetalhesSolicitacaoFinanceira,
  obterDetalhesDaInclusaoDeContasAPagar,
  obterDetalhesDaInclusaoDeContasAPagarCONSULTA,
  obterDetalhesDeJuros,
};
