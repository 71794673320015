import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    position: "fixed",
    bottom: "5em",
    right: "1em",
    transition: "all 0.2s",
  },
});
