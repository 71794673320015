import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab"
import { useEffect } from "react";
import { esperar } from "../../functions";
import { useGlobalContext } from "../../hooks";

const useThisStyles = makeStyles({

    notificacao: {
        position: 'fixed',
        top: 0,
        marginTop: '1em',
        zIndex: 10,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 10px',

        "& .MuiAlert-root": {
            boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.15)'
        }

    }

});

export const Notificacao: React.FC = () => {

    const classes = useThisStyles();

    const { notificacao, refNotificacao, setNotificacao } = useGlobalContext();

    const handleClickFecharNotificacao = () => setNotificacao({ mensagem: '', severity: '', tempoEmTela: 0 });

    useEffect(() => {

        if (notificacao.severity || notificacao.mensagem) {

            refNotificacao.current?.classList.add('fadeInDown');

            esperar(notificacao.tempoEmTela - 500).then(() => refNotificacao.current?.classList.replace('fadeInDown', 'fadeOutUp'));

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificacao]);

    if (!notificacao.severity || !notificacao.mensagem) return <></>

    return <div ref={refNotificacao} className={classes.notificacao}>

        <Alert onClose={handleClickFecharNotificacao} severity={notificacao.severity}>{notificacao.mensagem}</Alert>

    </div>

}