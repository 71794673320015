import { Button, IconButton, Menu, MenuItem } from "@material-ui/core";
import { useMemo, useState } from "react";
import { MdClose } from "react-icons/md";
import { GoGear } from "react-icons/go";
import { IInformacaoControleDeAcesso } from "..";
import { obterCamelCase } from "../../functions";
import { useStyles } from "./styles";
import { useConfiguracaoDeFiltroContext, useGlobalContext } from "../../hooks";
import { obterFiltrosUtilizados } from "../../functions/dados/dados";
import { IoFilter } from "react-icons/io5";
import { DataPicker } from "../data-picker";

interface FiltroSolicitacaoDeSenhaProps {
  data: IInformacaoControleDeAcesso;
  onRemoverFiltro(filtroParaRemover: IInformacaoControleDeAcesso): void;
}

function FiltroSolicitacaoDeSenha({
  data,
  onRemoverFiltro,
}: FiltroSolicitacaoDeSenhaProps) {
  const classes = useStyles();

  return (
    <div
      className={classes.filtroSolicitacaoDeSenha}
      style={{
        color: "#00AA35",
        background: `#00AA351A`,
        height: "3.5em",
      }}
    >
      <span>{data.titulo}</span>

      <IconButton
        style={{
          color: "#00AA35",
        }}
        onClick={() => onRemoverFiltro(data)}
      >
        <MdClose />
      </IconButton>
    </div>
  );
}

interface IMenuDeFiltroSolicitacoesDeSenha {
  onPesquisarPeloFiltro(): void;
}

export function MenuDeFiltroSolicitacoesDeSenha({
  onPesquisarPeloFiltro,
}: IMenuDeFiltroSolicitacoesDeSenha) {
  const classes = useStyles();

  const {
    filtrosDeSolicitacaoDeSenhaAtivos,
    exibindoOpcoesDeFiltroDeData,
    dataInicialDoFiltro,
    dataFinalDoFiltro,
    setDataInicialDoFiltro,
    setDataFinalDoFiltro,
    setExibindoOpcoesDeFiltroDeData,
    setFiltrosDeSolicitacaoDeSenhaAtivos,
    setPesquisaPeloFiltroDeDataAtiva,
  } = useConfiguracaoDeFiltroContext();

  const { paginaAtual } = useGlobalContext();

  const filtrosUtilizados = useMemo(
    () => obterFiltrosUtilizados(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginaAtual]
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const menuEstaAberto = Boolean(anchorEl);

  const handleAbrirMenu = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleFecharMenu = () => setAnchorEl(null);

  function handleAdicionarFiltro(
    filtroParaAdicionar: IInformacaoControleDeAcesso
  ) {
    setFiltrosDeSolicitacaoDeSenhaAtivos((antigosValores) => [
      ...antigosValores,
      filtroParaAdicionar,
    ]);

    handleFecharMenu();
  }

  function handleRemoverFiltro(filtroParaRemover: IInformacaoControleDeAcesso) {
    setFiltrosDeSolicitacaoDeSenhaAtivos((antigosValores) => [
      ...antigosValores.filter(
        (valorAntigo) => valorAntigo !== filtroParaRemover
      ),
    ]);
  }

  function handleAlterarEstadoFiltroDeData() {
    if (!exibindoOpcoesDeFiltroDeData)
      return setExibindoOpcoesDeFiltroDeData(true);

    setExibindoOpcoesDeFiltroDeData(false);
    setPesquisaPeloFiltroDeDataAtiva(false);
  }

  return (
    <div className={classes.containerFiltro}>
      <div className={classes.opcoesFiltro}>
        <Button
          className={classes.btnFiltro}
          onClick={handleAbrirMenu}
          disabled={filtrosUtilizados.length === 0}
        >
          {filtrosUtilizados.length > 0 ? (
            "Adicionar filtro"
          ) : (
            <>
              Configure seus filtros na aba "<GoGear size={15} />"
            </>
          )}
        </Button>

        <Button
          className={
            exibindoOpcoesDeFiltroDeData
              ? classes.btnFecharFiltro
              : classes.btnFiltro
          }
          onClick={handleAlterarEstadoFiltroDeData}
        >
          {exibindoOpcoesDeFiltroDeData ? (
            <MdClose size={22} />
          ) : (
            <IoFilter size={22} />
          )}
        </Button>
      </div>

      {exibindoOpcoesDeFiltroDeData && (
        <div className={classes.containerFiltrosDeData}>
          <div className={classes.contentFiltrosDeData}>
            <DataPicker
              label="Data inicial"
              value={dataInicialDoFiltro}
              onChange={(novoValor) =>
                setDataInicialDoFiltro(String(novoValor))
              }
            />

            <DataPicker
              label="Data final"
              value={dataFinalDoFiltro}
              onChange={(novoValor) => setDataFinalDoFiltro(String(novoValor))}
            />
          </div>

          <Button className={classes.btnFiltro} onClick={onPesquisarPeloFiltro}>
            Pesquisar pelo filtro
          </Button>
        </div>
      )}

      {filtrosDeSolicitacaoDeSenhaAtivos.length > 0 && (
        <div className={classes.filtros}>
          {filtrosDeSolicitacaoDeSenhaAtivos.map((filtro, k) => (
            <FiltroSolicitacaoDeSenha
              key={k}
              data={filtro}
              onRemoverFiltro={handleRemoverFiltro}
            />
          ))}
        </div>
      )}

      <Menu
        anchorEl={anchorEl}
        open={menuEstaAberto}
        onClose={handleFecharMenu}
      >
        {filtrosUtilizados.map((opcaoDeFiltro, k) => (
          <MenuItem
            key={k}
            onClick={() => handleAdicionarFiltro(opcaoDeFiltro)}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              whiteSpace: "initial",
            }}
          >
            <div
              className={classes.bolinhaDestaqueMenuFiltro}
              style={{ background: opcaoDeFiltro.cor }}
            ></div>
            <span className={classes.opcaoMenuDoFiltro}>
              {obterCamelCase(opcaoDeFiltro.titulo)}
            </span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
