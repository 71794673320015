import { createContext, ReactNode, useContext, useState } from "react";
import { IValoresDoLoteDeContasAPagar } from "../api";

interface ValoresDoLoteDeContasAPagarComGeral
  extends IValoresDoLoteDeContasAPagar {
  totalGeral: number;
  vencimentos: string[];
}

interface ContasAPagarContextData {
  valorInicialDoLoteDeContasAPagar: ValoresDoLoteDeContasAPagarComGeral;
  valoresDoLoteDeContasAPagar: ValoresDoLoteDeContasAPagarComGeral;
  setValoresDoLoteDeContasAPagar: React.Dispatch<
    React.SetStateAction<ValoresDoLoteDeContasAPagarComGeral>
  >;
}

interface ContasAPagarProviderProps {
  children: ReactNode;
}

const ContasAPagarContext = createContext({} as ContasAPagarContextData);

function ContasAPagarProvider({ children }: ContasAPagarProviderProps) {
  const valorInicialDoLoteDeContasAPagar: ValoresDoLoteDeContasAPagarComGeral =
    {
      valorDoc: 0,
      valorRestante: 0,
      totalGeral: 0,
      desconto: undefined,
      jurosPago: undefined,
      multa: undefined,
      taxaCartorio: undefined,
      vencimentos: [],
    };

  const [valoresDoLoteDeContasAPagar, setValoresDoLoteDeContasAPagar] =
    useState<ValoresDoLoteDeContasAPagarComGeral>({
      ...valorInicialDoLoteDeContasAPagar,
    });

  return (
    <ContasAPagarContext.Provider
      value={{
        valorInicialDoLoteDeContasAPagar,
        valoresDoLoteDeContasAPagar,
        setValoresDoLoteDeContasAPagar,
      }}
    >
      {children}
    </ContasAPagarContext.Provider>
  );
}

function useContasAPagar() {
  const context = useContext(ContasAPagarContext);

  return context;
}

export { ContasAPagarProvider, useContasAPagar };
