import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    listStyleType: "none",
    fontSize: ".95em",
    padding: ".5em",
    display: "flex",
    flexDirection: "column",
    gap: "1em",

    "& li": {
      background: `#CCCCCC33`,
      borderRadius: ".3em",
      padding: ".5em",
      display: "flex",
      flexDirection: "column",
      gap: ".25em",
    },
  },

  mensagem: {
    fontSize: ".95em",
    padding: ".5em",
    background: `#CCCCCC33`,
    borderRadius: ".3em",
  },
});
