import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    gap: ".25em",
  },

  cabecalhoContainer: {
    fontWeight: "bold",
  },

  atributoModificado: {
    fontSize: ".9em",
    padding: ".25em .5em",
  },

  atributoAntes: {
    backgroundColor: "#F037381A",
    border: "1px solid #F0373840",
    borderRadius: ".3em",

    "& strong": {
      fontWeight: "400",
    },

    "& *": {
      color: "#F03738",
    },
  },

  atributoDepois: {
    backgroundColor: "#00C8531A",
    border: "1px solid #00AA3540",
    borderRadius: ".3em",

    "& strong": {
      fontWeight: "400",
    },

    "& *": {
      color: "#00AA35",
    },
  },
});
