import React, { createContext, useRef } from "react";

interface IScrollDaListaContext {
  refElementoParaFoco: React.RefObject<HTMLButtonElement>;
}

export const ScrollDaListaContext = createContext({} as IScrollDaListaContext);

export const ScrollDaListaProvider: React.FC = ({ children }) => {
  const refElementoParaFoco = useRef<HTMLButtonElement>(null);

  return (
    <ScrollDaListaContext.Provider value={{ refElementoParaFoco }}>
      {children}
    </ScrollDaListaContext.Provider>
  );
};
