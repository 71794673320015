import _ from "lodash";
import { createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { IInformacaoControleDeAcesso } from "../../components";
import { obterCorControleDeAcesso } from "../../functions";
import {
  IDENTIFICADOR_ASSINATURA_DIGITAL_NLAR,
  IDENTIFICADOR_BAIXA_DE_CONTAS_A_PAGAR,
  IDENTIFICADOR_BONIFICACAO,
  IDENTIFICADOR_INCLUSAO_DE_CONTAS_A_PAGAR,
  IDENTIFICADOR_JUROS,
  IDENTIFICADOR_ORDEM_DE_COMPRAS_CONSUMO,
  IDENTIFICADOR_ORDEM_DE_PAGAMENTO,
  IDENTIFICADOR_ORDEM_DE_SERVICO,
  IDENTIFICADOR_PREVISAO_DE_COMPRA_NLAR,
  IDENTIFICADOR_SENHA_FINANCEIRA,
  IDENTIFICADOR_SOLICITACAO_DE_SENHA_COMUM,
} from "../../utils";

interface ConfiguracaoDeFiltroContextData {
  opcoesDeFiltro: IInformacaoControleDeAcesso[];
  filtrosDeSolicitacaoDeSenhaAtivos: IInformacaoControleDeAcesso[];
  exibindoOpcoesDeFiltroDeData: boolean;
  dataInicialDoFiltro: string;
  dataFinalDoFiltro: string;
  pesquisaPeloFiltroDeDataAtiva: boolean;
  setFiltrosDeSolicitacaoDeSenhaAtivos: React.Dispatch<
    React.SetStateAction<IInformacaoControleDeAcesso[]>
  >;
  setExibindoOpcoesDeFiltroDeData: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setDataInicialDoFiltro: React.Dispatch<React.SetStateAction<string>>;
  setDataFinalDoFiltro: React.Dispatch<React.SetStateAction<string>>;
  setPesquisaPeloFiltroDeDataAtiva: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

interface ConfiguracaoDeFiltroProviderProps {
  children: ReactNode;
}

const ConfiguracaoDeFiltroContext = createContext(
  {} as ConfiguracaoDeFiltroContextData
);

function ConfiguracaoDeFiltroProvider({
  children,
}: ConfiguracaoDeFiltroProviderProps) {
  const opcoesDeFiltro = useMemo(() => {
    const listaDeFiltrosDisponiveis = [
      obterCorControleDeAcesso(
        undefined,
        IDENTIFICADOR_ORDEM_DE_COMPRAS_CONSUMO
      ),
      obterCorControleDeAcesso(undefined, IDENTIFICADOR_ORDEM_DE_SERVICO),
      obterCorControleDeAcesso(undefined, IDENTIFICADOR_ORDEM_DE_PAGAMENTO),
      obterCorControleDeAcesso(
        undefined,
        IDENTIFICADOR_PREVISAO_DE_COMPRA_NLAR
      ),
      obterCorControleDeAcesso(
        undefined,
        IDENTIFICADOR_ASSINATURA_DIGITAL_NLAR
      ),
      obterCorControleDeAcesso(
        undefined,
        IDENTIFICADOR_BAIXA_DE_CONTAS_A_PAGAR
      ),
      obterCorControleDeAcesso(
        undefined,
        IDENTIFICADOR_SOLICITACAO_DE_SENHA_COMUM
      ),
      obterCorControleDeAcesso(undefined, IDENTIFICADOR_SENHA_FINANCEIRA),
      obterCorControleDeAcesso(undefined, IDENTIFICADOR_BONIFICACAO),
      obterCorControleDeAcesso(
        undefined,
        IDENTIFICADOR_INCLUSAO_DE_CONTAS_A_PAGAR
      ),
      obterCorControleDeAcesso(undefined, IDENTIFICADOR_JUROS),
    ];

    return _.orderBy(listaDeFiltrosDisponiveis, ["titulo"], "asc");
  }, []);

  const [
    filtrosDeSolicitacaoDeSenhaAtivos,
    setFiltrosDeSolicitacaoDeSenhaAtivos,
  ] = useState<IInformacaoControleDeAcesso[]>([]);

  const [exibindoOpcoesDeFiltroDeData, setExibindoOpcoesDeFiltroDeData] =
    useState(false);

  const [dataInicialDoFiltro, setDataInicialDoFiltro] = useState(
    new Date().toISOString()
  );

  const [dataFinalDoFiltro, setDataFinalDoFiltro] = useState(
    new Date().toISOString()
  );

  const [pesquisaPeloFiltroDeDataAtiva, setPesquisaPeloFiltroDeDataAtiva] =
    useState(false);

  useEffect(() => {
    setDataInicialDoFiltro(new Date().toISOString());
    setDataFinalDoFiltro(new Date().toISOString());
  }, [exibindoOpcoesDeFiltroDeData]);

  return (
    <ConfiguracaoDeFiltroContext.Provider
      value={{
        opcoesDeFiltro,
        filtrosDeSolicitacaoDeSenhaAtivos,
        exibindoOpcoesDeFiltroDeData,
        dataInicialDoFiltro,
        dataFinalDoFiltro,
        pesquisaPeloFiltroDeDataAtiva,
        setFiltrosDeSolicitacaoDeSenhaAtivos,
        setExibindoOpcoesDeFiltroDeData,
        setDataInicialDoFiltro,
        setDataFinalDoFiltro,
        setPesquisaPeloFiltroDeDataAtiva,
      }}
    >
      {children}
    </ConfiguracaoDeFiltroContext.Provider>
  );
}

export { ConfiguracaoDeFiltroProvider, ConfiguracaoDeFiltroContext };
