import { ApiException } from "../../services";

export const toMoney = (valor: any): string => {
  const valorNumerico = parseFloat(valor);

  if (!valorNumerico) return "R$ 0,00";

  return valorNumerico.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
};

export const toDataBR = (valor: string, comHora?: boolean): string => {
  if (!valor) return "";

  const dia = valor.substring(8, 10);

  const mes = valor.substring(5, 7);

  const ano = valor.substring(0, 4);

  const hora = valor.substring(11, 16);

  return dia + "/" + mes + "/" + ano + (comHora ? ` às ${hora}` : "");
};

export const obterMensagemApiException = (erro: any): string => {
  if (erro instanceof ApiException) {
    return erro.message;
  } else {
    return "Ocorreu um problema";
  }
};

export const limitarEmDuasCasas = (valor: number | string): string =>
  new Intl.NumberFormat("en-IN", { maximumFractionDigits: 2 }).format(
    typeof valor === "number" ? valor : Number.parseInt(valor || "0", 10)
  );

export const obterCamelCase = (texto: string): string => {
  if (!texto) return "";

  const palavrasDoTextoEmLowercase = texto.toLowerCase().split(" ");

  const primeiraPalavra = palavrasDoTextoEmLowercase[0];

  palavrasDoTextoEmLowercase.shift();

  return `${primeiraPalavra[0].toUpperCase()}${primeiraPalavra.substring(
    1
  )} ${palavrasDoTextoEmLowercase.join(" ")}`;
};
