import React, { createContext, useState } from 'react';

interface IControleContext {
    listagemDeOpcoesDeRelatorioAtiva: boolean;
    setListagemDeOpcoesDeRelatorioAtiva: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ControleContext = createContext({} as IControleContext);

export const ControleProvider: React.FC = ({ children }) => {

    const [listagemDeOpcoesDeRelatorioAtiva, setListagemDeOpcoesDeRelatorioAtiva] = useState(false);

    return (
        <ControleContext.Provider
            value={{ listagemDeOpcoesDeRelatorioAtiva, setListagemDeOpcoesDeRelatorioAtiva }}
        >
            {children}
        </ControleContext.Provider >
    )
}