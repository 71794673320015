import { Button } from "@material-ui/core";
import { useStyles } from "./styles";
import { TiArrowBack } from "react-icons/ti";
import { usePrevisaoCompra } from "../../hooks";

export const ExibicaoAnexo: React.FC = () => {
  const classes = useStyles();

  const { anexoEmExibicao, setAnexoEmExibicao } = usePrevisaoCompra();

  if (!anexoEmExibicao.src || !anexoEmExibicao.type) return <></>;

  return (
    <div className={classes.container}>
      <iframe
        srcDoc={`
          <!DOCTYPE html>
          <html lang="pt-br">
          <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Anexo</title>
              <style>
                  body {
                      margin: 0;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      height: 100vh;
                      overflow: auto;
                  }
                  object {
                      max-height: 100%;
                      max-width: 100%;
                  }
              </style>
          </head>
          <body>
              <object data="${anexoEmExibicao.src}" type="${anexoEmExibicao.type}"></object>
          </body>
          </html>
    `}
        width="100%"
      />

      <Button
        className={classes.botaoRetorno}
        variant="contained"
        onClick={() => setAnexoEmExibicao({ src: "", type: "" })}
      >
        <TiArrowBack />
      </Button>
    </div>
  );
};
