import { Fab } from "@material-ui/core";
import { HiArrowUp } from "react-icons/hi";
import { useStyles } from "./styles";

interface IBotaoVoltarParaOTopoProps {
  onClick?(): void;
}

export const BotaoVoltarParaOTopo: React.FC<IBotaoVoltarParaOTopoProps> = ({
  onClick,
}) => {
  const classes = useStyles();

  function handleVoltarParaOTopo() {
    onClick?.();

    window.scrollTo(0, 0);
  }

  return (
    <div className={classes.container}>
      <Fab
        onClick={handleVoltarParaOTopo}
        color="primary"
        aria-label="Voltar para o topo"
      >
        <HiArrowUp size={20} />
      </Fab>
    </div>
  );
};
