import { AbaNotificacao } from "../aba-notificacao";
import { useStyles } from "./styles"

type Props = {
    setIntervaloDeBuscaAtivo: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AbasNotificacoes: React.FC<Props> = ({ setIntervaloDeBuscaAtivo }) => {

    const classes = useStyles();

    return <section className={classes.container}>

        <AbaNotificacao
            aba="finalizadas"
            setIntervaloDeBuscaAtivo={setIntervaloDeBuscaAtivo}
        />

        <AbaNotificacao
            aba="pendentes"
            setIntervaloDeBuscaAtivo={setIntervaloDeBuscaAtivo}
        />

    </section>

}