import {
  IRetornoPaginadoApi,
  TNotificacaoDoAplicativo,
} from "../../../interfaces";
import { api } from "../api";
import { ApiException } from "../api-exception";

type TRetornoObterNotificacoes = IRetornoPaginadoApi & {
  dado: TNotificacaoDoAplicativo[];
};

const obterAsync = async (
  CurrentPage: number
): Promise<TRetornoObterNotificacoes> => {
  try {
    const r = await api.get<TRetornoObterNotificacoes>(
      `/Notificacoes?Paginacao.CurrentPage=${CurrentPage}&Paginacao.Limit=${10}`
    );

    return r.data;
  } catch (erro) {
    throw new ApiException(erro, "Erro ao buscar notificações do aplicativo");
  }
};

const obterPorIdAsync = async (
  idMensagem: number
): Promise<TNotificacaoDoAplicativo> => {
  try {
    const r = await api.get(`/NotificacaoPorId`, {
      params: { idMensagem },
    });

    return r.data;
  } catch (erro) {
    throw new ApiException(erro, "Erro ao buscar notificação do aplicativo");
  }
};

export const NotificacaoService = {
  obterAsync,
  obterPorIdAsync,
};
