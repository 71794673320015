import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({

    pngt: {
        fontWeight: 600
    }

});

export const PNGT: React.FC = ({ children }) => {

    const classes = useStyles();

    return <span className={classes.pngt}>{children}</span>

}