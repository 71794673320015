import { makeStyles } from "@material-ui/core";
import { colors } from "../../assets/styles";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: "15px",

    "& *": {
      lineHeight: "160%",
    },
  },
  secao: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    paddingBottom: "1em",
  },
  cabecalhoLista: {
    marginBottom: "0",
  },
  pendente: {
    transition: "all .2s",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: `${colors.primary}1A`,
    },
  },
  ultimoPendente: {
    marginBottom: "5em",
  },
  emptyState: {
    marginTop: "3em",
  },
});
