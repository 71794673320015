import ReactDOM from "react-dom";
import { Routes } from "./app/routes";

// styles
import "./app/shared/assets/styles/animacoes.css";
import "./app/shared/assets/styles/responsividade.css";
import "./app/shared/assets/styles/index.css";

// services
import {
  GlobalProvider,
  NotificacaoDoAppProvider,
  PaginacaoProvider,
  PrevisaoCompraProvider,
} from "./app/shared/services";

// material-ui
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core";
import { ControleProvider } from "./app/shared/services/context/controle-context";
import { ContasAPagarProvider } from "./app/shared/services/context/contas-a-pagar-context";
import { ConfiguracaoDeFiltroProvider } from "./app/shared/services/context/configuracao-de-filtro-context";
import { ScrollDaListaProvider } from "./app/shared/services/context/scroll-da-lista-context";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3C7EF7",
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <GlobalProvider>
      <ControleProvider>
        <PaginacaoProvider>
          <NotificacaoDoAppProvider>
            <ContasAPagarProvider>
              <ConfiguracaoDeFiltroProvider>
                <ScrollDaListaProvider>
                  <PrevisaoCompraProvider>
                    <Routes />
                  </PrevisaoCompraProvider>
                </ScrollDaListaProvider>
              </ConfiguracaoDeFiltroProvider>
            </ContasAPagarProvider>
          </NotificacaoDoAppProvider>
        </PaginacaoProvider>
      </ControleProvider>
    </GlobalProvider>
  </ThemeProvider>,
  document.getElementById("root")
);
