import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  negociacoes: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    rowGap: ".625em",
    fontSize: ".9em",
  },
  negociacao: {
    display: "flex",
    flexDirection: "column",
    gap: ".3125em",
    padding: ".625em",
    borderRadius: ".3em",
  },
  cabecalhoNegociacao: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
