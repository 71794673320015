import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    container: {
        display: 'grid',
        rowGap: 10,
    },
    observacoes: {
        paddingTop: 0,
        textAlign: 'left'
    }
}); 