import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  valorSolicitacao: {
    width: "100%",
    fontWeight: "bold",
  },

  notas: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    rowGap: ".625em",
    fontSize: ".9em",
  },

  nota: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: ".625em",
    paddingRight: "1.25em",
    borderRadius: ".3em",
  },

  conteudoNota: {
    display: "flex",
    flexDirection: "column",
    gap: ".3125em",
  },

  notaNaoPaga: {
    backgroundColor: "#FFA0001A",
    color: "#FF6F00",
  },

  notaPaga: {
    backgroundColor: "#00C8531A",
    color: "#00AA35",
  },
});
