import { ReactElement, useEffect, useState } from "react";
import { AtributoNotificacaoDoApp, BaseClicavel, InfiniteScroll } from "..";
import { criarAlerta, lancarAlertaPadraoParaListaVazia, limparIntervalosExistentes, obterIdPedidoSolicitacaoPendente, toDataBR, tratarMensagemDeErroNaBuscaDeDados, verificarScrollInfinitoAtivo } from "../../functions";
import { useGlobalContext, useNotificacaoDoAppContext, usePaginacaoContext } from "../../hooks";
import { ISolicitacaoSenha, TNotificacaoDoAplicativo } from "../../interfaces"
import { NotificacaoService, SolicitacaoSenhaService } from "../../services";
import { TEMPO_DE_ATUALIZACAO_DE_REGISTROS } from "../../utils";
import { AbasNotificacoes } from "../abas-notificacoes";
import { BtnSecundario } from "../btn-secundario";
import { EmptyState } from "../empty-state";
import { useStyles } from "./styles";

type TNotificacaoDoAppProps = {
    notificacao: TNotificacaoDoAplicativo;
    selecionarNotificacaoDoApp: (notificacao: TNotificacaoDoAplicativo) => void;
}

type TListagemDeNotificacoesProps = {
    selecionarNotificacaoDoApp: (notificacao: TNotificacaoDoAplicativo) => void;
    selecionarNotificacaoSolicitacaoPendente: (solicitacaoPendente: ISolicitacaoSenha) => void;
}

type TPropriedadeNotificacaoDoAppProps = {
    borderBottom?: boolean;
}

type NotificacaoSolicitacaoPendenteProps = {
    solicitacaoPendente: ISolicitacaoSenha;
    selecionarNotificacaoSolicitacaoPendente: (solicitacaoPendente: ISolicitacaoSenha) => void;
}

const PropriedadeNotificacaoDoApp: React.FC<TPropriedadeNotificacaoDoAppProps> = ({ children, borderBottom }) => {

    const classes = useStyles();

    return <span className={`${classes.propriedadeNotificacaoDoApp} ${borderBottom ? classes.propriedadeComBorderBottom : classes.propriedadeComBorderTop}`}>{children}</span>

}

const tratarMensagemNotificacao = (
    { mensagem, nomeUsuarioOrigem, dataHora }: TNotificacaoDoAplicativo,
    maisDetalhes?: boolean
): ReactElement => {

    const mensagemLowerCase = mensagem?.toLowerCase() || '';

    const indiceChamada = mensagemLowerCase.indexOf('chamada:');

    const indiceDataAbertura = mensagemLowerCase.indexOf('aberta em:');

    const indiceSolicitante = mensagemLowerCase.indexOf('solicitada por:');

    return <span>

        {maisDetalhes && <AtributoNotificacaoDoApp>De: {nomeUsuarioOrigem}</AtributoNotificacaoDoApp>}

        <AtributoNotificacaoDoApp>{mensagem.substring(indiceChamada, indiceDataAbertura)}</AtributoNotificacaoDoApp>

        <AtributoNotificacaoDoApp>{mensagem.substring(indiceSolicitante)}</AtributoNotificacaoDoApp>

        {maisDetalhes && <AtributoNotificacaoDoApp>Data: {toDataBR(dataHora, true)}</AtributoNotificacaoDoApp>}

    </span>

}

const NotificacaoDoApp: React.FC<TNotificacaoDoAppProps> = ({ notificacao, selecionarNotificacaoDoApp }) => {

    const classes = useStyles();

    const { verificarPrevDeCompraERetornarMsg } = useNotificacaoDoAppContext();

    const { dataHora, nomeUsuarioOrigem } = notificacao;

    return <BaseClicavel
        className={classes.notificacaoDoApp}
        onClick={() => selecionarNotificacaoDoApp(notificacao)}
    >

        <PropriedadeNotificacaoDoApp borderBottom>

            <strong>De:</strong>

            <p>{nomeUsuarioOrigem}</p>

        </PropriedadeNotificacaoDoApp>

        {verificarPrevDeCompraERetornarMsg(notificacao) || <>{tratarMensagemNotificacao(notificacao)}</>}

        <PropriedadeNotificacaoDoApp>

            <strong>Data:</strong>

            <p>{toDataBR(dataHora, true)}</p>

        </PropriedadeNotificacaoDoApp>

    </BaseClicavel>

}

const NotificacaoSolicitacaoPendente: React.FC<NotificacaoSolicitacaoPendenteProps> = ({
    solicitacaoPendente,
    selecionarNotificacaoSolicitacaoPendente
}) => {

    const classes = useStyles();

    const { usuarioOrigem, usuarioDestino, mensagem, fornecedor, tipoMensagem } = solicitacaoPendente;

    const idPedido = obterIdPedidoSolicitacaoPendente(mensagem);

    return <BaseClicavel
        className={classes.notificacaoDoApp}
        onClick={() => selecionarNotificacaoSolicitacaoPendente(solicitacaoPendente)}
    >

        <PropriedadeNotificacaoDoApp borderBottom>

            <strong>Origem:</strong>

            <p>{usuarioOrigem}</p>

        </PropriedadeNotificacaoDoApp>

        {idPedido && <AtributoNotificacaoDoApp>Pedido: {idPedido}</AtributoNotificacaoDoApp>}

        {fornecedor && <AtributoNotificacaoDoApp>Fornecedor: {fornecedor}</AtributoNotificacaoDoApp>}

        <AtributoNotificacaoDoApp style={{ paddingBottom: '10px' }}>Tipo mensagem: {tipoMensagem}</AtributoNotificacaoDoApp>

        <PropriedadeNotificacaoDoApp>

            <strong>Destino:</strong>

            <p>{usuarioDestino}</p>

        </PropriedadeNotificacaoDoApp>

    </BaseClicavel>

}

const NotificacaoDoAppSelecionada: React.FC = () => {

    const classes = useStyles();

    const { notificacaoDoAppSelecionada, setNotificacaoDoAppSelecionada, verificarPrevDeCompraERetornarMsg } = useNotificacaoDoAppContext();

    if (!notificacaoDoAppSelecionada) return <></>;

    return <section>

        <BtnSecundario onClick={() => setNotificacaoDoAppSelecionada(null)} />

        <div className={`${classes.mensagemNotificacaoSelecionada} fadeIn`}>

            {verificarPrevDeCompraERetornarMsg(notificacaoDoAppSelecionada, true) || tratarMensagemNotificacao(notificacaoDoAppSelecionada, true)}

        </div>

    </section>

}

const SolicitacaoDeSenhaPendenteSelecionada: React.FC = () => {

    const classes = useStyles();

    const { solicitacaoPendenteSelecionada, setSolicitacaoPendenteSelecionada } = useNotificacaoDoAppContext();

    const {
        usuarioOrigem,
        mensagem,
        usuarioDestino,
        tipoMensagem,
        fornecedor,
        valor,
        valorComIPI,
        numOrcPedido,
        sequencial
    } = solicitacaoPendenteSelecionada as ISolicitacaoSenha;

    const idPedido = obterIdPedidoSolicitacaoPendente(mensagem);

    return <section>

        <BtnSecundario onClick={() => setSolicitacaoPendenteSelecionada(null)} />

        <div className={`${classes.mensagemNotificacaoSelecionada} fadeIn`}>

            <AtributoNotificacaoDoApp>Sequencial: {sequencial}</AtributoNotificacaoDoApp>

            <AtributoNotificacaoDoApp>Origem: {usuarioOrigem}</AtributoNotificacaoDoApp>

            {idPedido && <AtributoNotificacaoDoApp>Pedido: {idPedido}</AtributoNotificacaoDoApp>}

            {fornecedor && <AtributoNotificacaoDoApp>Fornecedor: {fornecedor}</AtributoNotificacaoDoApp>}

            {valor && <AtributoNotificacaoDoApp>Valor: {valor}</AtributoNotificacaoDoApp>}

            {valorComIPI && <AtributoNotificacaoDoApp>Valor com IPI: {valorComIPI}</AtributoNotificacaoDoApp>}

            {numOrcPedido ? <AtributoNotificacaoDoApp>NumOrc: {numOrcPedido}</AtributoNotificacaoDoApp> : null}

            <AtributoNotificacaoDoApp>Tipo mensagem: {tipoMensagem}</AtributoNotificacaoDoApp>

            <AtributoNotificacaoDoApp>Destino: {usuarioDestino}</AtributoNotificacaoDoApp>

        </div>

    </section>

}

export const ListagemDeNotificacoes: React.FC<TListagemDeNotificacoesProps> = ({
    selecionarNotificacaoDoApp,
    selecionarNotificacaoSolicitacaoPendente
}) => {

    const classes = useStyles();

    const {
        loading,
        notificacao,
        setNotificacao,
        setLoading
    } = useGlobalContext();

    const {
        valorInicialPaginacao,
        paginacaoNotificacoes,
        paginacaoSolicitacoesPendentes,
        setPaginacaoNotificacoes,
        setPaginacaoSolicitacoesPendentes
    } = usePaginacaoContext();

    const {
        notificacaoDoAppSelecionada,
        abaSelecionada,
        solicitacaoPendenteSelecionada,
    } = useNotificacaoDoAppContext();

    const [notificacoesDoApp, setNotificacoesDoApp] = useState<TNotificacaoDoAplicativo[]>([]);

    const [solicitacoesDeSenhaPendentes, setSolicitacoesDeSenhaPendentes] = useState<ISolicitacaoSenha[]>([]);

    const [intervaloDeBuscaAtivo, setIntervaloDeBuscaAtivo] = useState(false);

    const buscarNotificacoes = async () => {

        setLoading(true);

        try {

            const { dado, totalPaginas } = await NotificacaoService.obterAsync(1);

            setNotificacoesDoApp([...dado]);

            setPaginacaoNotificacoes({ paginaAtual: 1, totalPaginas });

        } catch (erro) {

            setNotificacoesDoApp([]);

            setPaginacaoNotificacoes({ ...valorInicialPaginacao });

            tratarMensagemDeErroNaBuscaDeDados(erro, setNotificacao);

        } finally {

            setLoading(false);

        }

    }

    const buscarSolicitacoesPendentes = async () => {

        setLoading(true);

        try {

            const { dado, totalPaginas } = await SolicitacaoSenhaService.obterSolicitacoesPendentesSolicitadasPeloUsuario(1);

            lancarAlertaPadraoParaListaVazia(dado, notificacao, setNotificacao);

            setSolicitacoesDeSenhaPendentes([...dado]);

            setPaginacaoSolicitacoesPendentes({ paginaAtual: 1, totalPaginas });

        } catch (erro) {

            setSolicitacoesDeSenhaPendentes([]);

            setPaginacaoSolicitacoesPendentes({ ...valorInicialPaginacao });

            tratarMensagemDeErroNaBuscaDeDados(erro, setNotificacao);

        } finally {

            setLoading(false);

        }

    }

    const atualizarPaginaNotificacoes = async () => {

        setLoading(true);

        try {

            const novaPagina = paginacaoNotificacoes.paginaAtual + 1;

            const { dado, totalPaginas } = await NotificacaoService.obterAsync(novaPagina);

            setNotificacoesDoApp([...notificacoesDoApp, ...dado]);

            setPaginacaoNotificacoes({ paginaAtual: novaPagina, totalPaginas });

        } catch (erro) {

            criarAlerta('error', 'Não foi possível atualizar as notificações.', setNotificacao);

        }

        setLoading(false);

    }

    const atualizarPaginaSolicitacoesPendentes = async () => {

        setLoading(true);

        try {

            const novaPagina = paginacaoSolicitacoesPendentes.paginaAtual + 1;

            const { dado, totalPaginas } = await SolicitacaoSenhaService.obterSolicitacoesPendentesSolicitadasPeloUsuario(novaPagina);

            setSolicitacoesDeSenhaPendentes([...solicitacoesDeSenhaPendentes, ...dado]);

            setPaginacaoSolicitacoesPendentes({ paginaAtual: novaPagina, totalPaginas });

        } catch (erro) {

            criarAlerta('error', 'Não foi possível atualizar as solicitações de senha pendentes.', setNotificacao);

        } finally {

            setLoading(false);

        }

    }

    useEffect(() => {

        if (!intervaloDeBuscaAtivo) {

            limparIntervalosExistentes();

            if (abaSelecionada === 'finalizadas') {

                buscarNotificacoes();

                window.setInterval(buscarNotificacoes, TEMPO_DE_ATUALIZACAO_DE_REGISTROS);

            } else if (abaSelecionada === 'pendentes') {

                buscarSolicitacoesPendentes();

                window.setInterval(buscarSolicitacoesPendentes, TEMPO_DE_ATUALIZACAO_DE_REGISTROS);

            }

            setIntervaloDeBuscaAtivo(true);

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [abaSelecionada]);

    if (solicitacaoPendenteSelecionada !== null) return <SolicitacaoDeSenhaPendenteSelecionada />

    if (notificacaoDoAppSelecionada !== null) return <NotificacaoDoAppSelecionada />

    return <section className={`${classes.listagemDeNotificacoes} fadeIn`}>

        <AbasNotificacoes setIntervaloDeBuscaAtivo={setIntervaloDeBuscaAtivo} />

        {
            abaSelecionada === 'finalizadas' ?
                <>

                    {notificacoesDoApp.length === 0 && !loading && <EmptyState
                        mensagem='Não foi encontrada nenhuma notificação finalizada'
                    />}

                    {notificacoesDoApp.map((notificacaoDoApp, k) => <NotificacaoDoApp
                        key={k}
                        notificacao={notificacaoDoApp}
                        selecionarNotificacaoDoApp={selecionarNotificacaoDoApp}
                    />)}

                    {notificacoesDoApp.length > 0 && // já houverem itens
                        !loading && // nao estiver mais carregando
                        paginacaoNotificacoes.paginaAtual < paginacaoNotificacoes.totalPaginas && // ainda existem itens (atingiu o total, nao carrega mais.)
                        (<InfiniteScroll obterMais={atualizarPaginaNotificacoes} />)}

                </>
                :
                <>

                    {solicitacoesDeSenhaPendentes.length === 0 && !loading && <EmptyState
                        mensagem='Não foi encontrada nenhuma notificação pendente'
                    />}

                    {solicitacoesDeSenhaPendentes.map((solicitacaoPendente, k) => <NotificacaoSolicitacaoPendente
                        key={k}
                        solicitacaoPendente={solicitacaoPendente}
                        selecionarNotificacaoSolicitacaoPendente={selecionarNotificacaoSolicitacaoPendente}
                    />)}

                    {verificarScrollInfinitoAtivo(solicitacoesDeSenhaPendentes.length, loading, paginacaoSolicitacoesPendentes.paginaAtual, paginacaoSolicitacoesPendentes.totalPaginas) && (<InfiniteScroll obterMais={atualizarPaginaSolicitacoesPendentes} />)}

                </>
        }


    </section>

}