import { useStyles } from "./styles"

type Props = {
    className?: string;
}

export const EstadoVazioRelatorio: React.FC<Props> = ({ children, className }) => {

    const classes = useStyles();

    return <div className={`${classes.container} ${className || ''}`}>{children}</div>

}