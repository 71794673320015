import { api } from "../api";
import { ApiException } from "../api-exception";

interface IBonificacaoProps {
  numOrc: number;
  codLoja: number;
  data: string;
  nomeCliente: string;
  totalOrc: number;
  desconto: number;
  digitadoPor: string;
  observacoes: string;
}

interface IItemBonificacaoProps {
  item: string;
  codProd: string;
  descricao: string;
  qtdEmbalag: number;
  quantidade: number;
  valorUnitario: number;
  descontoItem: number;
  valorDesconto: number;
  valorTotal: number;
}

export interface IDetalhesSolicitacaoBonificacao {
  bonificacao: IBonificacaoProps | null;
  itens: IItemBonificacaoProps[];
}

const obterDetalhesSolicitacaoBonificacao = async (
  numOrc: number,
  codLoja: number
) => {
  try {
    const { data } = await api.get<IDetalhesSolicitacaoBonificacao>(
      `obter-informacoes-bonificacao`,
      {
        params: { numOrc, codLoja },
      }
    );

    return data;
  } catch (erro) {
    throw new ApiException(
      erro,
      "Não foi possível obter os detalhes da bonificação"
    );
  }
};

export const BonificacaoService = { obterDetalhesSolicitacaoBonificacao };
