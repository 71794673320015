import { useEffect, useState } from "react";

// styles
import "../shared/assets/styles/pagina-main/main.css";
import { useStyledButtons } from "../shared/assets/styles";

// material-ui
import { Button } from "@material-ui/core";

// services
import {
  useControleContext,
  useGlobalContext,
  useNotificacaoDoAppContext,
  usePrevisaoCompra,
} from "../shared/hooks";

// components
import {
  ContainerDaPagina,
  InformacoesPedido,
  ListagemDeNotificacoes,
  ListagemDeSolicitacoes,
  ModalConfirmarRespostaSolicitacao,
} from "../shared/components";

import {
  IRespostaSolicitacao,
  ISolicitacaoSenha,
  TDirecaoPagina,
  TNotificacaoDoAplicativo,
  TSeverityNotificacao,
  TStatusPermissao,
  TTipoResponderSolicitacao,
} from "../shared/interfaces";
import {
  NotificacaoService,
  PrevisaoCompraService,
  SolicitacaoSenhaService,
} from "../shared/services";
import {
  ehAssinaturaDigitalNLar,
  ehContasAPagar,
  ehPrevisaoDeCompraNLar,
  fazParteGrupoOrdemCompras,
  instanciaDeApiException,
  limparIntervalosExistentes,
} from "../shared/functions";
import { criarAlerta, obterParametrosDaURL } from "../shared/functions";
import { ListagemDeOpcoes } from "../shared/components/listagem-de-opcoes";
import { AssinaturaDigitalService } from "../shared/services/api/assinatura-digital/assinatura-digital-service";
import { useContasAPagar } from "../shared/services/context/contas-a-pagar-context";
import { ResumoDeContasAPagarPendenteDeEnvio } from "../shared/components/resumo-de-contas-a-pagar-pendente-de-envio";
import { ConfiguracaoDeFiltro } from "../shared/components/configuracao-de-filtro";
import { toCentavos, toReais } from "../shared/functions/conversao/conversao";
import { ScrollDaLista } from "../shared/functions/scroll-da-lista";
import { AvisoSolicitacaoJaRespondida } from "../shared/components/aviso-solicitacao-ja-respondida";

interface IInformacoesConfirmarRespostaSolicitacao {
  modalAtivo: boolean;
  pedidoSolicitacao: ISolicitacaoSenha;
  statusPermissao: TStatusPermissao;
}

interface IBtnListagemPedidosProps {
  disabled?: boolean;
  direcao: TDirecaoPagina;
  handleChangePagina: (direcao: TDirecaoPagina) => void;
}

export const BtnListagemPedidos: React.FC<IBtnListagemPedidosProps> = ({
  children,
  direcao,
  disabled,
  handleChangePagina,
}) => {
  const classes = useStyledButtons();

  return (
    <Button
      variant="contained"
      className={classes.bttPagination}
      onClick={() => handleChangePagina(direcao)}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export const Main: React.FC = () => {
  const {
    inicio_pedidoSelecionado,
    pedidoSelecionado,
    motivoRespostaSolicitacao,
    paginaAtual,
    valorInicialCabecalhoPrevisaoDeCompraSelecionada,
    setPedidoSelecionado,
    setNotificacao,
    setLoading,
    setSolicitacaoSelecionadaRespondida,
    setPaginaAtual,
    setCabecalhoPrevisaoDeCompraSelecionada,
    setPaginacaoItensPedidoSelecionado,
    setItensPedidoSelecionado,
    setFormaPagamentoPrevisaoCompraDetalhada,
  } = useGlobalContext();

  const {
    setNotificacaoDoAppSelecionada,
    setSolicitacaoPendenteSelecionada,
    setAbaSelecionada,
  } = useNotificacaoDoAppContext();

  const {
    listagemDeOpcoesDeRelatorioAtiva,
    setListagemDeOpcoesDeRelatorioAtiva,
  } = useControleContext();

  const { valorInicialDoLoteDeContasAPagar, setValoresDoLoteDeContasAPagar } =
    useContasAPagar();

  const { setAnexosPrevisao } = usePrevisaoCompra();

  const inicioInformacoesConfirmarRespostaSolicitacao: IInformacoesConfirmarRespostaSolicitacao =
    {
      modalAtivo: false,
      pedidoSolicitacao: { ...inicio_pedidoSelecionado },
      statusPermissao: "negar",
    };

  const [
    informacoesConfirmarRespostaSolicitacao,
    setInformacoesConfirmarRespostaSolicitacao,
  ] = useState<IInformacoesConfirmarRespostaSolicitacao>(
    inicioInformacoesConfirmarRespostaSolicitacao
  );

  const [
    estaExibindoAvisoSolicitacaoJaRespondida,
    setEstaExibindoAvisoSolicitacaoJaRespondida,
  ] = useState(false);

  const [
    tipoMensagemSolicitacaoRespondida,
    setTipoMensagemSolicitacaoRespondida,
  ] = useState("");

  const handleFecharModalAvisoSolicitacaoJaRespondida = () => {
    setEstaExibindoAvisoSolicitacaoJaRespondida(false);
    setTipoMensagemSolicitacaoRespondida("");
  };

  const notificar = async (severity: TSeverityNotificacao, message: string) =>
    await criarAlerta(severity, message, setNotificacao);

  // buscar informações sobre o pedido clicado
  const selecionarSolicitacao = async (
    solicitacao: ISolicitacaoSenha,
    indiceSolicitacao: number
  ) => {
    setLoading(true);

    try {
      setPedidoSelecionado({ ...solicitacao });

      ScrollDaLista.guardarElementoParaFoco(indiceSolicitacao);

      setPaginaAtual("pedido-selecionado");

      limparIntervalosExistentes();

      setListagemDeOpcoesDeRelatorioAtiva(false);

      setSolicitacaoSelecionadaRespondida(solicitacao.statusMensagem > 3);

      await SolicitacaoSenhaService.alterarStatusMensagemEUsuarioOrigemEDestinoPorId(
        solicitacao.sequencial
      );

      if (ehContasAPagar(solicitacao)) {
        const valoresDoLoteDeContasAPagarData =
          await SolicitacaoSenhaService.obterValoresDoLoteDeContasAPagar(
            solicitacao.idLote
          );

        const vencimentosDoLote =
          await SolicitacaoSenhaService.obterVencimentosDoLoteDeContasAPagar(
            solicitacao.idLote
          );

        setValoresDoLoteDeContasAPagar({
          ...valoresDoLoteDeContasAPagarData,
          totalGeral: toReais(
            toCentavos(valoresDoLoteDeContasAPagarData.valorDoc) +
              toCentavos(valoresDoLoteDeContasAPagarData.jurosPago || 0) +
              toCentavos(valoresDoLoteDeContasAPagarData.multa || 0) +
              toCentavos(valoresDoLoteDeContasAPagarData.taxaCartorio || 0) -
              toCentavos(valoresDoLoteDeContasAPagarData.desconto || 0)
          ),
          vencimentos: vencimentosDoLote,
        });
      } else
        setValoresDoLoteDeContasAPagar({ ...valorInicialDoLoteDeContasAPagar });
    } catch (erro) {
      setValoresDoLoteDeContasAPagar({ ...valorInicialDoLoteDeContasAPagar });

      instanciaDeApiException(erro)
        ? notificar("error", erro.message)
        : notificar("error", "Erro ao selecionar solicitacao");
    }

    if (solicitacao.numOrcPedido && fazParteGrupoOrdemCompras(solicitacao)) {
      try {
        const { nLarPrevisaoCompraDTO, nLarPrevisaoCompraItem } =
          await PrevisaoCompraService.obterInformacoesPeloCodigo(
            solicitacao.numOrcPedido,
            1
          );

        setCabecalhoPrevisaoDeCompraSelecionada({ ...nLarPrevisaoCompraDTO });

        setItensPedidoSelecionado([...nLarPrevisaoCompraItem]);

        setPaginacaoItensPedidoSelecionado({
          paginaAtual: 1,
          totalDePaginas: nLarPrevisaoCompraDTO.totalPaginas,
        });
      } catch {
        setCabecalhoPrevisaoDeCompraSelecionada({
          ...valorInicialCabecalhoPrevisaoDeCompraSelecionada,
        });

        setItensPedidoSelecionado([]);

        setPaginacaoItensPedidoSelecionado({
          paginaAtual: 0,
          totalDePaginas: 0,
        });
      }

      if (solicitacao.numOrcPedido && solicitacao.codloja) {
        try {
          const formaPagamentoDetalhada =
            await PrevisaoCompraService.obterFormaDePagamentoDetalhada(
              solicitacao.numOrcPedido,
              solicitacao.codloja
            );

          setFormaPagamentoPrevisaoCompraDetalhada(formaPagamentoDetalhada);
        } catch {
          setFormaPagamentoPrevisaoCompraDetalhada([]);
        }

        try {
          const anexosEncontrados = await PrevisaoCompraService.obterAnexos(
            solicitacao.codloja,
            solicitacao.numPrevisao || solicitacao.numOrcPedido,
            solicitacao.sequencial
          );

          setAnexosPrevisao([...anexosEncontrados]);
        } catch {
          setAnexosPrevisao([]);
        }
      } else {
        setFormaPagamentoPrevisaoCompraDetalhada([]);
        setAnexosPrevisao([]);
      }
    } else {
      setCabecalhoPrevisaoDeCompraSelecionada({
        ...valorInicialCabecalhoPrevisaoDeCompraSelecionada,
      });

      setItensPedidoSelecionado([]);

      setFormaPagamentoPrevisaoCompraDetalhada([]);

      setAnexosPrevisao([]);

      setPaginacaoItensPedidoSelecionado({ paginaAtual: 0, totalDePaginas: 0 });
    }

    setLoading(false);
  };

  const exibirInformacoesPedidoSolicitadoPeloApp = async (
    sequencial: number
  ) => {
    try {
      const solicitacaoBuscada =
        await SolicitacaoSenhaService.obterSolicitacaoSenhaPorId(sequencial);

      selecionarSolicitacao(solicitacaoBuscada, 0);
    } catch {}
  };

  const handleClickRespostaSolicitacao = (
    respostaSolicitacao: IRespostaSolicitacao
  ) => {
    const { pedidoSolicitacao, statusPermissao } = respostaSolicitacao;

    setInformacoesConfirmarRespostaSolicitacao({
      modalAtivo: true,
      pedidoSolicitacao,
      statusPermissao,
    });
  };

  const voltarModalConfirmacaoParaEstadoInicial = async () =>
    setInformacoesConfirmarRespostaSolicitacao({
      ...inicioInformacoesConfirmarRespostaSolicitacao,
    });

  const extrairTipoMensagem = (mensagem: string): string | null => {
    const regex = /#(\w+)/;
    const match = mensagem.match(regex);
    return match ? match[1] : null;
  };

  const handleClickConfirmarRespostaSolicitacao = async () => {
    setLoading(true);

    try {
      const tipoMensagem: TTipoResponderSolicitacao =
        informacoesConfirmarRespostaSolicitacao.statusPermissao === "autorizar"
          ? "CONFIRMACAO"
          : "RECUSADO";

      const {
        sequencial,
        numOrcPedido: NumOrc,
        codLojaPedido: CodLoja,
      } = pedidoSelecionado;

      if (ehAssinaturaDigitalNLar(pedidoSelecionado)) {
        const aSolicitacaoFoiRespondidaPorOutroGerente =
          await AssinaturaDigitalService.verificarSolicitacaoRespondida({
            NumOrc,
            CodLoja,
          });

        if (aSolicitacaoFoiRespondidaPorOutroGerente) {
          setSolicitacaoSelecionadaRespondida(true);

          setLoading(false);

          voltarModalConfirmacaoParaEstadoInicial();

          notificar(
            "info",
            `A solicitação de senha para o orçamento ${NumOrc} já foi respondida por outro gerente`
          );

          return;
        }

        if (tipoMensagem === "CONFIRMACAO")
          await AssinaturaDigitalService.validarContrato({ NumOrc, CodLoja });
        else await AssinaturaDigitalService.negarContrato({ NumOrc, CodLoja });
      }

      if (ehContasAPagar(pedidoSelecionado)) {
        if (tipoMensagem === "CONFIRMACAO")
          await SolicitacaoSenhaService.autorizarLiberacoesBaixaContasAPagar(
            pedidoSelecionado.idLote,
            "S"
          );
        else
          await SolicitacaoSenhaService.autorizarLiberacoesBaixaContasAPagar(
            pedidoSelecionado.idLote,
            "N"
          );
      }

      await SolicitacaoSenhaService.responderSolicitacao({
        sequencial,
        motivo: motivoRespostaSolicitacao || "",
        tipoMensagem,
      });

      sessionStorage.setItem(
        "mensagem-respondeu-solicitacao",
        `Solicitação ${
          tipoMensagem === "CONFIRMACAO" ? "autorizada" : "recusada"
        } com sucesso!`
      );

      window.location.reload();
    } catch (erro) {
      if (instanciaDeApiException(erro)) {
        const mensagem = erro.message?.toUpperCase() || "";

        if (mensagem.includes("A SOLICITAÇÃO JÁ FOI RESPONDIDA!")) {
          setEstaExibindoAvisoSolicitacaoJaRespondida(true);

          const tipoMensagemRespondida = extrairTipoMensagem(mensagem);

          setTipoMensagemSolicitacaoRespondida(tipoMensagemRespondida || "");
        } else {
          notificar("error", erro.message);
        }
      } else {
        notificar("error", "Erro ao responder solicitação");
      }
    }

    setLoading(false);
  };

  const selecionarNotificacaoDoApp = (
    notificacao: TNotificacaoDoAplicativo
  ) => {
    setLoading(true);

    try {
      setNotificacaoDoAppSelecionada({ ...notificacao });

      setPaginaAtual("notificacoes");
    } catch (erro) {
      instanciaDeApiException(erro)
        ? notificar("error", erro.message)
        : notificar("error", "Erro ao tentar abrir a notificação");
    }

    setLoading(false);
  };

  const selecionarNotificacaoSolicitacaoPendente = (
    solicitacaoPendente: ISolicitacaoSenha
  ) => {
    setLoading(true);

    try {
      setSolicitacaoPendenteSelecionada({ ...solicitacaoPendente });

      setAbaSelecionada("pendentes");

      setPaginaAtual("notificacoes");
    } catch (erro) {
      instanciaDeApiException(erro)
        ? notificar("error", erro.message)
        : notificar("error", "Erro ao tentar abrir a notificação pendente");
    }

    setLoading(false);
  };

  const exibirNotificacaoInformadaPelaURL = async (idMensagem: number) => {
    try {
      const notificacaoBuscada = await NotificacaoService.obterPorIdAsync(
        idMensagem
      );

      selecionarNotificacaoDoApp(notificacaoBuscada);
    } catch {}
  };

  const exibirSolicitacaoPendenteSolicitadaPelaURL = async (
    sequencial: number
  ) => {
    try {
      const solicitacaoPendente =
        await SolicitacaoSenhaService.obterSolicitacaoSenhaPorId(sequencial);

      selecionarNotificacaoSolicitacaoPendente(solicitacaoPendente);
    } catch {}
  };

  useEffect(() => {
    const { token, sequencial, tipoDaMensagem } = obterParametrosDaURL();

    if (token && sequencial) {
      if (tipoDaMensagem === "solicitacao") {
        exibirInformacoesPedidoSolicitadoPeloApp(sequencial);
      } else if (tipoDaMensagem === "notificacao") {
        exibirNotificacaoInformadaPelaURL(sequencial);
      } else if (tipoDaMensagem === "solicitacaopendente") {
        exibirSolicitacaoPendenteSolicitadaPelaURL(sequencial);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const mensagemRespondeuSolicitacao = sessionStorage.getItem(
      "mensagem-respondeu-solicitacao"
    );

    if (mensagemRespondeuSolicitacao) {
      notificar("success", mensagemRespondeuSolicitacao);

      sessionStorage.removeItem("mensagem-respondeu-solicitacao");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {informacoesConfirmarRespostaSolicitacao.modalAtivo && (
        <ModalConfirmarRespostaSolicitacao
          sequencial={
            informacoesConfirmarRespostaSolicitacao.pedidoSolicitacao.sequencial
          }
          statusPermissao={
            informacoesConfirmarRespostaSolicitacao.statusPermissao
          }
          voltarModalConfirmacaoParaEstadoInicial={
            voltarModalConfirmacaoParaEstadoInicial
          }
          onClickConfirmar={handleClickConfirmarRespostaSolicitacao}
        />
      )}

      {estaExibindoAvisoSolicitacaoJaRespondida && (
        <AvisoSolicitacaoJaRespondida
          sequencial={pedidoSelecionado.sequencial}
          tipoMensagemSolicitacaoRespondida={tipoMensagemSolicitacaoRespondida}
          onClickVoltar={handleFecharModalAvisoSolicitacaoJaRespondida}
        />
      )}

      <ContainerDaPagina>
        {paginaAtual === "notificacoes" && (
          <ListagemDeNotificacoes
            selecionarNotificacaoDoApp={selecionarNotificacaoDoApp}
            selecionarNotificacaoSolicitacaoPendente={
              selecionarNotificacaoSolicitacaoPendente
            }
          />
        )}

        {paginaAtual === "resumo-contas-a-pagar-pendente-de-envio" && (
          <ResumoDeContasAPagarPendenteDeEnvio />
        )}

        {paginaAtual === "solicitacoes" && (
          <ListagemDeSolicitacoes
            selecionarSolicitacao={selecionarSolicitacao}
          />
        )}

        {paginaAtual === "pedido-selecionado" &&
          !listagemDeOpcoesDeRelatorioAtiva && (
            <InformacoesPedido
              handleClickRespostaSolicitacao={handleClickRespostaSolicitacao}
            />
          )}

        {paginaAtual === "pedido-selecionado" &&
          listagemDeOpcoesDeRelatorioAtiva && <ListagemDeOpcoes />}

        {paginaAtual === "configuracoes" && <ConfiguracaoDeFiltro />}
      </ContainerDaPagina>
    </>
  );
};
