import { Fragment, useEffect, useMemo, useState } from "react";
import {
  criarAlerta,
  limitarEmDuasCasas,
  obterMensagemApiException,
  toDataBR,
  toMoney,
  verificarScrollInfinitoAtivo,
} from "../../functions";
import { useGlobalContext } from "../../hooks";
import { TSeverityNotificacao } from "../../interfaces";
import { TPaginacao } from "../../services";
import {
  Relatorio,
  RelatorioService,
} from "../../services/api/relatorio/relatorio-service";
import { ContainerRelatorio } from "../container-relatorio";
import { EstadoVazioRelatorio } from "../estado-vazio-relatorio";
import { InfiniteScroll } from "../infinite-scroll";
import { ItemRelatorio } from "../item-relatorio";
import { LinhaRelatorio } from "../linha-relatorio";
import { Loading } from "../loading";
import { ParcelaNegociacaoRelatorio } from "../parcela-negociacao-relatorio";
import { SeparadorDeListaRelatorio } from "../separador-de-lista-relatorio";
import { useStyles } from "./styles";

interface IAjusteValor {
  // titulo: string;
  valor: string;
  // ehAcrescimo: boolean;
}

export const RelatorioPedido: React.FC = () => {
  const classes = useStyles();

  const [estaBuscandoDetalhes, setEstaBuscandoDetalhes] = useState(true);

  const [estaAtualizandoPaginaItens, setEstaAtualizandoPaginaItens] =
    useState(false);

  const valorInicialDetalhes: Relatorio = {
    orcamentos: {
      numOrc: 0,
      codLoja: 0,
      codCli: 0,
      nomeCliente: "",
      codFunc: 0,
      nomeFuncionario: "",
      entrega: false,
      totalBruto: 0,
      desconto: 0,
      percentualDesconto: 0,
      totalOrc: 0,
      codCondicaoPgto: 0,
      codStatus: 0,
      codTipoVenda: 0,
      codTipoEntrega: 0,
      data: "",
      descontoItens: 0,
      fechado: "",
      jurosItens: 0,
      numEndereco: 0,
      numEnderecoEntrega: 0,
      numNotaFiscal: null,
      numOrcDevolucao: 0,
      observacoes: "",
      valorBruto: 0,
      tipoOperacao: "",
      documento: null,
      cgC_CPF: null,
      endereco: "",
      cidade: "",
      fone1: "",
      rgie: null,
      bairro: "",
      uf: "",
      descricaoCondPgto: "",
      motivoSolicitacaoSenha: "",
    },
    totalPaginaItens: 0,
    itens: [],
    parcelas: [],
  };

  const [detalhes, setDetalhes] = useState<Relatorio>({
    ...valorInicialDetalhes,
  });

  const [paginacao, setPaginacao] = useState<TPaginacao>({
    paginaAtual: 0,
    totalPaginas: 0,
  });

  const { pedidoSelecionado, setNotificacao } = useGlobalContext();

  const CODLOJAPEDIDO = pedidoSelecionado.codLojaPedido;
  const NUMORCPEDIDO = pedidoSelecionado.numOrcPedido;
  const SEQUENCIALPEDIDO = pedidoSelecionado.sequencial;

  const informacoesAjusteValor = useMemo((): IAjusteValor => {
    // const ehAcrescimo = detalhes.orcamentos.desconto > 0;
    const valorAjuste = toMoney(detalhes.orcamentos.desconto) || "R$ 0,00";
    const percentualAjuste = limitarEmDuasCasas(
      detalhes.orcamentos.percentualDesconto
    );

    return {
      // titulo: ehAcrescimo ? "Acréscimo" : "Desconto",
      valor: `${valorAjuste} (${percentualAjuste}%)`,
      // ehAcrescimo,
    };
  }, [detalhes]);

  const notificar = async (severity: TSeverityNotificacao, message: string) =>
    await criarAlerta(severity, message, setNotificacao);

  const atualizarPaginaItens = async () => {
    setEstaAtualizandoPaginaItens(true);

    try {
      const novaPagina: number = paginacao.paginaAtual + 1;

      const { totalPaginaItens, itens } = await RelatorioService.obterRelatorio(
        CODLOJAPEDIDO,
        NUMORCPEDIDO,
        SEQUENCIALPEDIDO,
        novaPagina
      );

      setDetalhes({
        ...detalhes,
        itens: [...detalhes.itens, ...itens],
        totalPaginaItens,
      });

      setPaginacao({
        paginaAtual: novaPagina,
        totalPaginas: totalPaginaItens || 0,
      });
    } catch (erro) {
      criarAlerta(
        "error",
        "Não foi possível atualizar os itens.",
        setNotificacao
      );
    } finally {
      setEstaAtualizandoPaginaItens(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const r = await RelatorioService.obterRelatorio(
          CODLOJAPEDIDO,
          NUMORCPEDIDO,
          SEQUENCIALPEDIDO,
          paginacao.paginaAtual
        );

        setDetalhes({ ...r });

        setPaginacao({ ...paginacao, totalPaginas: r.totalPaginaItens || 0 });
      } catch (erro) {
        notificar("error", obterMensagemApiException(erro));
      } finally {
        setEstaBuscandoDetalhes(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {estaAtualizandoPaginaItens && <Loading loadingPersonalizado />}

      {estaBuscandoDetalhes ? (
        <Loading loadingPersonalizado />
      ) : (
        <div className={classes.container}>
          <ContainerRelatorio titulo="Valores">
            <LinhaRelatorio
              titulo="Total Bruto"
              valor={toMoney(detalhes.orcamentos.totalBruto)}
              corDoValor="#007303"
            />

            <LinhaRelatorio
              titulo="Desconto"
              valor={informacoesAjusteValor.valor}
              corDoValor="#F03738"
              // titulo={informacoesAjusteValor.titulo}
              // valor={informacoesAjusteValor.valor}
              // corDoValor={
              //   informacoesAjusteValor.ehAcrescimo ? "#303030" : "#F03738"
              // }
            />

            <LinhaRelatorio
              titulo="Total Orçamento"
              valor={toMoney(detalhes.orcamentos.totalOrc)}
              corDoValor="#007303"
            />
          </ContainerRelatorio>

          {detalhes.orcamentos.observacoes && (
            <ContainerRelatorio titulo="Observações">
              <EstadoVazioRelatorio className={classes.observacoes}>
                {detalhes.orcamentos.observacoes}
              </EstadoVazioRelatorio>
            </ContainerRelatorio>
          )}

          <ContainerRelatorio titulo="Cabeçalho">
            <LinhaRelatorio
              titulo="Pedido"
              valor={detalhes.orcamentos.numOrc}
            />

            <LinhaRelatorio
              titulo="Data"
              valor={toDataBR(detalhes.orcamentos.data)}
            />

            <LinhaRelatorio
              titulo="Cliente"
              valor={`${detalhes.orcamentos.codCli} - ${detalhes.orcamentos.nomeCliente}`}
            />

            <LinhaRelatorio
              titulo="CPF/CNPJ"
              valor={detalhes.orcamentos.cgC_CPF || ""}
            />

            <LinhaRelatorio
              titulo="Endereço"
              valor={detalhes.orcamentos.endereco}
            />

            <LinhaRelatorio
              titulo="Cidade/UF"
              valor={`${detalhes.orcamentos.cidade} - ${detalhes.orcamentos.uf}`}
            />

            <LinhaRelatorio
              titulo="Cond. de Pgto"
              valor={detalhes.orcamentos.descricaoCondPgto}
            />

            <LinhaRelatorio titulo="Fone" valor={detalhes.orcamentos.fone1} />

            <LinhaRelatorio
              titulo="R.G./I.E."
              valor={detalhes.orcamentos.rgie || ""}
            />

            <LinhaRelatorio
              titulo="Bairro"
              valor={detalhes.orcamentos.bairro}
            />

            <LinhaRelatorio
              titulo="Vendedor"
              valor={detalhes.orcamentos.nomeFuncionario}
            />
          </ContainerRelatorio>

          {detalhes.orcamentos.motivoSolicitacaoSenha && (
            <ContainerRelatorio titulo="Motivo da solicitação de senha">
              <EstadoVazioRelatorio className={classes.observacoes}>
                {detalhes.orcamentos.motivoSolicitacaoSenha}
              </EstadoVazioRelatorio>
            </ContainerRelatorio>
          )}

          <ContainerRelatorio titulo="Itens">
            {detalhes.itens.length === 0 ? (
              <EstadoVazioRelatorio>
                Não foi encontrado nenhum item neste pedido
              </EstadoVazioRelatorio>
            ) : (
              detalhes.itens.map((item, k) => (
                <Fragment key={k}>
                  <ItemRelatorio data={item} />

                  <SeparadorDeListaRelatorio
                    chave={k}
                    tamanhoDaLista={detalhes.itens.length}
                  />
                </Fragment>
              ))
            )}

            {verificarScrollInfinitoAtivo(
              detalhes.itens.length,
              estaBuscandoDetalhes || estaAtualizandoPaginaItens,
              paginacao.paginaAtual + 1,
              paginacao.totalPaginas
            ) && <InfiniteScroll obterMais={atualizarPaginaItens} />}
          </ContainerRelatorio>

          <ContainerRelatorio titulo="Negociação">
            {detalhes.parcelas?.length === 0 ? (
              <EstadoVazioRelatorio>
                Não foi encontrada nenhuma parcela deste pedido
              </EstadoVazioRelatorio>
            ) : (
              detalhes.parcelas?.map((parcela, k) => (
                <Fragment key={k}>
                  <ParcelaNegociacaoRelatorio data={parcela} />

                  <SeparadorDeListaRelatorio
                    chave={k}
                    tamanhoDaLista={detalhes.parcelas?.length || 0}
                  />
                </Fragment>
              ))
            )}
          </ContainerRelatorio>
        </div>
      )}
    </>
  );
};
