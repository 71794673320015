import { CabecalhoSecao } from "../informacoes-pedido";
import { useStyles } from "./styles";

interface ISecaoProps {
  cabecalho?: string;
  className?: string;
  cabecalhoClassName?: string;
  onClick?(): void;
}

export const Secao: React.FC<ISecaoProps> = ({
  cabecalho,
  className,
  children,
  cabecalhoClassName,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <section
      onClick={onClick}
      className={`${classes.container} ${className || ""}`}
    >
      {cabecalho && (
        <CabecalhoSecao className={cabecalhoClassName}>
          {cabecalho}
        </CabecalhoSecao>
      )}

      {children}
    </section>
  );
};
