import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1em",
  },
  anexoContainer: {
    width: "100%",
    textTransform: "none",
    height: "3em",
    fontWeight: 600,
    fontSize: "0.9em",
    backgroundColor: "#5F7390",
    color: "white",

    "&:hover": {
      backgroundColor: "#435673",
    },

    "& svg": {
      marginLeft: "1em",
    },
  },
});
