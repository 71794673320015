import { Button, makeStyles } from "@material-ui/core";
import { useRef } from "react";
import { animarSaidaModal } from "../../functions";

interface IAvisoSolicitacaoJaRespondidaProps {
  sequencial: number;
  tipoMensagemSolicitacaoRespondida: string;
  onClickVoltar: () => void;
}

const useThisStyles = makeStyles((theme) => ({
  containerModal: {
    position: "fixed",
    zIndex: 4,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down(620)]: {
      padding: "0 1em",
    },
  },

  corpo: {
    backgroundColor: "white",
    borderRadius: "0.3em",
    boxShadow: "0 0 0 101vw rgba(0,0,0,0.5)",
  },

  conteudo: {
    maxWidth: "52ch",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    rowGap: "1.5em",
    padding: "2em 1.5em",

    "& h2": {
      fontSize: "1.25em",
      color: "#464665",
    },

    "& span": {
      fontSize: "1.1em",
      color: "#464665",
      textAlign: "center",
      // marginBottom: "1.5em",
    },

    [theme.breakpoints.down(620)]: {
      // paddingBottom: "0.5em",
    },
  },

  foot: {
    display: "flex",
    alignItems: "center",
    padding: "1.5em",
    paddingTop: "0",

    "& button": {
      flex: 1,
      textTransform: "none",
      height: "3em",
      fontSize: "1em",
    },
  },

  vermelho: { color: "#980001" },

  verde: { color: "#077326" },
}));

export const AvisoSolicitacaoJaRespondida: React.FC<
  IAvisoSolicitacaoJaRespondidaProps
> = ({ sequencial, tipoMensagemSolicitacaoRespondida, onClickVoltar }) => {
  const classes = useThisStyles();

  const refModal = useRef<HTMLElement>(null);

  const handleClickVoltar = async () => {
    await animarSaidaModal(refModal);

    onClickVoltar();
  };

  return (
    <article className={classes.containerModal}>
      <section ref={refModal} className={`${classes.corpo} zoomIn`}>
        <div className={classes.conteudo}>
          <h2>Atenção</h2>

          <span>
            A solicitação <strong>{sequencial}</strong> já foi respondida!
          </span>

          {tipoMensagemSolicitacaoRespondida ? (
            <div>
              <span>Resposta: </span>

              <strong
                className={
                  tipoMensagemSolicitacaoRespondida === "RECUSADO"
                    ? classes.vermelho
                    : classes.verde
                }
              >
                {tipoMensagemSolicitacaoRespondida
                  ?.replace("DO", "DA")
                  .replace("CAO", "DA")}
              </strong>
            </div>
          ) : null}
        </div>

        <div className={classes.foot}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickVoltar}
          >
            Entendi
          </Button>
        </div>
      </section>
    </article>
  );
};
