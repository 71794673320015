import { useEffect, useState } from "react";
import {
  toDataBR,
  toMoney,
  tratarMensagemDeErroNaBuscaDeDados,
} from "../../functions";
import { useGlobalContext } from "../../hooks";
import {
  IContasAPagarPendente,
  ILoteDocumento,
  SolicitacaoSenhaService,
} from "../../services";
import { Atributo } from "../atributo";
import { BtnSecundario } from "../btn-secundario";
import { DocumentoDeContasAPagar } from "../detalhes-contas-a-pagar";
import { Secao } from "../secao";
import { useStyles } from "./styles";

interface IDetalhesContasAPagarPendenteProps {
  data: IContasAPagarPendente;
  onClickVoltar(): void;
}

export const DetalhesContasAPagarPendente: React.FC<
  IDetalhesContasAPagarPendenteProps
> = ({ data, onClickVoltar }) => {
  const classes = useStyles();

  const { setLoading, setNotificacao } = useGlobalContext();

  const [documentos, setDocumentos] = useState<
    Omit<ILoteDocumento, "codLoja">[]
  >([]);

  useEffect(() => {
    async function obterDocumentosComVencimentoDoDiaSelecionado() {
      try {
        setLoading(true);

        const documentosData =
          await SolicitacaoSenhaService.obterDetalhesDeContasAPagarPendente(
            data.vencimento
          );

        setDocumentos([...documentosData]);
      } catch (erro) {
        tratarMensagemDeErroNaBuscaDeDados(erro, setNotificacao);
      } finally {
        setLoading(false);
      }
    }

    obterDocumentosComVencimentoDoDiaSelecionado();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <BtnSecundario onClick={onClickVoltar} />

      <div className={classes.content}>
        <Secao>
          <Atributo nome="Vencimento" valor={toDataBR(data.vencimento)} />

          <Atributo nome="Valor" valor={toMoney(data.totalPendente)} />
        </Secao>

        <Secao cabecalho="Lote de documentos">
          <div className={classes.listaDocumentos}>
            {documentos.map((documento, k) => (
              <DocumentoDeContasAPagar key={k} data={documento} />
            ))}
          </div>
        </Secao>
      </div>
    </div>
  );
};
