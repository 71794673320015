import { makeStyles } from "@material-ui/core";
import { Header, Loading, Navegacao, Notificacao } from "..";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh"
  },

  conteudo: {
    padding: "20px 10px 96.6px 10px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
});

export const ContainerDaPagina: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <main className={classes.container}>
      <Loading />

      <Notificacao />

      <section className={classes.conteudo}>
        <Header />

        {children}
      </section>

      <Navegacao />
    </main>
  );
};
