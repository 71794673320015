import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  itens: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    rowGap: "1em",
    paddingLeft: "1em",
    fontSize: ".9em",
  },

  item: {
    display: "flex",
    flexDirection: "column",
    gap: ".3em",
    padding: ".5em 0",
  },

  itemComBorda: {
    borderTop: "1px solid #3C7EF733",
  },
});
