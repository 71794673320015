import { useMemo } from "react";
import { toDataBR, toMoney } from "../../functions";
import { useGlobalContext } from "../../hooks";
import { IDetalhesInclusaoContasAPagar } from "../../services";
import { Atributo } from "../atributo";
import { Secao } from "../secao";
import { useStyles } from "./styles";

interface IDetalhesInclusaoDeContasAPagarProps {
  data: IDetalhesInclusaoContasAPagar;
}

export function DetalhesInclusaoDeContasAPagar({
  data,
}: IDetalhesInclusaoDeContasAPagarProps) {
  const classes = useStyles();

  const { pedidoSelecionado } = useGlobalContext();

  const valorRestanteCalculado = useMemo(() => {
    const valorSolicitacaoEmString =
      pedidoSelecionado.chave?.replaceAll(".", "").replace(",", ".") || "";

    const valorSolicitacao = Number.parseFloat(valorSolicitacaoEmString);

    return data.cabecalho.valorRestante - valorSolicitacao;
  }, [data.cabecalho.valorRestante, pedidoSelecionado.chave]);

  return (
    <>
      <Secao>
        <Atributo nome="Previsão" valor={data.cabecalho.numPrevisao} />

        <Atributo nome="Fornecedor" valor={data.cabecalho.fornecedor} />

        <Atributo
          nome="Emissão"
          valor={data.cabecalho.emissao ? toDataBR(data.cabecalho.emissao) : ""}
        />

        <Atributo
          nome="Vencimento"
          valor={
            data.cabecalho.vencimento ? toDataBR(data.cabecalho.vencimento) : ""
          }
        />

        <Atributo nome="Total" valor={toMoney(data.cabecalho.valorTotal)} />

        <Atributo nome="Pago" valor={toMoney(data.cabecalho.valorPago)} />

        <Atributo nome="Restante" valor={toMoney(valorRestanteCalculado)} />

        <div className={classes.valorSolicitacao}>
          <Atributo
            nome="Solicitação"
            valor={`R$ ${pedidoSelecionado.chave || "0,00"}`}
            corPersonalizada="#F03738"
          />
        </div>
      </Secao>

      {data.cabecalho.observacao && (
        <Secao cabecalho="Observação">
          <p style={{ paddingBottom: "1em" }}>{data.cabecalho.observacao}</p>
        </Secao>
      )}

      <Secao cabecalho="Notas">
        <div className={classes.notas}>
          {data.notas.map((nota) => {
            return (
              <div
                className={`${classes.nota} ${
                  nota.pago ? classes.notaPaga : classes.notaNaoPaga
                }`}
                key={`${nota.parcela}-${nota.documento}`}
              >
                <div className={classes.conteudoNota}>
                  <Atributo nome="Documento" valor={nota.documento} />

                  <Atributo nome="Parcela" valor={nota.parcela} />

                  <Atributo nome="Valor" valor={toMoney(nota.valorLancado)} />
                </div>

                <strong>{nota.pago ? "Paga" : "Não paga"}</strong>
              </div>
            );
          })}
        </div>
      </Secao>
    </>
  );
}
