import { makeStyles } from "@material-ui/core";
import { colors } from "../../assets/styles";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",

    "& *": {
      lineHeight: "160%",
    },
  },

  apresentacao: {
    textAlign: "justify",
  },

  opcoesDeFiltro: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },

  opcaoDeFiltro: {
    display: "grid",
    gridTemplateColumns: ".1fr .05fr .85fr",
    alignItems: "center",
    gap: "10px",
    background: "white",
    borderRadius: ".3em",
    padding: "10px 2.5px",
    transition: "all .5s",

    "& p": {
      marginLeft: "5px",
    },

    "&.selecionado": {
      background: `${colors.primary}1A`,
    },
  },
});
