import { useStyles } from "./styles";

type Props = {
    titulo: string;
}

export const ContainerRelatorio: React.FC<Props> = ({ titulo, children }) => {

    const classes = useStyles();

    return <div className={classes.container}>

        <section className={classes.titulo}>
            {titulo}
        </section>

        <section className={classes.conteudo}>
            {children}
        </section>

    </div>

}