import { obterParametrosDaURL } from "../../../functions";
import { apiRelatorios } from "../api";
import { ApiException } from "../api-exception";

export type Relatorio = {
  orcamentos: Orcamentos;
  totalPaginaItens: number | null;
  itens: TItemRelatorio[];
  parcelas: TParcelaNegociacaoRelatorio[] | null;
};

type Orcamentos = {
  numOrc: number;
  codLoja: number;
  codCli: number;
  nomeCliente: string;
  codFunc: number;
  nomeFuncionario: string;
  entrega: boolean;
  totalBruto: number; // valor money
  desconto: number; // valor money
  percentualDesconto: number; // percentual
  totalOrc: number; // valor money
  codCondicaoPgto: number;
  codStatus: number;
  codTipoVenda: number;
  codTipoEntrega: number;
  data: string;
  descontoItens: number;
  fechado: string;
  jurosItens: number;
  numEndereco: number;
  numEnderecoEntrega: number;
  numNotaFiscal: string | null;
  numOrcDevolucao: number;
  observacoes: string;
  valorBruto: number;
  tipoOperacao: string;
  documento: string | null;
  cgC_CPF: string | null;
  endereco: string;
  cidade: string;
  fone1: string;
  rgie: string | null;
  bairro: string;
  uf: string;
  descricaoCondPgto: string;
  motivoSolicitacaoSenha: string;
};

export type TItemRelatorio = {
  codProd: string;
  codTabela: string | null;
  descricao: string;
  dataOrigem: Date;
  codProdComposto: string;
  valorBruto: number; // valor money
  percDescProd: number; // valor percentual do desconto da negociação ? REVER ISSO: (pode ser negativo -> acréscimo)
  descontoProdutos: number; // valor money do desconto da negociação
  descontoItem: number; // porcentagem do desconto do item
  valorDesconto: number; // valor money do desconto do item
  valorTotal: number; // valor money
  garantiaComJuros: number;
  quantidade: number;
  complemento1: string | null;
  item: string;
  seqNumLote: string | null;
};

export type TParcelaNegociacaoRelatorio = {
  numOrc: number;
  codLoja: number;
  nrParcela: number;
  vencimento: string;
  codFormaPgto: number;
  descricaoFormaPagamento: string;
  dias: number;
  valorFinal: number; // valor money (líquido)
};

const obterRelatorio = async (
  CodLoja: number,
  NumOrc: number,
  Sequencial: number,
  CurrentPage: number
): Promise<Relatorio> => {
  const { token } = obterParametrosDaURL();

  try {
    const r = await apiRelatorios.get<Relatorio>("/Relatorio", {
      params: {
        CodLoja,
        NumOrc,
        Sequencial,
        CurrentPage,
        Limit: 15,
      },
      headers: { token },
    });

    return r.data;
  } catch (error) {
    throw new ApiException(error, "Erro ao obter relatório");
  }
};

export const RelatorioService = {
  obterRelatorio,
};
