import { toDataBR, toMoney } from "../../functions";
import { IDetalhesSolicitacaoBonificacao } from "../../services/api/bonificacao/bonificacao-service";
import { Atributo } from "../atributo";
import { Secao } from "../secao";
import { useStyles } from "./styles";

interface IDetalhesBonificacaoProps {
  data: IDetalhesSolicitacaoBonificacao;
}

export function DetalhesBonificacao({ data }: IDetalhesBonificacaoProps) {
  const classes = useStyles();

  return (
    <>
      <Secao>
        <Atributo nome="NumOrc" valor={data.bonificacao?.numOrc} />

        <Atributo nome="CodLoja" valor={data.bonificacao?.codLoja} />

        <Atributo nome="Data" valor={toDataBR(data.bonificacao?.data || "")} />

        <Atributo nome="Cliente" valor={data.bonificacao?.nomeCliente} />

        <Atributo nome="Digitado por" valor={data.bonificacao?.digitadoPor} />

        <Atributo
          nome="Total Orc"
          valor={toMoney(data.bonificacao?.totalOrc)}
        />

        <Atributo nome="Desconto" valor={toMoney(data.bonificacao?.desconto)} />
      </Secao>

      <Secao cabecalho="Observações">{data.bonificacao?.observacoes}</Secao>

      <Secao cabecalho="Itens">
        <div className={classes.itens}>
          {data.itens.map((item, k) => {
            return (
              <div
                className={`${classes.item} ${
                  k % 2 !== 0 ? classes.itemComBorda : ""
                }`}
                key={item.item}
              >
                <Atributo nome="Item" valor={item.item} />

                <Atributo nome="Cód Prod" valor={item.codProd} />

                <Atributo nome="Descrição" valor={item.descricao} />

                <Atributo nome="Qtd Embalag" valor={item.qtdEmbalag} />

                <Atributo nome="Quantidade" valor={item.quantidade} />

                <Atributo
                  nome="Valor Unitário"
                  valor={toMoney(item.valorUnitario)}
                />

                <Atributo nome="Valor Total" valor={toMoney(item.valorTotal)} />

                <Atributo
                  nome="Desconto Item"
                  valor={toMoney(item.descontoItem)}
                />

                <Atributo
                  nome="Valor Desconto"
                  valor={toMoney(item.valorDesconto)}
                />
              </div>
            );
          })}
        </div>
      </Secao>
    </>
  );
}
