import { Button, IconButton, makeStyles, TextField } from "@material-ui/core";
import React, { useCallback, useRef } from "react";
import { MdClose } from "react-icons/md"
import { instanciaDeApiException } from "../../functions";
import { animarSaidaModal, criarAlerta } from "../../functions";
import { useGlobalContext } from "../../hooks";
import { TSeverityNotificacao, TStatusPermissao } from "../../interfaces";

interface IModalConfirmarRespostaSolicitacaoProps {
    sequencial: number;
    statusPermissao: TStatusPermissao;
    voltarModalConfirmacaoParaEstadoInicial: () => void;
    onClickConfirmar: () => Promise<void>;
}

const useThisStyles = makeStyles(theme => ({

    containerModal: {
        position: 'fixed',
        zIndex: 4,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.down(620)]: {
            padding: '0 1em'
        }

    },

    corpo: {
        backgroundColor: 'white',
        borderRadius: '0.3em',
        boxShadow: '0 0 0 101vw rgba(0,0,0,0.5)'
    },

    header: {
        padding: '0.3em 1em',
        color: '#777777',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontWeight: 600,
        borderBottom: '1px solid #EEEEEE',

        "& svg": {
            width: '1em',
            height: '1em',
        }

    },

    conteudo: {

        maxWidth: '52ch',
        display: 'grid',
        rowGap: '1em',
        padding: '1em',

        "& span": {
            fontSize: '1.1em',
            color: '#464665',
            textAlign: 'center',
        },


        [theme.breakpoints.down(620)]: {
            paddingBottom: '0.5em'
        }

    },


    botoes: {

        padding: '1em',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        columnGap: '1em',

        "& button": {
            textTransform: 'none',
            height: '3.5em'
        },

        [theme.breakpoints.down(620)]: {
            gridTemplateColumns: '1fr',
            rowGap: '1em',

            "& button:nth-child(2)": {
                gridRow: 1
            }

        },

    },

}));

export const ModalConfirmarRespostaSolicitacao: React.FC<IModalConfirmarRespostaSolicitacaoProps> = ({ sequencial, statusPermissao, voltarModalConfirmacaoParaEstadoInicial, onClickConfirmar }) => {

    const classes = useThisStyles();

    const refModal = useRef<HTMLElement>(null);

    const { motivoRespostaSolicitacao, setMotivoRespostaSolicitacao, setNotificacao } = useGlobalContext();

    const notificar = (severity: TSeverityNotificacao, message: string) => criarAlerta(severity, message, setNotificacao);

    const handleChangeMotivo = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setMotivoRespostaSolicitacao(e.target.value), [setMotivoRespostaSolicitacao]);

    const handleClickCancelar = async () => {

        await animarSaidaModal(refModal);

        setMotivoRespostaSolicitacao('');

        voltarModalConfirmacaoParaEstadoInicial();

    }

    const handleClickConfirmar = async () => {

        try {

            await onClickConfirmar();

            await animarSaidaModal(refModal);

            voltarModalConfirmacaoParaEstadoInicial();

        } catch (erro) {

            instanciaDeApiException(erro) ? notificar("error", erro.message) : notificar("error", "Erro ao autorizar solicitação");

        }

    }

    return <article className={classes.containerModal}>

        <section ref={refModal} className={`${classes.corpo} zoomIn`}>

            <div className={classes.header}>

                <p>Confirmação</p>

                <IconButton
                    onClick={handleClickCancelar}
                >
                    <MdClose />
                </IconButton>

            </div>

            <div className={classes.conteudo}>

                <span>Você tem certeza que deseja {statusPermissao} a solicitação <strong>{sequencial}</strong>?</span>

                <TextField
                    variant="outlined"
                    placeholder="Motivo (opcional)"
                    value={motivoRespostaSolicitacao}
                    onChange={handleChangeMotivo}
                    onKeyDown={(e) => e.key === "Enter" ? handleClickConfirmar() : undefined}
                />

            </div>

            <div className={classes.botoes}>

                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClickCancelar}
                >
                    Cancelar
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickConfirmar}
                >
                    Confirmar
                </Button>

            </div>

        </section>

    </article>

}