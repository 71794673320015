import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 4,
    width: "100%",
    height: "100vh",
    display: "flex",
    backgroundColor: "white",

    "& object": {
      flex: 1,
      border: "none",
    },
  },
  botaoRetorno: {
    position: "absolute",
    left: "2em",
    bottom: "3em",
    width: "4.2em",
    height: "4em",
    padding: 0,
    minWidth: "0",
    borderRadius: "50%",
    background: "#F03738",

    "& svg": {
      height: "1.8em",
      width: "1.8em",
      fill: "#FFFFFF",
    },

    transition: ".5s",

    "&:hover": {
      background: "#980001",
    },
  },
});
