import { createContext, useRef, useState } from "react";
import {
  ICabecalhoPrevisaoCompra,
  IItemPrevisaoCompra,
  INotificacao,
  IPaginacaoItensPedidoSelecionado,
  ISolicitacaoSenha,
  TPaginas,
} from "../../interfaces";
import { IParcelaAPagarPrevisaoCompra } from "..";

interface IGlobalContext {
  inicio_pedidoSelecionado: ISolicitacaoSenha;
  pedidoSelecionado: ISolicitacaoSenha;
  notificacao: INotificacao;
  refNotificacao: React.RefObject<HTMLDivElement>;
  loading: boolean;
  solicitacaoSelecionadaRespondida: boolean;
  motivoRespostaSolicitacao: string;
  itensPedidoSelecionado: IItemPrevisaoCompra[];
  paginacaoItensPedidoSelecionado: IPaginacaoItensPedidoSelecionado;
  valorInicialCabecalhoPrevisaoDeCompraSelecionada: ICabecalhoPrevisaoCompra;
  cabecalhoPrevisaoDeCompraSelecionada: ICabecalhoPrevisaoCompra;
  paginaAtual: TPaginas;
  formaPagamentoPrevisaoCompraDetalhada: IParcelaAPagarPrevisaoCompra[];
  setPedidoSelecionado: React.Dispatch<React.SetStateAction<ISolicitacaoSenha>>;
  setNotificacao: React.Dispatch<React.SetStateAction<INotificacao>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setMotivoRespostaSolicitacao: React.Dispatch<React.SetStateAction<string>>;
  setSolicitacaoSelecionadaRespondida: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setItensPedidoSelecionado: React.Dispatch<
    React.SetStateAction<IItemPrevisaoCompra[]>
  >;
  setPaginacaoItensPedidoSelecionado: React.Dispatch<
    React.SetStateAction<IPaginacaoItensPedidoSelecionado>
  >;
  setCabecalhoPrevisaoDeCompraSelecionada: React.Dispatch<
    React.SetStateAction<ICabecalhoPrevisaoCompra>
  >;
  setPaginaAtual: React.Dispatch<React.SetStateAction<TPaginas>>;
  setFormaPagamentoPrevisaoCompraDetalhada: React.Dispatch<
    React.SetStateAction<IParcelaAPagarPrevisaoCompra[]>
  >;
}

export const GlobalContext = createContext({} as IGlobalContext);

export const GlobalProvider: React.FC = ({ children }) => {
  const inicio_pedidoSelecionado: ISolicitacaoSenha = {
    numOrcPedido: 0,
    numPrevisao: 0,
    fornecedor: "",
    usuarioOrigem: "",
    sequencial: 0,
    codLojaPedido: 0,
    usuarioDestino: "",
    statusMensagem: 0,
    mensagem: "",
    tipoMensagem: "",
    valor: "",
    valorComIPI: "",
    observacao: "",
    codCliente: 0,
    origem: "",
    destino: "",
    totalPaginas: 0,
    motivoResumido: "",
    senhaLiberou: null,
    usuarioLiberou: null,
    dataEnvio: "",
    idLote: "",
    empresa: "",
    codloja: 0,
  };

  const [pedidoSelecionado, setPedidoSelecionado] = useState<ISolicitacaoSenha>(
    inicio_pedidoSelecionado
  );

  const [notificacao, setNotificacao] = useState<INotificacao>({
    severity: "",
    mensagem: "",
    tempoEmTela: 0,
  });

  const refNotificacao = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState<boolean>(true);

  const [motivoRespostaSolicitacao, setMotivoRespostaSolicitacao] =
    useState<string>("");

  const [
    solicitacaoSelecionadaRespondida,
    setSolicitacaoSelecionadaRespondida,
  ] = useState<boolean>(false);

  const valorInicialCabecalhoPrevisaoDeCompraSelecionada: ICabecalhoPrevisaoCompra =
    {
      id: 0,
      codFor: 0,
      fornecedor: "",
      marca: "",
      frete: 0,
      ciffob: 0,
      desconto: 0,
      dataMaximaEntrega: "",
      condicaoPagamento: "",
      formaPagamento: "",
      codTransportadora: 0,
      fechado: false,
      totalPaginas: 0,
      dataVisita: "",
      observacao: "",
      transportadora: "",
      vpc: 0,
    };

  const [
    cabecalhoPrevisaoDeCompraSelecionada,
    setCabecalhoPrevisaoDeCompraSelecionada,
  ] = useState<ICabecalhoPrevisaoCompra>({
    ...valorInicialCabecalhoPrevisaoDeCompraSelecionada,
  });

  const [itensPedidoSelecionado, setItensPedidoSelecionado] = useState<
    IItemPrevisaoCompra[]
  >([]);

  const [paginacaoItensPedidoSelecionado, setPaginacaoItensPedidoSelecionado] =
    useState<IPaginacaoItensPedidoSelecionado>({
      paginaAtual: 0,
      totalDePaginas: 0,
    });

  const [
    formaPagamentoPrevisaoCompraDetalhada,
    setFormaPagamentoPrevisaoCompraDetalhada,
  ] = useState<IParcelaAPagarPrevisaoCompra[]>([]);

  const [paginaAtual, setPaginaAtual] = useState<TPaginas>("solicitacoes");

  return (
    <GlobalContext.Provider
      value={{
        // armazena informações sobre o pedido que o usuário clicar em cima
        inicio_pedidoSelecionado,
        pedidoSelecionado,
        setPedidoSelecionado,

        notificacao,
        setNotificacao,
        refNotificacao,

        loading,
        setLoading,

        motivoRespostaSolicitacao,
        setMotivoRespostaSolicitacao,

        solicitacaoSelecionadaRespondida,
        setSolicitacaoSelecionadaRespondida,

        itensPedidoSelecionado,
        setItensPedidoSelecionado,

        paginacaoItensPedidoSelecionado,
        setPaginacaoItensPedidoSelecionado,

        valorInicialCabecalhoPrevisaoDeCompraSelecionada,
        cabecalhoPrevisaoDeCompraSelecionada,
        setCabecalhoPrevisaoDeCompraSelecionada,

        paginaAtual,
        setPaginaAtual,

        formaPagamentoPrevisaoCompraDetalhada,
        setFormaPagamentoPrevisaoCompraDetalhada
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
