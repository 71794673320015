import { useState } from "react";
import { criarAlerta } from "../../functions";
import { useControleContext, useGlobalContext } from "../../hooks";
import { TSeverityNotificacao } from "../../interfaces";
import { OPCOES_DE_RELATORIOS } from "../../utils";
import { BtnSecundario } from "../btn-secundario"
import { OpcaoDeRelatorio, TTipoDeRelatorio } from "../opcao-de-relatorio";
import { RelatorioPedido } from "../relatorio-pedido";

type TTipoDeRelatorioSelecionado = TTipoDeRelatorio | null;

export const ListagemDeOpcoes: React.FC = () => {

    const [tipoRelatorioSelecionado, setTipoRelatorioSelecionado] = useState<TTipoDeRelatorioSelecionado>(null);

    const { pedidoSelecionado, setNotificacao } = useGlobalContext();

    const { setListagemDeOpcoesDeRelatorioAtiva } = useControleContext();

    const handleVoltarInformacoesDoPedido = () => setListagemDeOpcoesDeRelatorioAtiva(false);

    const handleVoltarRelatorioSelecionado = () => setTipoRelatorioSelecionado(null);

    const notificar = async (severity: TSeverityNotificacao, message: string) => await criarAlerta(severity, message, setNotificacao);

    const handleSelecionarRelatorio = (tipo: TTipoDeRelatorioSelecionado) => {

        if (tipo === "pedido") {

            if (!pedidoSelecionado.numOrcPedido) return notificar("warning", "O pedido selecionado não possui número do orçamento. Não é possível prosseguir");

            if (!pedidoSelecionado.codLojaPedido) return notificar("warning", "O pedido selecionado não possui o código da loja. Não é possível prosseguir");

            setTipoRelatorioSelecionado(tipo);

        }

    }

    return <>

        <BtnSecundario onClick={tipoRelatorioSelecionado === null ? handleVoltarInformacoesDoPedido : handleVoltarRelatorioSelecionado} />

        {
            tipoRelatorioSelecionado === "pedido" ?
                <RelatorioPedido />
                :
                <section className='fadeIn'>
                    {OPCOES_DE_RELATORIOS.map(((opcao, k) => <OpcaoDeRelatorio
                        key={k}
                        data={opcao}
                        onClick={() => handleSelecionarRelatorio(opcao.tipo)}
                    />))}
                </section>
        }

    </>

}