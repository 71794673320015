import { IInformacaoControleDeAcesso } from "../../components";
import { configs } from "../../configs";

export const obterFiltrosUtilizados = (): IInformacaoControleDeAcesso[] => {
  const filtrosUtilizadosLocalStorage = localStorage.getItem(
    configs.LISTA_DE_FILTROS_UTILIZADOS
  );

  if (filtrosUtilizadosLocalStorage)
    return JSON.parse(filtrosUtilizadosLocalStorage);

  return [];
};
