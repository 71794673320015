import { createContext, useState } from 'react';

export type TPaginacao = {
    paginaAtual: number;
    totalPaginas: number;
}

interface IPaginacaoContext {
    valorInicialPaginacao: TPaginacao;
    paginacaoSolicitacoes: TPaginacao;
    paginacaoNotificacoes: TPaginacao;
    paginacaoSolicitacoesPendentes: TPaginacao;
    setPaginacaoSolicitacoes: React.Dispatch<React.SetStateAction<TPaginacao>>;
    setPaginacaoNotificacoes: React.Dispatch<React.SetStateAction<TPaginacao>>;
    setPaginacaoSolicitacoesPendentes: React.Dispatch<React.SetStateAction<TPaginacao>>;
}

export const PaginacaoContext = createContext({} as IPaginacaoContext);

export const PaginacaoProvider: React.FC = ({ children }) => {

    const valorInicialPaginacao: TPaginacao = { paginaAtual: 1, totalPaginas: 0 };

    const [paginacaoSolicitacoes, setPaginacaoSolicitacoes] = useState<TPaginacao>({ ...valorInicialPaginacao });

    const [paginacaoNotificacoes, setPaginacaoNotificacoes] = useState<TPaginacao>({ ...valorInicialPaginacao });

    const [paginacaoSolicitacoesPendentes, setPaginacaoSolicitacoesPendentes] = useState<TPaginacao>({ ...valorInicialPaginacao });

    return (
        <PaginacaoContext.Provider
            value=
            {{
                valorInicialPaginacao,
                paginacaoSolicitacoes, setPaginacaoSolicitacoes,
                paginacaoNotificacoes, setPaginacaoNotificacoes,
                paginacaoSolicitacoesPendentes, setPaginacaoSolicitacoesPendentes
            }}
        >
            {children}
        </PaginacaoContext.Provider >
    )
}