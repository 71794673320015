import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "flex-start",
    gap: "10px",
    color: "#303030",
  },
});
