import { useGlobalContext } from "../../hooks"

type Props = {
    loadingPersonalizado?: boolean;
}

export const Loading: React.FC<Props> = ({ loadingPersonalizado }) => {

    const { loading } = useGlobalContext();

    if (!loading && !loadingPersonalizado) return <></>

    return <div className="containerLoading">

        <div className="loadingio-spinner-eclipse-txzlt67u8ir">
            <div className="ldio-oi3hvdw6g9m">
                <div></div>
            </div>
        </div>

    </div>

}
