import { ReactChild, ReactElement, ReactNode, useMemo, useState } from "react";

// react-icons
import {
  BsFillCaretDownFill,
  BsFillCaretUpFill,
  BsHandbagFill,
} from "react-icons/bs";
import { HiReceiptTax, HiDocumentText } from "react-icons/hi";
import { ImBlocked, ImCheckmark } from "react-icons/im";
import {
  MdMoreHoriz,
  MdOutlineNavigateBefore,
  MdOutlineNavigateNext,
} from "react-icons/md";

// styles
import "../../assets/styles/informacoes-pedido.css";

// material-ui
import { Button, Fab, makeStyles } from "@material-ui/core";
import { IRespostaSolicitacao, TDirecaoPagina } from "../../interfaces";
import {
  useControleContext,
  useGlobalContext,
  usePrevisaoCompra,
} from "../../hooks";
import {
  criarAlerta,
  ehAssinaturaDigitalNLar,
  ehBonificacao,
  ehContasAPagar,
  ehInclusaoContasAPagar,
  ehJuros,
  ehPrevisaoDeCompraNLar,
  ehSolicitacaoFinanceira,
  fazParteGrupoOrdemCompras,
  instanciaDeApiException,
  limparIntervalosExistentes,
  toDataBR,
  toMoney,
} from "../../functions";
import {
  IDetalhesInclusaoContasAPagar,
  IDetalhesJuros,
  IDetalhesSolicitacaoFinanceira,
  ILoteDocumentoAgrupadoPorLoja,
  PrevisaoCompraService,
  SolicitacaoSenhaService,
} from "../../services";
import {
  DetalhesAssinaturaDigital,
  IDetalhesAssinaturaDigital,
} from "../detalhes-assinatura-digital";
import { AssinaturaDigitalService } from "../../services/api/assinatura-digital/assinatura-digital-service";
import { Loading } from "../loading";
import { ExibicaoContrato } from "../exibicao-contrato";
import { DetalhesContasAPagar } from "../detalhes-contas-a-pagar";
import { useContasAPagar } from "../../services/context/contas-a-pagar-context";
import { toCentavos, toReais } from "../../functions/conversao/conversao";
import { Secao } from "../secao";
import { DetalhesSolicitacaoFinanceira } from "../detalhes-solicitacao-financeira";
import { FormaPagamentoDetalhada } from "../forma-pagamento-detalhada";
import {
  BonificacaoService,
  IDetalhesSolicitacaoBonificacao,
} from "../../services/api/bonificacao/bonificacao-service";
import { DetalhesBonificacao } from "../detalhes-bonificacao";
import { Anexos } from "../anexos";
import { ExibicaoAnexo } from "../exibicao-anexo";
import { DetalhesInclusaoDeContasAPagar } from "../detalhes-inclusao-de-contas-a-pagar";
import { DetalhesJuros } from "../detalhes-juros";

interface ILinhaHeaderPedidoProps {
  uppercase?: boolean;
  descricao: string;
  valor: string | number;
}

interface ILinhaResumoPedidoProps {
  icone: ReactNode | ReactChild | ReactElement;
  descricao: string;
  valor: string;
}

interface IInformacoesPedidoProps {
  handleClickRespostaSolicitacao: (
    respostaSolicitacao: IRespostaSolicitacao
  ) => void;
}

interface ICabecalhoSecaoProps {
  className?: string;
}

interface ICardItemPedidoProps {
  descricao: string;
  quantidade?: number;
  totalCusto?: number;
}

const useStyles = makeStyles({
  motivoResumido: {
    padding: "1em 0",
  },

  observacao: {
    paddingTop: "0.5em",
    textAlign: "justify",
  },

  bttCancel: {
    backgroundColor: "#F03738",
    color: "white",
    fontWeight: 600,
    textTransform: "none",
    fontSize: "0.9em",
    width: "100%",
    height: "3em",
    "&:hover": {
      backgroundColor: "#980001",
    },
  },

  bttConfirm: {
    backgroundColor: "#32CE5E",
    color: "white",
    fontWeight: 600,
    fontSize: "0.9em",
    textTransform: "none",
    width: "100%",
    height: "3em",
    "&:hover": {
      backgroundColor: "#077326",
    },
  },

  bttVisualizarContrato: {
    backgroundColor: "#FFA000",
    color: "white",
    fontWeight: 600,
    fontSize: "0.9em",
    textTransform: "none",
    width: "100%",
    height: "3em",
    "&:hover": {
      backgroundColor: "#FF8F00",
    },
  },

  bttMaisDetalhes: {
    width: "100%",
    textTransform: "none",
    height: "3em",
    fontWeight: 600,
    fontSize: "0.9em",

    "& svg": {
      marginLeft: "1em",
    },
  },

  listagemItemPedido: {
    width: "100%",
    marginTop: "0.8em",
    display: "grid",
    rowGap: "0.6em",
  },

  cardItemPedido: {
    display: "grid",
    rowGap: "0.5em",
    borderBottom: "0.8px solid #E3ECFA",
    paddingBottom: "10px",
  },

  tituloItemPedido: {
    color: "#303030",

    "& strong": {
      whiteSpace: "nowrap",
      color: "#303030",
    },
  },

  paginacaoItens: {
    padding: "0.5em 0",
    display: "flex",
    justifyContent: "flex-end",
    columnGap: "1em",
  },

  bttPaginacao: {
    backgroundColor: "#3C7EF7",
    fontFamily: `"Open Sans", sans-serif`,
    fontWeight: 600,
    color: "white",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#3C7EF7",
    },

    "& svg": {
      width: "2em",
      height: "2em",
    },
  },

  conteudoMaisDetalhes: {
    width: "100%",
    display: "grid",
    rowGap: "10px",
    marginBottom: ".15em",
  },

  cabecalhoSecaoMaisDetalhes: {
    marginTop: "1em",
    marginBottom: "0",
  },

  propriedadeCardItemPedido: {
    display: "flex",
    alignItems: "center",
    columnGap: "0.5em",

    "& strong": {
      fontWeight: 600,
    },

    "& p": {
      color: "#007303",
      fontWeight: 400,
    },
  },
});

export const CabecalhoSecao: React.FC<ICabecalhoSecaoProps> = ({
  children,
  className,
}) => (
  <span className={`cabecalho_secaoItensPedido ${className || ""}`}>
    {children}
  </span>
);

const LinhaHeaderPedido: React.FC<ILinhaHeaderPedidoProps> = ({
  descricao,
  valor,
  uppercase,
}) => (
  <div className="linha_headerPedido">
    <span className="desc_LinhaHeaderPedido">{descricao}:</span>

    <span className="valor_LinhaHeaderPedido">
      {uppercase && typeof valor === "string" ? valor.toUpperCase() : valor}
    </span>
  </div>
);

const LinhaResumoPedido: React.FC<ILinhaResumoPedidoProps> = ({
  icone,
  descricao,
  valor,
}) => (
  <div className="linha_resumoPedido">
    <div className="left_LinhaResumoPedido">
      <span className="container_iconeResumoPedido">{icone}</span>

      <span className="desc_linhaResumoPedido">{descricao}</span>
    </div>

    <span className="valor_linhaResumoPedido">{valor}</span>
  </div>
);

export const PropriedadeCardItemPedido: React.FC = ({ children }) => {
  const classes = useStyles();

  return <span className={classes.propriedadeCardItemPedido}>{children}</span>;
};

const CardItemPedido: React.FC<ICardItemPedidoProps> = ({
  quantidade,
  descricao,
  totalCusto,
}) => {
  const classes = useStyles();

  return (
    <span className={classes.cardItemPedido}>
      <p className={classes.tituloItemPedido}>
        <strong>{quantidade || "?"}x — </strong>

        {descricao}
      </p>

      <PropriedadeCardItemPedido>
        <strong>Total Custo:</strong>

        <p>{toMoney(totalCusto)}</p>
      </PropriedadeCardItemPedido>
    </span>
  );
};

export const InformacoesPedido: React.FC<IInformacoesPedidoProps> = ({
  handleClickRespostaSolicitacao,
}) => {
  const classes = useStyles();

  const {
    pedidoSelecionado,
    solicitacaoSelecionadaRespondida,
    cabecalhoPrevisaoDeCompraSelecionada,
    itensPedidoSelecionado,
    paginacaoItensPedidoSelecionado,
    setItensPedidoSelecionado,
    setPaginacaoItensPedidoSelecionado,
    setNotificacao,
  } = useGlobalContext();

  const { setListagemDeOpcoesDeRelatorioAtiva } = useControleContext();

  const { valoresDoLoteDeContasAPagar } = useContasAPagar();

  const { anexosPrevisao } = usePrevisaoCompra();

  const [maisDetalhesAtivo, setMaisDetalhesAtivo] = useState<boolean>(false);

  const [
    estaBuscandoDetalhesAssinaturaDigitalSelecionada,
    setEstaBuscandoDetalhesAssinaturaDigitalSelecionada,
  ] = useState(false);

  const [
    maisDetalhesAssinaturaDigitalSelecionada,
    setMaisDetalhesAssinaturaDigitalSelecionada,
  ] = useState({} as IDetalhesAssinaturaDigital);

  const [estaBuscandoLinkDoContrato, setEstaBuscandoLinkDoContrato] =
    useState(false);

  const [urlContrato, setUrlContrato] = useState<string | null>(null);

  const [
    estaBuscandoDetalhesContasAPagarSelecionada,
    setEstaBuscandoDetalhesContasAPagarSelecionada,
  ] = useState(false);

  const [maisDetalhesContasAPagar, setMaisDetalhesContasAPagar] = useState<
    ILoteDocumentoAgrupadoPorLoja[]
  >([]);

  const valorTotalDoLoteDeContasAPagar: number = useMemo(() => {
    if (!ehContasAPagar(pedidoSelecionado)) return 0;

    return toReais(
      toCentavos(valoresDoLoteDeContasAPagar.valorDoc) +
        toCentavos(valoresDoLoteDeContasAPagar.jurosPago || 0)
    );
  }, [pedidoSelecionado, valoresDoLoteDeContasAPagar]);

  const [
    estaBuscandoDetalhesSolicitacaoFinanceira,
    setEstaBuscandoDetalhesSolicitacaoFinanceira,
  ] = useState(false);

  const [
    maisDetalhesSolicitacaoFinanceira,
    setMaisDetalhesSolicitacaoFinanceira,
  ] = useState<IDetalhesSolicitacaoFinanceira>(
    {} as IDetalhesSolicitacaoFinanceira
  );

  const [estaBuscandoDetalhesBonificacao, setEstaBuscandoDetalhesBonificacao] =
    useState(false);

  const [maisDetalhesBonificacao, setMaisDetalhesBonificacao] =
    useState<IDetalhesSolicitacaoBonificacao>(
      {} as IDetalhesSolicitacaoBonificacao
    );

  const [
    estaBuscandoDetalhesInclusaoContasAPagar,
    setEstaBuscandoDetalhesInclusaoContasAPagar,
  ] = useState(false);

  const [
    maisDetalhesInclusaoContasAPagar,
    setMaisDetalhesInclusaoContasAPagar,
  ] = useState<IDetalhesInclusaoContasAPagar>(
    {} as IDetalhesInclusaoContasAPagar
  );

  const [estaBuscandoDetalhesJuros, setEstaBuscandoDetalhesJuros] =
    useState(false);

  const [maisDetalhesJuros, setMaisDetalhesJuros] = useState<IDetalhesJuros>(
    {} as IDetalhesJuros
  );

  const botaoMaisDetalhesExtensivel = useMemo(
    () =>
      fazParteGrupoOrdemCompras(pedidoSelecionado) ||
      ehAssinaturaDigitalNLar(pedidoSelecionado) ||
      ehContasAPagar(pedidoSelecionado) ||
      ehSolicitacaoFinanceira(pedidoSelecionado) ||
      ehBonificacao(pedidoSelecionado) ||
      ehInclusaoContasAPagar(pedidoSelecionado) ||
      ehJuros(pedidoSelecionado),
    [pedidoSelecionado]
  );

  const handleClickMaisDetalhesPrevisaoDeCompraNLar = () =>
    setMaisDetalhesAtivo(!maisDetalhesAtivo);

  const handleClickMaisDetalhesRelatorio = () => {
    limparIntervalosExistentes();

    setListagemDeOpcoesDeRelatorioAtiva(true);
  };

  const handleClickMaisDetalhesAssinaturaDigitalNLar = async () => {
    if (maisDetalhesAtivo) {
      setMaisDetalhesAssinaturaDigitalSelecionada(
        {} as IDetalhesAssinaturaDigital
      );
      return setMaisDetalhesAtivo(false);
    }

    try {
      setEstaBuscandoDetalhesAssinaturaDigitalSelecionada(true);

      const {
        numOrcPedido,
        codLojaPedido,
        observacao: observacoes,
        motivoResumido,
      } = pedidoSelecionado;

      const data = await AssinaturaDigitalService.obterResumoOrcamento({
        NumOrc: numOrcPedido,
        CodLoja: codLojaPedido,
      });

      const complementosQueEstaoPreenchidos = data.complementos.filter(
        (complemento) => complemento.chave
      );

      setMaisDetalhesAssinaturaDigitalSelecionada({
        ...data,
        numOrc: numOrcPedido,
        observacoes,
        motivoResumido,
        complementos: complementosQueEstaoPreenchidos,
      });

      setEstaBuscandoDetalhesAssinaturaDigitalSelecionada(false);

      setMaisDetalhesAtivo(true);
    } catch (erro) {
      criarAlerta(
        "error",
        instanciaDeApiException(erro)
          ? erro.message
          : `Não conseguimos obter mais detalhes do pedido ${pedidoSelecionado.numOrcPedido}`,
        setNotificacao
      );

      setEstaBuscandoDetalhesAssinaturaDigitalSelecionada(false);
    }
  };

  const handleClickVisualizarContrato = async () => {
    try {
      setEstaBuscandoLinkDoContrato(true);

      const data = await AssinaturaDigitalService.obterURLDoContrato({
        NumOrc: pedidoSelecionado.numOrcPedido,
        CodLoja: pedidoSelecionado.codLojaPedido,
      });

      setUrlContrato(data);

      setEstaBuscandoLinkDoContrato(false);

      setMaisDetalhesAtivo(false);

      setMaisDetalhesAssinaturaDigitalSelecionada(
        {} as IDetalhesAssinaturaDigital
      );
    } catch (erro) {
      criarAlerta(
        "error",
        instanciaDeApiException(erro)
          ? erro.message
          : `Não conseguimos obter o endereço do contrato ${pedidoSelecionado.numOrcPedido}`,
        setNotificacao
      );

      setEstaBuscandoLinkDoContrato(false);
    }
  };

  const handleClickMaisDetalhesContasAPagar = async () => {
    if (maisDetalhesAtivo) {
      setMaisDetalhesContasAPagar([]);
      return setMaisDetalhesAtivo(false);
    }

    try {
      setEstaBuscandoDetalhesContasAPagarSelecionada(true);

      const maisDetalheContasAPagarData =
        await SolicitacaoSenhaService.obterDocumentosDoLoteAgrupadosPorLoja(
          pedidoSelecionado.idLote
        );

      setMaisDetalhesContasAPagar([...maisDetalheContasAPagarData]);

      setEstaBuscandoDetalhesContasAPagarSelecionada(false);

      setMaisDetalhesAtivo(true);
    } catch (erro) {
      criarAlerta(
        "error",
        instanciaDeApiException(erro)
          ? erro.message
          : `Não conseguimos obter mais detalhes do lote`,
        setNotificacao
      );

      setEstaBuscandoDetalhesContasAPagarSelecionada(false);
    }
  };

  const handleClickMaisDetalhesSolicitacaoFinanceira = async () => {
    if (maisDetalhesAtivo) {
      setMaisDetalhesSolicitacaoFinanceira(
        {} as IDetalhesSolicitacaoFinanceira
      );
      return setMaisDetalhesAtivo(false);
    }

    try {
      setEstaBuscandoDetalhesSolicitacaoFinanceira(true);

      const maisDetalhesSolicitacaoFinanceiraData =
        await SolicitacaoSenhaService.obterDetalhesSolicitacaoFinanceira(
          pedidoSelecionado.documento || 0,
          pedidoSelecionado.codigoConta || ""
        );

      setMaisDetalhesSolicitacaoFinanceira({
        ...maisDetalhesSolicitacaoFinanceiraData,
      });

      setEstaBuscandoDetalhesSolicitacaoFinanceira(false);

      setMaisDetalhesAtivo(true);
    } catch (erro) {
      criarAlerta(
        "error",
        instanciaDeApiException(erro)
          ? erro.message
          : "Não conseguimos obter detalhes da solicitação financeira",
        setNotificacao
      );

      setEstaBuscandoDetalhesSolicitacaoFinanceira(false);
    }
  };

  const handleClickMaisDetalhesBonificacao = async () => {
    if (maisDetalhesAtivo) {
      setMaisDetalhesBonificacao({} as IDetalhesSolicitacaoBonificacao);
      return setMaisDetalhesAtivo(false);
    }

    try {
      setEstaBuscandoDetalhesBonificacao(true);

      const maisDetalhesBonificacaoData =
        await BonificacaoService.obterDetalhesSolicitacaoBonificacao(
          pedidoSelecionado.documento || 0,
          pedidoSelecionado.codloja
        );

      setMaisDetalhesBonificacao({ ...maisDetalhesBonificacaoData });

      setEstaBuscandoDetalhesBonificacao(false);

      setMaisDetalhesAtivo(true);
    } catch (erro) {
      criarAlerta(
        "error",
        instanciaDeApiException(erro)
          ? erro.message
          : "Não conseguimos obter detalhes da bonificação",
        setNotificacao
      );

      setEstaBuscandoDetalhesBonificacao(false);
    }
  };

  const handleClickMaisDetalhesInclusaoContasAPagar = async () => {
    if (maisDetalhesAtivo) {
      setMaisDetalhesInclusaoContasAPagar({} as IDetalhesInclusaoContasAPagar);
      return setMaisDetalhesAtivo(false);
    }

    try {
      setEstaBuscandoDetalhesInclusaoContasAPagar(true);

      const documento = pedidoSelecionado.documento?.toString() || "";
      const loja = pedidoSelecionado.codloja;

      const originadoDaConsulta =
        pedidoSelecionado.mensagem.includes("- CONSULTA");

      const obterDetalhes = originadoDaConsulta
        ? SolicitacaoSenhaService.obterDetalhesDaInclusaoDeContasAPagarCONSULTA
        : SolicitacaoSenhaService.obterDetalhesDaInclusaoDeContasAPagar;

      const maisDetalhesInclusaoContasAPagarData = await obterDetalhes(
        documento,
        loja
      );

      setMaisDetalhesInclusaoContasAPagar({
        ...maisDetalhesInclusaoContasAPagarData,
      });

      setEstaBuscandoDetalhesInclusaoContasAPagar(false);

      setMaisDetalhesAtivo(true);
    } catch (erro) {
      criarAlerta(
        "error",
        instanciaDeApiException(erro)
          ? erro.message
          : "Não foi possível obter os detalhes da solicitação de inclusão de contas a pagar",
        setNotificacao
      );

      setEstaBuscandoDetalhesInclusaoContasAPagar(false);
    }
  };

  const handleClickMaisDetalhesJuros = async () => {
    if (maisDetalhesAtivo) {
      setMaisDetalhesJuros({} as IDetalhesJuros);
      return setMaisDetalhesAtivo(false);
    }

    try {
      setEstaBuscandoDetalhesJuros(true);

      const maisDetalheJurosData =
        await SolicitacaoSenhaService.obterDetalhesDeJuros(
          pedidoSelecionado.documento?.toString() || "",
          pedidoSelecionado.codloja
        );

      setMaisDetalhesJuros({ ...maisDetalheJurosData });

      setEstaBuscandoDetalhesJuros(false);

      setMaisDetalhesAtivo(true);
    } catch (erro) {
      criarAlerta(
        "error",
        instanciaDeApiException(erro)
          ? erro.message
          : "Não foi possível obter os detalhes da solicitação de juros tipo gerência",
        setNotificacao
      );

      setEstaBuscandoDetalhesJuros(false);
    }
  };

  const handleClickMaisDetalhes = async () => {
    if (fazParteGrupoOrdemCompras(pedidoSelecionado))
      return handleClickMaisDetalhesPrevisaoDeCompraNLar();

    if (ehAssinaturaDigitalNLar(pedidoSelecionado))
      return handleClickMaisDetalhesAssinaturaDigitalNLar();

    if (ehContasAPagar(pedidoSelecionado))
      return handleClickMaisDetalhesContasAPagar();

    if (ehSolicitacaoFinanceira(pedidoSelecionado))
      return handleClickMaisDetalhesSolicitacaoFinanceira();

    if (ehBonificacao(pedidoSelecionado))
      return handleClickMaisDetalhesBonificacao();

    if (ehInclusaoContasAPagar(pedidoSelecionado))
      return handleClickMaisDetalhesInclusaoContasAPagar();

    if (ehJuros(pedidoSelecionado)) return handleClickMaisDetalhesJuros();

    return handleClickMaisDetalhesRelatorio();
  };

  const handleChangePaginaItens = async (direcao: TDirecaoPagina) => {
    if (!pedidoSelecionado.numOrcPedido) return;

    const { paginaAtual, totalDePaginas } = paginacaoItensPedidoSelecionado;

    if (direcao === "anterior" && paginaAtual === 1) return;

    if (
      direcao === "proxima" &&
      (totalDePaginas === 1 ||
        paginaAtual === totalDePaginas ||
        totalDePaginas === 0)
    )
      return;

    const novaPagina: number =
      direcao === "anterior" ? paginaAtual - 1 : paginaAtual + 1;

    try {
      const { nLarPrevisaoCompraItem } =
        await PrevisaoCompraService.obterInformacoesPeloCodigo(
          pedidoSelecionado.numOrcPedido,
          novaPagina
        );

      setItensPedidoSelecionado([...nLarPrevisaoCompraItem]);

      setPaginacaoItensPedidoSelecionado({
        ...paginacaoItensPedidoSelecionado,
        paginaAtual: novaPagina,
      });
    } catch (erro) {
      criarAlerta(
        "error",
        instanciaDeApiException(erro) ? erro.message : "Erro ao alterar página",
        setNotificacao
      );
    }
  };

  return (
    <>
      {(estaBuscandoDetalhesAssinaturaDigitalSelecionada ||
        estaBuscandoDetalhesContasAPagarSelecionada ||
        estaBuscandoLinkDoContrato) && <Loading loadingPersonalizado />}

      {urlContrato && (
        <ExibicaoContrato url={urlContrato} setUrl={setUrlContrato} />
      )}

      <ExibicaoAnexo />

      <div className="container_InformacoesPedido fadeIn">
        <Secao className="secao_headerPedido">
          <LinhaHeaderPedido
            descricao="Tipo da mensagem"
            valor={pedidoSelecionado.tipoMensagem}
          />

          <LinhaHeaderPedido
            descricao="Sequencial"
            valor={pedidoSelecionado.sequencial}
          />

          {pedidoSelecionado.empresa && (
            <LinhaHeaderPedido
              descricao="Empresa"
              valor={pedidoSelecionado.empresa}
            />
          )}

          {!ehContasAPagar(pedidoSelecionado) &&
          pedidoSelecionado.fornecedor ? (
            <LinhaHeaderPedido
              descricao="Fornecedor"
              valor={pedidoSelecionado.fornecedor}
              uppercase
            />
          ) : null}

          <LinhaHeaderPedido
            descricao="Usuário origem"
            valor={pedidoSelecionado.usuarioOrigem}
            uppercase
          />

          <LinhaHeaderPedido
            descricao="Usuário destino"
            valor={pedidoSelecionado.usuarioDestino}
            uppercase
          />

          {!ehContasAPagar(pedidoSelecionado) &&
          pedidoSelecionado.numOrcPedido ? (
            <LinhaHeaderPedido
              descricao="N° do pedido"
              valor={pedidoSelecionado.numOrcPedido}
            />
          ) : null}

          {!ehContasAPagar(pedidoSelecionado) &&
          pedidoSelecionado.numPrevisao ? (
            <LinhaHeaderPedido
              descricao="N° da previsão"
              valor={pedidoSelecionado.numPrevisao}
            />
          ) : null}

          {fazParteGrupoOrdemCompras(pedidoSelecionado) && (
            <LinhaHeaderPedido
              descricao="Condição Pgto"
              valor={cabecalhoPrevisaoDeCompraSelecionada.condicaoPagamento}
            />
          )}

          {ehContasAPagar(pedidoSelecionado) && (
            <>
              <LinhaHeaderPedido
                descricao="Valor total de documentos"
                valor={toMoney(valoresDoLoteDeContasAPagar.valorDoc)}
              />

              <LinhaHeaderPedido
                descricao="Total juros pago"
                valor={toMoney(valoresDoLoteDeContasAPagar.jurosPago)}
              />

              <LinhaHeaderPedido
                descricao="Valor total do lote"
                valor={toMoney(valorTotalDoLoteDeContasAPagar)}
              />

              <LinhaHeaderPedido
                descricao="Total desconto"
                valor={toMoney(valoresDoLoteDeContasAPagar.desconto)}
              />

              <LinhaHeaderPedido
                descricao="Total multa"
                valor={toMoney(valoresDoLoteDeContasAPagar.multa)}
              />

              <LinhaHeaderPedido
                descricao="Total taxa cartório"
                valor={toMoney(valoresDoLoteDeContasAPagar.taxaCartorio)}
              />

              <LinhaHeaderPedido
                descricao="Total valor restante"
                valor={toMoney(valoresDoLoteDeContasAPagar.valorRestante)}
              />

              <LinhaHeaderPedido
                descricao="Vencimento(s)"
                valor={valoresDoLoteDeContasAPagar.vencimentos.join(", ")}
              />
            </>
          )}
        </Secao>

        {pedidoSelecionado.motivoResumido ? (
          <Secao cabecalho="Motivo resumido">
            <p className={classes.motivoResumido}>
              {pedidoSelecionado.motivoResumido}
            </p>
          </Secao>
        ) : null}

        {pedidoSelecionado.valor || pedidoSelecionado.valorComIPI ? (
          <Secao cabecalho="Resumo do pedido" className="secao_resumoPedido">
            <div className="informacoes_ResumoPedido">
              <LinhaResumoPedido
                icone={<BsHandbagFill size="1.32em" />}
                descricao="Valor total"
                valor={pedidoSelecionado.valor}
              />

              <LinhaResumoPedido
                icone={<HiReceiptTax size="1.32em" />}
                descricao="Valor com IPI"
                valor={pedidoSelecionado.valorComIPI}
              />
            </div>
          </Secao>
        ) : null}

        {pedidoSelecionado.observacao ? (
          <Secao
            cabecalho="Observação"
            cabecalhoClassName={classes.cabecalhoSecaoMaisDetalhes}
          >
            <p className={classes.observacao}>{pedidoSelecionado.observacao}</p>
          </Secao>
        ) : null}

        <Anexos dados={anexosPrevisao} />

        <article className={classes.conteudoMaisDetalhes}>
          <Button
            variant="contained"
            color="primary"
            className={classes.bttMaisDetalhes}
            onClick={handleClickMaisDetalhes}
          >
            {botaoMaisDetalhesExtensivel ? (
              <>
                Mais detalhes{" "}
                {maisDetalhesAtivo ? (
                  <BsFillCaretUpFill />
                ) : (
                  <BsFillCaretDownFill />
                )}
              </>
            ) : (
              <>
                Mais detalhes <MdMoreHoriz size="1.2em" />
              </>
            )}
          </Button>

          {maisDetalhesAtivo &&
            fazParteGrupoOrdemCompras(pedidoSelecionado) && (
              <div className={`${classes.conteudoMaisDetalhes} fadeIn`}>
                <Secao className="secao_headerPedido">
                  <LinhaHeaderPedido
                    descricao="Marca"
                    valor={cabecalhoPrevisaoDeCompraSelecionada.marca}
                  />

                  <LinhaHeaderPedido
                    descricao="Forma Pgto"
                    valor={cabecalhoPrevisaoDeCompraSelecionada.formaPagamento}
                  />

                  {cabecalhoPrevisaoDeCompraSelecionada.transportadora ? (
                    <LinhaHeaderPedido
                      descricao="Transportadora"
                      valor={
                        cabecalhoPrevisaoDeCompraSelecionada.transportadora
                      }
                    />
                  ) : null}

                  <LinhaHeaderPedido
                    descricao="Condição Pgto"
                    valor={
                      cabecalhoPrevisaoDeCompraSelecionada.condicaoPagamento
                    }
                  />

                  <FormaPagamentoDetalhada />

                  <LinhaHeaderPedido
                    descricao="Frete"
                    valor={toMoney(cabecalhoPrevisaoDeCompraSelecionada.frete)}
                  />

                  <LinhaHeaderPedido
                    descricao="Desconto"
                    valor={toMoney(
                      cabecalhoPrevisaoDeCompraSelecionada.desconto
                    )}
                  />

                  {/* {cabecalhoPrevisaoDeCompraSelecionada.dataVisita ? (
                  <LinhaHeaderPedido
                    descricao="Data visita"
                    valor={toDataBR(
                      cabecalhoPrevisaoDeCompraSelecionada.dataVisita
                    )}
                  />
                ) : null} */}

                  <LinhaHeaderPedido
                    descricao="Data máxima entrega"
                    valor={toDataBR(
                      cabecalhoPrevisaoDeCompraSelecionada.dataMaximaEntrega
                    )}
                  />
                </Secao>

                {itensPedidoSelecionado.length > 0 ? (
                  <Secao
                    cabecalho="Itens"
                    cabecalhoClassName={classes.cabecalhoSecaoMaisDetalhes}
                  >
                    <div className={classes.listagemItemPedido}>
                      {itensPedidoSelecionado.map(
                        ({ quantidade, descricao, totalCusto }, k) => {
                          return (
                            <CardItemPedido
                              key={k}
                              quantidade={quantidade}
                              descricao={descricao}
                              totalCusto={totalCusto}
                            />
                          );
                        }
                      )}
                    </div>

                    <div className={classes.paginacaoItens}>
                      <Fab
                        size="medium"
                        color="primary"
                        className={classes.bttPaginacao}
                        onClick={() => handleChangePaginaItens("anterior")}
                        disabled={
                          paginacaoItensPedidoSelecionado.paginaAtual === 1
                        }
                      >
                        <MdOutlineNavigateBefore />
                      </Fab>

                      <Fab
                        size="medium"
                        color="primary"
                        className={classes.bttPaginacao}
                        onClick={() => handleChangePaginaItens("proxima")}
                        disabled={
                          paginacaoItensPedidoSelecionado.totalDePaginas ===
                            1 ||
                          paginacaoItensPedidoSelecionado.paginaAtual ===
                            paginacaoItensPedidoSelecionado.totalDePaginas ||
                          paginacaoItensPedidoSelecionado.totalDePaginas === 0
                        }
                      >
                        <MdOutlineNavigateNext />
                      </Fab>
                    </div>
                  </Secao>
                ) : null}
              </div>
            )}

          {maisDetalhesAtivo &&
            ehAssinaturaDigitalNLar(pedidoSelecionado) &&
            !estaBuscandoDetalhesAssinaturaDigitalSelecionada && (
              <DetalhesAssinaturaDigital
                data={maisDetalhesAssinaturaDigitalSelecionada}
              />
            )}

          {maisDetalhesAtivo &&
            ehContasAPagar(pedidoSelecionado) &&
            !estaBuscandoDetalhesContasAPagarSelecionada && (
              <DetalhesContasAPagar
                data={maisDetalhesContasAPagar}
                totalGeral={valoresDoLoteDeContasAPagar.totalGeral}
              />
            )}

          {maisDetalhesAtivo &&
            ehSolicitacaoFinanceira(pedidoSelecionado) &&
            !estaBuscandoDetalhesSolicitacaoFinanceira && (
              <DetalhesSolicitacaoFinanceira
                data={maisDetalhesSolicitacaoFinanceira}
              />
            )}

          {maisDetalhesAtivo &&
            ehBonificacao(pedidoSelecionado) &&
            !estaBuscandoDetalhesBonificacao && (
              <DetalhesBonificacao data={maisDetalhesBonificacao} />
            )}

          {maisDetalhesAtivo &&
            ehInclusaoContasAPagar(pedidoSelecionado) &&
            !estaBuscandoDetalhesInclusaoContasAPagar && (
              <DetalhesInclusaoDeContasAPagar
                data={maisDetalhesInclusaoContasAPagar}
              />
            )}

          {maisDetalhesAtivo &&
            ehJuros(pedidoSelecionado) &&
            !estaBuscandoDetalhesJuros && (
              <DetalhesJuros data={maisDetalhesJuros} />
            )}
        </article>

        <section className="secao_botoesAcaoPedido">
          {ehAssinaturaDigitalNLar(pedidoSelecionado) && (
            <Button
              variant="contained"
              className={classes.bttVisualizarContrato}
              onClick={handleClickVisualizarContrato}
            >
              Visualizar contrato <HiDocumentText />
            </Button>
          )}

          <Button
            variant="contained"
            className={classes.bttCancel}
            onClick={() =>
              handleClickRespostaSolicitacao({
                pedidoSolicitacao: { ...pedidoSelecionado },
                statusPermissao: "negar",
              })
            }
            disabled={solicitacaoSelecionadaRespondida}
          >
            Negar solicitação <ImBlocked />
          </Button>

          <Button
            variant="contained"
            className={classes.bttConfirm}
            onClick={() =>
              handleClickRespostaSolicitacao({
                pedidoSolicitacao: { ...pedidoSelecionado },
                statusPermissao: "autorizar",
              })
            }
            disabled={solicitacaoSelecionadaRespondida}
          >
            Autorizar solicitação <ImCheckmark />
          </Button>
        </section>
      </div>
    </>
  );
};
