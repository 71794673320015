import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    container: {
        fontFamily: `"Montserrat", sans-serif`,
        fontSize: '1em',
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        color: "#303030",
    },
    valor: {
        fontWeight: 400
    }
});