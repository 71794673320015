import { Fragment } from "react";
import { toMoney } from "../../functions";
import { IResumoOrcamento } from "../../services/api/assinatura-digital/assinatura-digital-service";
import { ContainerRelatorio } from "../container-relatorio";
import { EstadoVazioRelatorio } from "../estado-vazio-relatorio";
import { ItemAssinaturaDigital } from "../item-assinatura-digital";
import { LinhaRelatorio } from "../linha-relatorio";
import { ParcelaNegociacaoAssinaturaDigital } from "../parcela-negociacao-assinatura-digital";
import { SeparadorDeListaRelatorio } from "../separador-de-lista-relatorio";
import { useStyles } from "./styles";

export interface IDetalhesAssinaturaDigital extends IResumoOrcamento {
  numOrc: number;
  observacoes: string;
  motivoResumido: string;
}

interface IDetalhesAssinaturaDigitalProps {
  data: IDetalhesAssinaturaDigital;
}

export function DetalhesAssinaturaDigital({
  data,
}: IDetalhesAssinaturaDigitalProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ContainerRelatorio titulo="Valores">
        <LinhaRelatorio
          titulo="Total Bruto"
          valor={toMoney(data.totalBruto) || "R$ 0,00"}
          corDoValor="#007303"
        />

        <LinhaRelatorio
          titulo="Desconto"
          valor={toMoney(data.desconto) || "R$ 0,00"}
          corDoValor="#F03738"
        />

        <LinhaRelatorio
          titulo="Total Líquido"
          valor={toMoney(data.totalLiquido) || "R$ 0,00"}
          corDoValor="#007303"
        />
      </ContainerRelatorio>

      {data.observacoes && (
        <ContainerRelatorio titulo="Observações">
          <EstadoVazioRelatorio className={classes.observacoes}>
            {data.observacoes}
          </EstadoVazioRelatorio>
        </ContainerRelatorio>
      )}

      <ContainerRelatorio titulo="Cabeçalho">
        <LinhaRelatorio titulo="Pedido" valor={data.numOrc} />

        <LinhaRelatorio titulo="Data" valor={data.data.substring(0, 10)} />

        <LinhaRelatorio titulo="Cliente" valor={data.cliente} />

        <LinhaRelatorio titulo="CPF/CNPJ" valor={data.cpfcnpj || ""} />

        <LinhaRelatorio titulo="Endereço" valor={data.endereco} />

        <LinhaRelatorio titulo="Cidade/UF" valor={data.cidadeUF} />

        <LinhaRelatorio titulo="Cond. de Pgto" valor={data.condicaoPagamento} />

        <LinhaRelatorio titulo="Fone" valor={data.fone} />

        <LinhaRelatorio titulo="R.G./I.E." valor={data.rG_IE || ""} />

        <LinhaRelatorio titulo="Bairro" valor={data.bairro} />

        <LinhaRelatorio titulo="Vendedor" valor={data.vendedor} />

        <LinhaRelatorio titulo="Tipo da Entrega" valor={data.tipoEntrega} />

        <LinhaRelatorio titulo="Num Endereço" valor={data.numEndereco || ""} />
      </ContainerRelatorio>

      {data.motivoResumido && (
        <ContainerRelatorio titulo="Motivo da solicitação de senha">
          <EstadoVazioRelatorio className={classes.observacoes}>
            {data.motivoResumido}
          </EstadoVazioRelatorio>
        </ContainerRelatorio>
      )}

      <ContainerRelatorio titulo="Itens">
        {data.itens.length === 0 ? (
          <EstadoVazioRelatorio>
            Não foi encontrado nenhum item neste pedido
          </EstadoVazioRelatorio>
        ) : (
          data.itens.map((item, k) => (
            <Fragment key={k}>
              <ItemAssinaturaDigital data={item} />

              <SeparadorDeListaRelatorio
                chave={k}
                tamanhoDaLista={data.itens.length}
              />
            </Fragment>
          ))
        )}
      </ContainerRelatorio>

      <ContainerRelatorio titulo="Negociação">
        {data.negociacao?.length === 0 ? (
          <EstadoVazioRelatorio>
            Não foi encontrada nenhuma parcela deste pedido
          </EstadoVazioRelatorio>
        ) : (
          data.negociacao?.map((parcela, k) => (
            <Fragment key={k}>
              <ParcelaNegociacaoAssinaturaDigital data={parcela} />

              <SeparadorDeListaRelatorio
                chave={k}
                tamanhoDaLista={data.negociacao?.length || 0}
              />
            </Fragment>
          ))
        )}
      </ContainerRelatorio>

      <ContainerRelatorio titulo="Complementos">
        {data.complementos.length > 0 ? (
          data.complementos.map((complemento, k) => (
            <LinhaRelatorio
              key={k}
              titulo={complemento.chave}
              valor={complemento.valor}
            />
          ))
        ) : (
          <EstadoVazioRelatorio>
            Não foi encontrado nenhum complemento para este pedido
          </EstadoVazioRelatorio>
        )}
      </ContainerRelatorio>
    </div>
  );
}
