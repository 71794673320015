import { Checkbox } from "@material-ui/core";
import { useState } from "react";
import { BsFillHeptagonFill } from "react-icons/bs";
import { configs } from "../../configs";
import { obterCamelCase } from "../../functions";
import { useConfiguracaoDeFiltroContext } from "../../hooks";
import { IInformacaoControleDeAcesso } from "../listagem-de-solicitacoes";
import { useStyles } from "./styles";
import _ from "lodash";
import { obterFiltrosUtilizados } from "../../functions/dados/dados";

interface IOpcaoDeFiltroProps {
  data: IInformacaoControleDeAcesso;
  selecionado: boolean;
  onSelecionarFiltro(filtro: IInformacaoControleDeAcesso): void;
}

const OpcaoDeFiltro = ({
  data,
  selecionado,
  onSelecionarFiltro,
}: IOpcaoDeFiltroProps) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.opcaoDeFiltro} ${selecionado ? "selecionado" : ""}`}
      onClick={() => onSelecionarFiltro(data)}
    >
      <Checkbox color="primary" checked={selecionado} />

      <BsFillHeptagonFill size=".7em" color={data.cor} />

      <p>{obterCamelCase(data.titulo)}</p>
    </div>
  );
};

export const ConfiguracaoDeFiltro = () => {
  const classes = useStyles();

  const { opcoesDeFiltro, setFiltrosDeSolicitacaoDeSenhaAtivos } =
    useConfiguracaoDeFiltroContext();

  const [filtrosUtilizados, setFiltrosUtilizados] = useState<
    IInformacaoControleDeAcesso[]
  >(obterFiltrosUtilizados());

  function handleSelecionarFiltro(filtro: IInformacaoControleDeAcesso) {
    let listaDeFiltrosUtilizados: IInformacaoControleDeAcesso[] =
      obterFiltrosUtilizados();

    if (listaDeFiltrosUtilizados.find((f) => f.titulo === filtro.titulo))
      listaDeFiltrosUtilizados = [
        ...listaDeFiltrosUtilizados.filter((f) => f.titulo !== filtro.titulo),
      ];
    else listaDeFiltrosUtilizados = [...listaDeFiltrosUtilizados, filtro];

    listaDeFiltrosUtilizados = _.orderBy(
      listaDeFiltrosUtilizados,
      ["titulo"],
      "asc"
    );

    localStorage.setItem(
      configs.LISTA_DE_FILTROS_UTILIZADOS,
      JSON.stringify(listaDeFiltrosUtilizados)
    );

    setFiltrosUtilizados([...listaDeFiltrosUtilizados]);

    setFiltrosDeSolicitacaoDeSenhaAtivos([]);
  }

  return (
    <div className={classes.container}>
      <p className={classes.apresentacao}>
        Selecione quais filtros você deseja que estejam disponíveis nas opções
        de filtro da listagem de solicitações de senha
      </p>

      <div className={classes.opcoesDeFiltro}>
        {opcoesDeFiltro.map((opcaoDeFiltro, k) => (
          <OpcaoDeFiltro
            key={k}
            data={opcaoDeFiltro}
            selecionado={Boolean(
              filtrosUtilizados.find((f) => f.titulo === opcaoDeFiltro.titulo)
            )}
            onSelecionarFiltro={handleSelecionarFiltro}
          />
        ))}
      </div>
    </div>
  );
};
