import { useContext } from "react";
import {
  GlobalContext,
  NotificacaoDoAppContext,
  PaginacaoContext,
  PrevisaoCompraContext,
} from "../services";
import { ConfiguracaoDeFiltroContext } from "../services/context/configuracao-de-filtro-context";
import { ControleContext } from "../services/context/controle-context";
import { ScrollDaListaContext } from "../services/context/scroll-da-lista-context";

export const useGlobalContext = () => useContext(GlobalContext);

export const usePaginacaoContext = () => useContext(PaginacaoContext);

export const useNotificacaoDoAppContext = () =>
  useContext(NotificacaoDoAppContext);

export const useControleContext = () => useContext(ControleContext);

export const useConfiguracaoDeFiltroContext = () =>
  useContext(ConfiguracaoDeFiltroContext);

export const useScrollDaLista = () => useContext(ScrollDaListaContext);

export const usePrevisaoCompra = () => useContext(PrevisaoCompraContext);
