import { useMemo } from "react";
import { toDataBR, toMoney } from "../../functions";
import { useGlobalContext } from "../../hooks";
import { Atributo } from "../atributo";
import { useStyles } from "./styles";

export function FormaPagamentoDetalhada() {
  const classes = useStyles();

  const { pedidoSelecionado, formaPagamentoPrevisaoCompraDetalhada } =
    useGlobalContext();

  const mensagem: string = useMemo(() => {
    if (!pedidoSelecionado.numOrcPedido)
      return `Não foi possível listar os detalhes da forma de pagamento da previsão pois "NumOrcPedido" é nulo.`;

    if (!pedidoSelecionado.codloja)
      return `Não foi possível listar os detalhes da forma de pagamento da previsão pois "CodLoja" é nula.`;

    if (formaPagamentoPrevisaoCompraDetalhada.length === 0)
      return "Não foi encontrada nenhuma parcela para esta previsão no sistema.";

    return "";
  }, [pedidoSelecionado, formaPagamentoPrevisaoCompraDetalhada]);

  if (mensagem) return <span className={classes.mensagem}>{mensagem}</span>;

  return (
    <ul className={classes.container}>
      {formaPagamentoPrevisaoCompraDetalhada.map((parcela) => (
        <li key={parcela.np}>
          <Atributo nome="NP" valor={parcela.np} />

          <Atributo nome="Vencimento" valor={toDataBR(parcela.vencimento)} />

          <Atributo nome="Valor Doc" valor={toMoney(parcela.valorDoc)} />
        </li>
      ))}
    </ul>
  );
}
