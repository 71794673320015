import { useMemo } from "react";
import { naoNuloOuUndefined, toDataBR, toMoney } from "../../functions";
import { IDetalhesSolicitacaoFinanceira } from "../../services";
import { Atributo } from "../atributo";
import { Secao } from "../secao";
import _ from "lodash";
import { useStyles } from "./styles";

interface IDetalhesSolicitacaoFinanceiraProps {
  data: IDetalhesSolicitacaoFinanceira;
}

interface ICampoQuePodeSerAtualizado {
  label: string;
  chave: string;
  modificado: boolean;
  to?: (valor: string) => string;
}

const CAMPOS_QUE_PODEM_SOFRER_ATUALIZACOES: ICampoQuePodeSerAtualizado[] = [
  { label: "Conta", chave: "codigoConta", modificado: false },
  { label: "Valor total", chave: "valorTotal", to: toMoney, modificado: false },
  { label: "Emissão", chave: "emissao", to: toDataBR, modificado: false },
  { label: "Vencimento", chave: "vencimento", to: toDataBR, modificado: false },
  { label: "Pagamento", chave: "pagamento", to: toDataBR, modificado: false },
  { label: "Histórico", chave: "historico", modificado: false },
];

function MapCampoNaoModificado(
  campo: ICampoQuePodeSerAtualizado,
  valor: string
) {
  return (
    <Atributo nome={campo.label} valor={campo.to ? campo.to(valor) : valor} />
  );
}

function MapCampoModificado(
  campo: ICampoQuePodeSerAtualizado,
  valor: string,
  valorAnt: string
) {
  const classes = useStyles();

  return (
    <Secao
      cabecalho={campo.label}
      className={classes.container}
      cabecalhoClassName={classes.cabecalhoContainer}
    >
      <div className={`${classes.atributoModificado} ${classes.atributoAntes}`}>
        <Atributo
          nome="Anterior"
          valor={campo.to ? campo.to(valorAnt) : valorAnt}
        />
      </div>

      <div
        className={`${classes.atributoModificado} ${classes.atributoDepois}`}
      >
        <Atributo
          nome="Solicitado"
          valor={campo.to ? campo.to(valor) : valor}
        />
      </div>
    </Secao>
  );
}

export function DetalhesSolicitacaoFinanceira({
  data,
}: IDetalhesSolicitacaoFinanceiraProps) {
  const camposQuePodemSofrerAtualizacoes: ICampoQuePodeSerAtualizado[] =
    useMemo(
      () =>
        CAMPOS_QUE_PODEM_SOFRER_ATUALIZACOES.map((informacoesDoCampo) => {
          if (naoNuloOuUndefined(data[informacoesDoCampo.chave + "Ant"]))
            informacoesDoCampo.modificado = true;

          return informacoesDoCampo;
        }),
      [data]
    );

  const camposSemAlteracao = useMemo(
    () => camposQuePodemSofrerAtualizacoes.filter((campo) => !campo.modificado),
    [camposQuePodemSofrerAtualizacoes]
  );

  const camposComAlteracao = useMemo(
    () => camposQuePodemSofrerAtualizacoes.filter((campo) => campo.modificado),
    [camposQuePodemSofrerAtualizacoes]
  );

  return (
    <>
      <Secao>
        <Atributo nome="Id Caixa" valor={data.idcaixa} />

        <Atributo nome="N° do lote" valor={data.numeroLote} />

        {data.docFina && !data.docFina.includes("LM") && (
          <Atributo nome="Documento" valor={data.docFina} />
        )}

        {camposSemAlteracao.map((campo) =>
          MapCampoNaoModificado(campo, data[campo.chave]?.toString() || "")
        )}

        <Atributo nome="Complemento" valor={data.complemento} />
      </Secao>

      {camposComAlteracao.map((campo) =>
        MapCampoModificado(
          campo,
          data[campo.chave]?.toString() || "",
          data[campo.chave + "Ant"]?.toString() || ""
        )
      )}
    </>
  );
}
