import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    padding: "0.6em",
    backgroundColor: "white",
    width: "100%",
    borderRadius: "0.3em",
    display: "flex",
    flexDirection: "column",
    gap: ".5em",
  },
});
