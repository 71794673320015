import { useStyles } from "./styles";

type Props = {
  titulo: string;
  valor: string | number;
  semNegrito?: boolean;
  corDoValor?: string;
};

export const LinhaRelatorio: React.FC<Props> = ({
  titulo,
  valor,
  corDoValor,
  semNegrito,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <span style={{ fontWeight: semNegrito ? 400 : 600 }}>{titulo}:</span>

      <span
        className={classes.valor}
        style={{ color: corDoValor || "#303030" }}
      >
        {valor}
      </span>
    </div>
  );
};
