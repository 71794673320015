import { configs } from "../../configs";
import { ISolicitacaoSenha } from "../../interfaces";

function guardarElementoParaFoco(indiceElemento: number) {
  sessionStorage.setItem(configs.ELEMENTO_PARA_FOCO, indiceElemento.toString());
}

function existeElementoAguardandoFoco() {
  const elementoParaFocoGuardado = sessionStorage.getItem(
    configs.ELEMENTO_PARA_FOCO
  );

  if (!elementoParaFocoGuardado) return false;

  return true;
}

function obterElementoParaFoco() {
  const elementoParaFocoGuardado = sessionStorage.getItem(
    configs.ELEMENTO_PARA_FOCO
  );

  if (!elementoParaFocoGuardado) return 0;

  return Number.parseInt(elementoParaFocoGuardado, 10);
}

function obterIndiceDaNavegacaoDoFoco(lista: ISolicitacaoSenha[]) {
  const indiceElementoParaFoco = obterElementoParaFoco();

  const elementoNaLista = lista[indiceElementoParaFoco];

  if (elementoNaLista) return indiceElementoParaFoco;

  if (indiceElementoParaFoco > 0) return indiceElementoParaFoco - 1;

  return 0;
}

function navegarParaReferenciaDoFoco(ref: React.RefObject<HTMLButtonElement>) {
  removerElementoDoFoco();

  window.scrollTo({
    top: ref.current?.offsetTop,
    behavior: "smooth",
  });
}

function removerElementoDoFoco() {
  sessionStorage.removeItem(configs.ELEMENTO_PARA_FOCO);
}

export const ScrollDaLista = {
  guardarElementoParaFoco,
  existeElementoAguardandoFoco,
  obterIndiceDaNavegacaoDoFoco,
  navegarParaReferenciaDoFoco,
  removerElementoDoFoco,
};
