import { Button } from "@material-ui/core"
import { useNotificacaoDoAppContext } from "../../hooks";
import { TAbaNotificacao } from "../../interfaces"
import { useStyles, cores } from "./styles"

type Props = {
    aba: TAbaNotificacao;
    setIntervaloDeBuscaAtivo: React.Dispatch<React.SetStateAction<boolean>>;
}

type BolinhaDecorativaProps = {
    cor: string;
}

type Propriedades = {
    classeSelecionada: string;
    corBolinhaDecorativa: string;
}

const BolinhaDecorativa: React.FC<BolinhaDecorativaProps> = ({ cor }) => {

    const classes = useStyles();

    return <span
        className={classes.bolinhaMaior}
        style={{ background: `${cor}33` }}
    >

        <span
            className={classes.bolinhaMenor}
            style={{ background: `${cor}B3` }}
        />

    </span>

}

export const AbaNotificacao: React.FC<Props> = ({ aba, setIntervaloDeBuscaAtivo }) => {

    const classes = useStyles();

    const { abaSelecionada, setAbaSelecionada } = useNotificacaoDoAppContext();

    const { classeSelecionada, corBolinhaDecorativa }: Propriedades = aba === 'finalizadas' ?
        {
            classeSelecionada: classes.abaFinalizadas,
            corBolinhaDecorativa: cores.finalizadas.decoracoes
        }
        :
        {
            classeSelecionada: classes.abaPendentes,
            corBolinhaDecorativa: cores.pendentes.textoEDecoracoes
        };

    const estaSelecionada = aba === abaSelecionada;

    function handleClick() {

        setAbaSelecionada(aba);

        setIntervaloDeBuscaAtivo(false);

    }

    return <Button
        className={`${classes.container} ${estaSelecionada ? classeSelecionada : classes.abaNaoSelecionada}`}
        onClick={handleClick}
    >
        <p>{aba[0].toUpperCase() + aba.substring(1)}</p> {estaSelecionada && <BolinhaDecorativa cor={corBolinhaDecorativa} />}
    </Button>

}