import { makeStyles } from "@material-ui/core";
import { colors } from "../../assets/styles";

export const useStyles = makeStyles({
  loteDeDocumento: {
    display: "grid",
    rowGap: "0.5em",
    borderBottom: "0.8px solid #E3ECFA",
    padding: "10px 0",

    "& span": {
      display: "flex",
      alignItems: "flex-start",
      gap: "10px",
      color: "#303030",
    },

    "& h3": {
      fontSize: "1.1em",
      display: "flex",
      gap: "15px",
      paddingLeft: "5px",
      marginBottom: "10px",
    },
  },

  listaDocumentos: {
    "& div:nth-child(odd)": {
      background: `#CCCCCC33`,
      borderRadius: ".3em",
    },
  },

  documento: {
    display: "flex",
    flexDirection: "column",
    gap: ".5em",
    padding: ".8em",
  },

  totalLoja: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    padding: ".6em",
    background: `${colors.primary}26`,
    borderRadius: ".3em",
  },
  
  totalGeral: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    padding: ".6em",
    background: `#32CE5E26`,
    borderRadius: ".3em",
  },
});
