import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    container: {
        display: 'grid',
        gap: 10,
        color: "#303030"
    },
    titulo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    }
});