import { useGlobalContext } from "../../hooks";
import icone_notificacao from "../../assets/images/empty-state-notificacao.svg";
import icone_solicitacao from "../../assets/images/empty-state-solicitacao-de-senha.svg";
import { useMemo } from "react";
import { makeStyles } from "@material-ui/core";

type TInformacoes = {
  imagem: string;
  mensagem: string;
};

type Props = {
  mensagem?: string;
  className?: string;
};

const useStyles = makeStyles({
  emptyState: {
    marginTop: "20vh",
    display: "grid",
    placeItems: "center",
    rowGap: "30px",
    padding: "0 10px",
    fontSize: "1.1em",
    textAlign: "center",
    color: "#989FAD",
  },
});

export const EmptyState: React.FC<Props> = ({ className, mensagem }) => {
  const classes = useStyles();

  const { paginaAtual } = useGlobalContext();

  const informacoes: TInformacoes = useMemo(() => {
    if (paginaAtual === "notificacoes") {
      return {
        imagem: icone_notificacao,
        mensagem: mensagem || "Não foi encontrada nenhuma notificação",
      };
    } else if (paginaAtual === "resumo-contas-a-pagar-pendente-de-envio") {
      return {
        imagem: icone_solicitacao,
        mensagem:
          mensagem ||
          "Não foi encontrado nenhum registro de contas a pagar pendente",
      };
    } else {
      return {
        imagem: icone_solicitacao,
        mensagem:
          mensagem ||
          "Não foi encontrada nenhuma solicitação de senha pendente",
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginaAtual]);

  return (
    <article className={`${classes.emptyState} ${className || ""}`}>
      <img src={informacoes.imagem} alt="icone nenhum item encontrado" />

      <p>{informacoes.mensagem}</p>
    </article>
  );
};
