import { Button } from "@material-ui/core"
import { useStyles } from "./styles"
import { TiArrowBack } from 'react-icons/ti'

interface IProps {
    url: string
    setUrl: React.Dispatch<React.SetStateAction<string | null>>
}

export const ExibicaoContrato: React.FC<IProps> = ({ url, setUrl }) => {
    const classes = useStyles()

    return (
        <div className={classes.container}>

            <iframe
                title='Contrato'
                src={`https://docs.google.com/gview?url=${url}&embedded=true&origin=${window.location.href.split('?')[0]}`}
                height='100%'
                width='100%'
            />

            <Button
                className={classes.botaoRetorno}
                variant='contained'
                onClick={() => setUrl(null)}
            >
                <TiArrowBack />
            </Button>

        </div>
    )
}