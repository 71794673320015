import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    container: {
        width: '100%',
        backgroundColor: 'white',
        borderRadius: 3,
    },
    titulo: {
        padding: 10,
        color: "#303030",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        borderBottom: '0.5px solid #E3ECFA',
    },
    conteudo: {
        padding: 10,
        display: 'grid',
        rowGap: 10,
        textAlign: 'justify'
    }
});