import { useStyles } from "./styles";
import { IAnexoPrevisaoCompra } from "../../services";
import { Button } from "@material-ui/core";
import { FaPaperclip } from "react-icons/fa";
import { usePrevisaoCompra } from "../../hooks";

interface IAnexosProps {
  dados: IAnexoPrevisaoCompra[];
}

export const Anexos: React.FC<IAnexosProps> = ({ dados }) => {
  const classes = useStyles();

  const { setAnexoEmExibicao } = usePrevisaoCompra();

  function definirTipoDoArquivo(descricao?: string) {
    if (!descricao) return "";

    const indiceUltimoPonto = descricao.lastIndexOf(".");

    if (indiceUltimoPonto === -1) return "";

    const extensao = descricao.slice(indiceUltimoPonto + 1).toLowerCase();

    if (extensao === "png") return "image/png";

    if (extensao === "jpg" || extensao === "jpeg") return "image/jpeg";

    if (extensao === "pdf") return "application/pdf";

    return "";
  }

  function definirUrlDeExibicao(anexo: IAnexoPrevisaoCompra) {
    const tipoDoArquivo = definirTipoDoArquivo(anexo.descricao);

    const binaryData = atob(anexo.anexo);

    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (var i = 0; i < binaryData.length; i++)
      uint8Array[i] = binaryData.charCodeAt(i);

    const blob = new Blob([uint8Array], { type: tipoDoArquivo });

    const objectURL = URL.createObjectURL(blob);

    if (!tipoDoArquivo.includes("pdf")) {
      return setAnexoEmExibicao({
        type: tipoDoArquivo,
        src: objectURL,
      });
    }

    const link = document.createElement("a");
    link.href = objectURL;
    link.download = anexo.descricao || "anexo-solicitacao-de-senha.pdf";
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }

  return (
    <div className={classes.container}>
      {dados.map((anexo) => (
        <Button
          key={anexo.idAnexo}
          variant="contained"
          className={classes.anexoContainer}
          onClick={() => definirUrlDeExibicao(anexo)}
        >
          {anexo.descricao} <FaPaperclip />
        </Button>
      ))}
    </div>
  );
};
