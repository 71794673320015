import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({

    listagemDeNotificacoes: {
        display: 'grid',
        rowGap: '15px',
    },

    notificacaoDoApp: {
        width: '100%',
        backgroundColor: 'white',
        color: '#303030',

        "&:hover": {
            backgroundColor: 'white',
        }

    },

    propriedadeNotificacaoDoApp: {
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        columnGap: '10px',
        textAlign: 'left'
    },

    propriedadeComBorderTop: {
        borderTop: '0.5px solid #E3ECFA',
    },

    propriedadeComBorderBottom: {
        borderBottom: '0.5px solid #E3ECFA',
    },

    mensagemNotificacaoSelecionada: {
        width: '100%',
        background: 'white',
        textAlign: 'justify',
    }

})