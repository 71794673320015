import { BsCart4 } from "react-icons/bs";
import { TOpcaoDeRelatorio } from "../components/opcao-de-relatorio";

export const TEMPO_DE_ATUALIZACAO_DE_REGISTROS = 30000;

export const OPCOES_DE_RELATORIOS: TOpcaoDeRelatorio[] = [
  {
    nome: "Pedido",
    Icone: BsCart4,
    tipo: "pedido",
  },
];

// ? =========================================================================================================
// ? PERTENCEM A UM MESMO GRUPO
export const IDENTIFICADOR_PREVISAO_DE_COMPRA_NLAR = "LIBERAR PEDIDO DE COMPRA";
export const IDENTIFICADOR_ORDEM_DE_COMPRAS_CONSUMO = "LIBERAR ORDEM DE COMPRAS - CONSUMO";
export const IDENTIFICADOR_ORDEM_DE_SERVICO = "LIBERAR ORDEM DE SERVIÇO";
export const IDENTIFICADOR_ORDEM_DE_PAGAMENTO = "LIBERAR ORDEM DE PAGAMENTO";
// ? =========================================================================================================

export const IDENTIFICADOR_ASSINATURA_DIGITAL_NLAR =
  "PERMITIR ASSINAR CONTRATO CLICKSIGN";

export const IDENTIFICADOR_BAIXA_DE_CONTAS_A_PAGAR =
  "LIBERA BAIXA DE DOCUMENTOS DO CONTAS A PAGAR";

export const IDENTIFICADOR_SENHA_FINANCEIRA =
  "LIBERA LANCAMENTOS NO FINANCEIRO";

export const IDENTIFICADOR_SOLICITACAO_DE_SENHA_COMUM = "SOLICITAÇÃO DE SENHA";

export const IDENTIFICADOR_BONIFICACAO = "LIBERA VENDA BONIFICACAO NO BALCAO";

export const IDENTIFICADOR_INCLUSAO_DE_CONTAS_A_PAGAR =
  "LIBERA INCLUSAO DE DOCUMENTOS DO CONTAS A PAGAR";

export const IDENTIFICADOR_JUROS = "LIBERA USO DE JUROS TIPO GERENCIA";
