import { makeStyles } from "@material-ui/core";
import { ReactElement, ReactNode } from "react";
import { PNGT } from "..";
import { useNotificacaoDoAppContext } from "../../hooks";

const useStyles = makeStyles({

    atributoNotificacaoDoApp: {
        padding: '10px 10px 0 10px',
        textAlign: 'left',

        "&:last-child": {
            paddingBottom: '10px'
        }

    },

});

export const AtributoNotificacaoDoApp: React.FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>> = ({ children, ...rest }) => {

    const classes = useStyles();

    const {
        notificacaoDoAppSelecionada,
        solicitacaoPendenteSelecionada
    } = useNotificacaoDoAppContext();

    const tratarAtributoMaisDetalhes = (children: ReactNode): ReactElement => {

        const childrenEmString = String(children);

        const indiceFimChave = childrenEmString.indexOf(':');

        return <><PNGT>{childrenEmString.substring(0, indiceFimChave)}:</PNGT> {childrenEmString.substring(indiceFimChave + 1).replace(',', '')}</>

    }

    return <p className={classes.atributoNotificacaoDoApp} {...rest}>
        {notificacaoDoAppSelecionada !== null || solicitacaoPendenteSelecionada !== null ? tratarAtributoMaisDetalhes(children) : children}
    </p>

}