import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  listaDocumentos: {
    "& div:nth-child(odd)": {
      background: `#CCCCCC33`,
      borderRadius: ".3em",
    },
  },
});
