import { IInformacaoControleDeAcesso } from "../../components";
import { ISolicitacaoSenha } from "../../interfaces";
import { ApiException } from "../../services";
import {
  IDENTIFICADOR_ASSINATURA_DIGITAL_NLAR,
  IDENTIFICADOR_BAIXA_DE_CONTAS_A_PAGAR,
  IDENTIFICADOR_BONIFICACAO,
  IDENTIFICADOR_INCLUSAO_DE_CONTAS_A_PAGAR,
  IDENTIFICADOR_JUROS,
  IDENTIFICADOR_ORDEM_DE_COMPRAS_CONSUMO,
  IDENTIFICADOR_ORDEM_DE_PAGAMENTO,
  IDENTIFICADOR_ORDEM_DE_SERVICO,
  IDENTIFICADOR_PREVISAO_DE_COMPRA_NLAR,
  IDENTIFICADOR_SENHA_FINANCEIRA,
  IDENTIFICADOR_SOLICITACAO_DE_SENHA_COMUM,
} from "../../utils";

export const instanciaDeApiException = (erro: any): erro is ApiException =>
  "message" in erro;

export const verificarScrollInfinitoAtivo = (
  tamanhoDaLista: number,
  estaCarregando: boolean,
  paginaAtual: number,
  totalPaginas: number
) => {
  if (tamanhoDaLista > 0 && !estaCarregando && paginaAtual < totalPaginas)
    return true;

  return false;
};

export const naoNuloOuUndefined = (valor: any): boolean =>
  valor !== null && valor !== undefined;

export const ehPrevisaoDeCompraNLar = (
  pedidoSelecionado?: ISolicitacaoSenha
): boolean =>
  pedidoSelecionado === undefined
    ? false
    : pedidoSelecionado.mensagem
        .toUpperCase()
        .includes(IDENTIFICADOR_PREVISAO_DE_COMPRA_NLAR);

export const ehOrdemDeComprasConsumo = (
  pedidoSelecionado?: ISolicitacaoSenha
): boolean =>
  pedidoSelecionado === undefined
    ? false
    : pedidoSelecionado.mensagem
        .toUpperCase()
        .includes(IDENTIFICADOR_ORDEM_DE_COMPRAS_CONSUMO);

export const ehOrdemDeServico = (
  pedidoSelecionado?: ISolicitacaoSenha
): boolean =>
  pedidoSelecionado === undefined
    ? false
    : pedidoSelecionado.mensagem
        .toUpperCase()
        .includes(IDENTIFICADOR_ORDEM_DE_SERVICO);

export const ehOrdemDePagamento = (
  pedidoSelecionado?: ISolicitacaoSenha
): boolean =>
  pedidoSelecionado === undefined
    ? false
    : pedidoSelecionado.mensagem
        .toUpperCase()
        .includes(IDENTIFICADOR_ORDEM_DE_PAGAMENTO);

// * USADO APENAS PARA AS ROTINAS DE BUSCAS NAS ROTAS CERTAS
export const fazParteGrupoOrdemCompras = (
  pedidoSelecionado?: ISolicitacaoSenha
): boolean =>
  ehPrevisaoDeCompraNLar(pedidoSelecionado) ||
  ehOrdemDeComprasConsumo(pedidoSelecionado) ||
  ehOrdemDeServico(pedidoSelecionado) ||
  ehOrdemDePagamento(pedidoSelecionado);

export const ehAssinaturaDigitalNLar = (
  pedidoSelecionado?: ISolicitacaoSenha
): boolean =>
  pedidoSelecionado === undefined
    ? false
    : pedidoSelecionado.mensagem
        .toUpperCase()
        .includes(IDENTIFICADOR_ASSINATURA_DIGITAL_NLAR);

export const ehContasAPagar = (
  pedidoSelecionado?: ISolicitacaoSenha
): boolean =>
  pedidoSelecionado === undefined
    ? false
    : pedidoSelecionado.mensagem
        .toUpperCase()
        .includes(IDENTIFICADOR_BAIXA_DE_CONTAS_A_PAGAR);

export const ehSolicitacaoFinanceira = (
  pedidoSelecionado?: ISolicitacaoSenha
): boolean =>
  pedidoSelecionado === undefined
    ? false
    : pedidoSelecionado.mensagem
        .toUpperCase()
        .includes(IDENTIFICADOR_SENHA_FINANCEIRA);

export const ehBonificacao = (pedidoSelecionado?: ISolicitacaoSenha): boolean =>
  pedidoSelecionado === undefined
    ? false
    : pedidoSelecionado.mensagem
        .toUpperCase()
        .includes(IDENTIFICADOR_BONIFICACAO);

export const ehInclusaoContasAPagar = (
  pedidoSelecionado?: ISolicitacaoSenha
): boolean =>
  pedidoSelecionado === undefined
    ? false
    : pedidoSelecionado.mensagem
        .toUpperCase()
        .includes(IDENTIFICADOR_INCLUSAO_DE_CONTAS_A_PAGAR);

export const ehJuros = (pedidoSelecionado?: ISolicitacaoSenha): boolean =>
  pedidoSelecionado === undefined
    ? false
    : pedidoSelecionado.mensagem.toUpperCase().includes(IDENTIFICADOR_JUROS);

export const obterCorControleDeAcesso = (
  solicitacaoDeSenha?: ISolicitacaoSenha,
  controleDeAcesso?: string
): IInformacaoControleDeAcesso => {
  if (
    ehOrdemDeComprasConsumo(solicitacaoDeSenha) ||
    controleDeAcesso === IDENTIFICADOR_ORDEM_DE_COMPRAS_CONSUMO
  )
    return {
      cor: "#1B2A49",
      titulo: IDENTIFICADOR_ORDEM_DE_COMPRAS_CONSUMO,
    };

  if (
    ehOrdemDeServico(solicitacaoDeSenha) ||
    controleDeAcesso === IDENTIFICADOR_ORDEM_DE_SERVICO
  )
    return {
      cor: "#EEF1C6",
      titulo: IDENTIFICADOR_ORDEM_DE_SERVICO,
    };

  if (
    ehOrdemDePagamento(solicitacaoDeSenha) ||
    controleDeAcesso === IDENTIFICADOR_ORDEM_DE_PAGAMENTO
  )
    return {
      cor: "#F1078A",
      titulo: IDENTIFICADOR_ORDEM_DE_PAGAMENTO,
    };

  if (
    ehPrevisaoDeCompraNLar(solicitacaoDeSenha) ||
    controleDeAcesso === IDENTIFICADOR_PREVISAO_DE_COMPRA_NLAR
  )
    return {
      cor: "#1DE9B6",
      titulo: IDENTIFICADOR_PREVISAO_DE_COMPRA_NLAR,
    };

  if (
    ehAssinaturaDigitalNLar(solicitacaoDeSenha) ||
    controleDeAcesso === IDENTIFICADOR_ASSINATURA_DIGITAL_NLAR
  )
    return {
      cor: "#2979FF",
      titulo: IDENTIFICADOR_ASSINATURA_DIGITAL_NLAR,
    };

  if (
    ehContasAPagar(solicitacaoDeSenha) ||
    controleDeAcesso === IDENTIFICADOR_BAIXA_DE_CONTAS_A_PAGAR
  )
    return {
      cor: "#FB8C00",
      titulo: IDENTIFICADOR_BAIXA_DE_CONTAS_A_PAGAR,
    };

  if (
    ehSolicitacaoFinanceira(solicitacaoDeSenha) ||
    controleDeAcesso === IDENTIFICADOR_SENHA_FINANCEIRA
  )
    return {
      cor: "#FF1744",
      titulo: IDENTIFICADOR_SENHA_FINANCEIRA,
    };

  if (
    ehBonificacao(solicitacaoDeSenha) ||
    controleDeAcesso === IDENTIFICADOR_BONIFICACAO
  )
    return {
      cor: "#7D17FF",
      titulo: "SAÍDA DE ESTOQUE",
    };

  if (
    ehInclusaoContasAPagar(solicitacaoDeSenha) ||
    controleDeAcesso === IDENTIFICADOR_INCLUSAO_DE_CONTAS_A_PAGAR
  )
    return {
      cor: "#613600",
      titulo: IDENTIFICADOR_INCLUSAO_DE_CONTAS_A_PAGAR,
    };

  if (ehJuros(solicitacaoDeSenha) || controleDeAcesso === IDENTIFICADOR_JUROS)
    return {
      cor: "#FFEB3B",
      titulo: IDENTIFICADOR_JUROS,
    };

  return {
    cor: "#78909C",
    titulo: IDENTIFICADOR_SOLICITACAO_DE_SENHA_COMUM,
  };
};
