import { toDataBR, toMoney } from "../../functions";
import { TParcelaNegociacaoRelatorio } from "../../services/api/relatorio/relatorio-service"
import { LinhaRelatorio } from "../linha-relatorio";
import { useStyles } from "./styles";

type Props = {
    data: TParcelaNegociacaoRelatorio;
}

export const ParcelaNegociacaoRelatorio: React.FC<Props> = ({ data }) => {

    const classes = useStyles();

    return <div className={classes.container}>

        <span className={classes.titulo}>

            <p>Parcela {data.nrParcela}</p>

            <p>{toDataBR(data.vencimento)}</p>

        </span>

        <LinhaRelatorio
            titulo="Forma de pagamento"
            valor={data.descricaoFormaPagamento}
            semNegrito
        />

        <LinhaRelatorio
            titulo="Valor Final"
            valor={toMoney(data.valorFinal)}
            corDoValor="#007303"
            semNegrito
        />

        <LinhaRelatorio
            titulo="Dias"
            valor={data.dias}
            semNegrito
        />

    </div>

}