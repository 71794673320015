import { ReactNode } from "react";
import { useStyles } from "./styles";

interface IAtributoProps {
  nome: string;
  valor: ReactNode;
  corPersonalizada?: string;
}

export const Atributo: React.FC<IAtributoProps> = ({
  nome,
  valor,
  corPersonalizada,
}) => {
  const classes = useStyles();

  return (
    <span className={classes.container} style={{ color: corPersonalizada }}>
      <strong>{nome}:</strong>
      <p>{valor}</p>
    </span>
  );
};
