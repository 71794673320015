import { api, ApiException } from "..";
import {
  ICabecalhoPrevisaoCompra,
  IItemPrevisaoCompra,
} from "../../../interfaces";

interface IRetornoGetInformacoesByCod {
  nLarPrevisaoCompraDTO: ICabecalhoPrevisaoCompra;
  nLarPrevisaoCompraItem: IItemPrevisaoCompra[];
}

export interface IParcelaAPagarPrevisaoCompra {
  np: number;
  vencimento: string;
  valorDoc: number;
}

export interface IAnexoPrevisaoCompra {
  idAnexo: number;
  anexo: string;
  descricao?: string;
}

const obterInformacoesPeloCodigo = async (
  codPrevisao: number,
  CurrentPage: number
): Promise<IRetornoGetInformacoesByCod> => {
  try {
    const r = await api.get<IRetornoGetInformacoesByCod>(
      `/previsaocompraporid/${codPrevisao}`,
      { params: { CurrentPage, Limit: 5 } }
    );

    return r.data;
  } catch (erro) {
    throw new ApiException(
      erro,
      "Erro ao buscar informações da previsão de compra."
    );
  }
};

const obterFormaDePagamentoDetalhada = async (
  codPrevisaoCompra: number,
  codLoja: number
): Promise<IParcelaAPagarPrevisaoCompra[]> => {
  try {
    const { data } = await api.get<IParcelaAPagarPrevisaoCompra[]>(
      `/obter-forma-de-pagamento-detalhada-da-previsao`,
      { params: { codPrevisaoCompra, codLoja } }
    );

    return data;
  } catch (erro) {
    throw new ApiException(
      erro,
      "Não foi possível obter os detalhes da forma de pagamento da previsão"
    );
  }
};

const obterAnexos = async (
  codLoja: number,
  codPrevisaoCompra: number,
  sequencial: number
): Promise<IAnexoPrevisaoCompra[]> => {
  try {
    const { data } = await api.get<IAnexoPrevisaoCompra[]>(`/obter-anexos`, {
      params: {
        codLoja,
        codPrevisaoCompra,
        sequencial,
      },
    });

    return data;
  } catch (erro) {
    throw new ApiException(
      erro,
      "Não foi possível obter os anexos para esta previsão"
    );
  }
};

export const PrevisaoCompraService = {
  obterInformacoesPeloCodigo,
  obterFormaDePagamentoDetalhada,
  obterAnexos,
};
