import { useMemo } from "react";
import { toDataBR, toMoney } from "../../functions";
import { IDetalhesJuros } from "../../services";
import { Atributo } from "../atributo";
import { Secao } from "../secao";
import { useStyles } from "./styles";
import _ from "lodash";

interface IDetalhesJurosProps {
  data: IDetalhesJuros;
}

interface DicionarioFormasPagamento {
  [codigo: number]: string;
}

const CORES_NEGOCIACAO = [
  "#A0E7E5",
  "#E6E2FF",
  "#F9CCAC",
  "#C9A0DC",
  "#D1D1D1",
  "#FFD166",
  "#7FC7AF",
  "#F4AFA8",
];

export function DetalhesJuros({ data }: IDetalhesJurosProps) {
  const classes = useStyles();

  const clienteFormatado = useMemo(
    () =>
      `(${data.cabecalho.codCliente}) ${data.cabecalho.nomeCliente
        .replaceAll("CLIENTE -", "")
        .trim()}`,
    [data.cabecalho]
  );

  const coresPorFpgto = useMemo((): DicionarioFormasPagamento => {
    const result: DicionarioFormasPagamento = {};

    const codigosFpgto = _.uniqBy(data.negociacao, "codFormaPgto").map(
      (item) => item.codFormaPgto
    );

    codigosFpgto.forEach((fp, i) => (result[fp] = `${CORES_NEGOCIACAO[i]}66`));

    return result;
  }, [data.negociacao]);

  return (
    <>
      <Secao>
        <Atributo nome="Loja" valor={data.cabecalho.loja} />

        <Atributo nome="Orçamento" valor={data.cabecalho.orcamento} />

        {data.cabecalho.data && (
          <Atributo nome="Data" valor={toDataBR(data.cabecalho.data)} />
        )}

        <Atributo nome="Cliente" valor={clienteFormatado} />

        <Atributo
          nome="Condição Pgto"
          valor={data.cabecalho.descricaoCondicaoPgto}
        />

        <Atributo
          nome="Total sem juros"
          valor={toMoney(data.cabecalho.valorSemJuros)}
        />

        <Atributo nome="Total de juros" valor={toMoney(data.cabecalho.juros)} />

        <Atributo
          nome="Total com juros"
          valor={toMoney(data.cabecalho.valorFinal)}
        />
      </Secao>

      <Secao cabecalho="Negociação">
        <div className={classes.negociacoes}>
          {data.negociacao.map((negociacao) => (
            <div
              className={classes.negociacao}
              style={{
                backgroundColor: coresPorFpgto[negociacao.codFormaPgto],
              }}
            >
              <div className={classes.cabecalhoNegociacao}>
                <Atributo
                  nome="Forma Pgto"
                  valor={negociacao.descricaoFormaPagamento}
                />

                <Atributo nome="Np" valor={negociacao.nrParcela} />
              </div>

              <Atributo
                nome="Sem juros"
                valor={toMoney(negociacao.valorSemJuros)}
              />

              <Atributo nome="Juros" valor={toMoney(negociacao.juros)} />

              <Atributo
                nome="Com juros"
                valor={toMoney(negociacao.valorFinal)}
              />

              <Atributo
                nome="Vencimento"
                valor={toDataBR(negociacao.vencimento)}
              />
            </div>
          ))}
        </div>
      </Secao>
    </>
  );
}
